import React from 'react';
import { Avatar } from '@material-ui/core';
import styles from './styles';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';

export default ({ DATA = {}, popUp, userone, usertwo, chatType }) => {
  console.log('message_type', DATA.message_type);

  const cls = styles({ me: DATA.me });
  const image =
    DATA.message.startsWith('blob:') ||
    DATA.message.includes('https://smart-turn.com/storage/message') ||
    DATA.message.includes('https://apitest.smart-turn.com/storage/message') ||
    DATA.message.includes('https://testapi.smart-turn.com/storage/message') ||
    DATA.message.includes('https://api.smart-turn.com/storage/message');
  return (
    <>
      {DATA?.message_type === '4' ? (
        <div className={cls.card}>
          <div className={cls.chatInstructions}>
            <span className={cls.chatInfo}>
              <span className={cls.chatInstructionsInfo}>
                <ReactSVG
                  src={Icons.InstructionsIcon}
                  className={cls.dicsounticon}
                />
                <span>Instructions</span>
              </span>
            </span>
            <div className={cls.title}>
              {DATA.message.includes(',')
                ? DATA.message
                    .split(',')
                    .map((item, index) => (
                      <span className={cls.chatLine}>{item}</span>
                    ))
                : DATA.message}
            </div>
          </div>
        </div>
      ) : DATA?.message_type === null || DATA?.message_type === undefined ? (
        <div className={cls.card}>
          <div className={cls.chat}>
            {image ? (
              <img
                className={cls.img}
                src={DATA.message}
                alt="Chat"
                onClick={() =>
                  // popUp(DATA)
                  window.open(DATA.message, '_blank')
                }
              />
            ) : (
              <div className={cls.title}>
                {DATA.message.includes(',')
                  ? DATA.message
                      .split(',')
                      .map((item, index) => (
                        <span className={cls.chatLine}>{item}</span>
                      ))
                  : DATA.message}
              </div>
            )}
            <div className={cls.time}>{DATA.time}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};
