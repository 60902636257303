import React, { useEffect, useState } from 'react';
import styles from './styles';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';

export default ({ startTime }) => {
  const cls = styles();

  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const targetTime = new Date(
      new Date(startTime).getTime() + 2 * 60 * 60 * 1000
    );

    const updateTimer = () => {
      const now = new Date();
      const diff = targetTime - now;

      if (diff <= 0) {
        setTimeLeft('00:00:00');
        return;
      }

      const hours = String(Math.floor(diff / (1000 * 60 * 60))).padStart(
        2,
        '0'
      );
      const minutes = String(
        Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, '0');
      const seconds = String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(
        2,
        '0'
      );

      setTimeLeft(`${hours}:${minutes}:${seconds}`);
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <div className={cls.CountdownTimer}>
      <ReactSVG src={Icons.CountdownTimerIcon} className={cls.infoCircleIcon} />
      <span className={cls.timerText}>{timeLeft}</span>
    </div>
  );
};
