// in src/MyAppBar.js
import React, { useState } from 'react';
import { usePermissions } from 'react-admin';
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'react-router-dom';
import { HeaderIcons } from '@/assets';
import UserMenu from '../UserMenu';
import { SearchRounded } from '@material-ui/icons';
import {
  InputBase,
  InputAdornment,
  IconButton,
  Toolbar,
  CircularProgress,
  Button,
} from '@material-ui/core';
import Notification from '@/components/Notification';
import styles from './styles';
import MenuIcon from '@material-ui/icons/Menu';
import LogOutButton from '../LogOutButton';
import { useSelector } from 'react-redux';
import { SaveData } from '@/actions';
import MobileMenu from '../MobileMenu';
import useMediaQuery from 'use-mediaquery';
import ChatBar from '@/components/Chat/ChatBar';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';

export default (props) => {
  const { permissions } = usePermissions();
  const cls = styles();
  const openUserMenu = useSelector(
    (state) => state.reducer.openUserMenu || null
  );
  let history = useHistory();
  const isSmallDevice = useMediaQuery('(max-width: 1000px)');
  const isMobileDevice = useMediaQuery('(max-width: 763px)');
  const [loginLoading, setLoginLoading] = useState(false);

  const handleToggleMenu = () => {
    SaveData({ openUserMenu: !openUserMenu });
  };

  const handleRedirectToAdmin = () => {
    setLoginLoading(true);
    let adminToken = localStorage.getItem('token_admin');
    localStorage.setItem('token', adminToken);
    localStorage.setItem('permissions', 'admin');
    history.push('/dashboard');
    window.location.reload();
  };

  const handleToggleMenuBar = () => {
    document.getElementById('menuBar').style.display = 'flex';
    document.getElementById('menuOpenIcon').style.display = 'none';
    document.getElementById('amirsalam').style.marginLeft = '100px';
    // window.updateWidth();
    // window.updateHeadWidth();
    // window.syncScroll('amirbios');
    if (typeof window.updateWidth === 'function') {
      window.updateWidth();
    } else {
      console.warn('window.updateWidth is not defined in this page!');
    }

    if (typeof window.updateHeadWidth === 'function') {
      window.updateHeadWidth();
    } else {
      console.warn('window.updateHeadWidth is not defined in this page!');
    }

    if (typeof window.syncScroll === 'function') {
      window.syncScroll('amirbios');
    } else {
      console.warn('window.syncScroll is not defined in this page!');
    }
  };

  return (
    <>
      <AppBar className={cls.header} position="fixed">
        <Toolbar className={cls.toolbar}>
          <span className={cls.menuTuggleMobile}>
            <IconButton
              edge="start"
              color="inherit"
              sx={{ mr: 2 }}
              className={cls.menuIcon}
              onClick={() => handleToggleMenu()}>
              <MenuIcon />
              {/* <ReactSVG src={HeaderIcons.menuHandlerOpen} />{' '} */}
            </IconButton>
          </span>

          <span id="menuOpenIcon" className={cls.menuTuggle}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => handleToggleMenuBar()}>
              <ReactSVG src={HeaderIcons.menuHandlerOpen} />
            </IconButton>
          </span>

          <Link to="/">
            <img
              src={HeaderIcons.logosmartyellowdots}
              alt="Logo"
              className={cls.logo}
            />
          </Link>
          {/* {isSmallDevice ? (
            <MobileMenu />
          ) : (
            <div className={cls.linkCont}>
              <Link to="/" className={cls.link}>
                HOME
              </Link>
              <Link to="/how-it-works" className={cls.link}>
                HOW IT WORKS
              </Link>
              <Link to="/about" className={cls.link}>
                ABOUT
              </Link>
            </div>
          )} */}
          <span className={cls.spacer} />
          {/* <InputBase
            id="searchBar"
            placeholder="Search Offer or ..."
            variant="outlined"
            margin="dense"
            className={cls.search}
            endAdornment={
              <InputAdornment position="end">
                <SearchRounded className={cls.searchIcon} />
              </InputAdornment>
            }
          /> */}
          {/* {permissions !== 'admin' && <ChatBar />} */}
          {permissions === 'user' &&
            localStorage.getItem('token_admin') &&
            !isMobileDevice && (
              <Button
                className={cls.adminBtn}
                onClick={() => handleRedirectToAdmin()}>
                {loginLoading ? (
                  <CircularProgress className={cls.loginLoader} />
                ) : (
                  'Redirect to Admin'
                )}
              </Button>
            )}

          <Notification />
          <UserMenu>
            <LogOutButton />
          </UserMenu>
        </Toolbar>
      </AppBar>
    </>
  );
};
