import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import { Icons, TerminalsIcon } from '@/assets';
import {
  Tooltip,
  Grid,
  Button,
  IconButton,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  Share as ShareIcon,
} from '@material-ui/icons';
import { ReactSVG } from 'react-svg';

import clsx from 'clsx';
import { getAppointmentsSetting, getPerDiem, updatePerDiem } from '@/actions';
import { useNotify, Loading } from 'react-admin';
import { getEmptyReceivingSchedule, getSslList } from '@/actions';
import EditFields from './editFields';

export default ({ Terminals }) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const [sslsList, setSslsList] = useState([]);
  const [DATADefault, setDATADefault] = React.useState([]);
  const [appointmentSettingList, SetAppointmentSettingList] = React.useState(
    []
  );
  const [PerDiems, setPerDiems] = useState([]);

  const [reconstructedList, setreconstructedList] = useState([]);
  const [sslsSelectOptions, setSslsSelectOptions] = useState([]);
  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);
  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);
  const notify = useNotify();
  const [popupMessage, setPopupMessage] = useState({
    DATA: null,
    loading: true,
    popUp: false,
  });

  const getAppointmentsSettingList = () => {
    setLoading(true);
    getAppointmentsSetting()
      .then((res) => {
        // setDataTable(res.data.restrictions);
        SetAppointmentSettingList(res.data.scrapeSettings);
        console.log('res.data.scrapeSettings', res.data.scrapeSettings);

        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getEmptyReceivingScheduleList = () => {
    setLoading(true);
    getEmptyReceivingSchedule(`2024-09-29`)
      .then((res) => {
        // setDataTable(res.data.restrictions);
        setDATADefault(res.data.default);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getSslListFanc = () => {
    setLoading(true);
    getSslList()
      .then((res) => {
        // console.log('res', res);

        const sslsRes = Object.values(res.data.ssl_list);
        if (sslsRes) {
          setSslsList(sslsRes); // Set the state directly
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (sslsList.length > 0) {
      const options = sslsList.map((ssl, index) => ({
        label: ssl.name_full,
        slug: ssl.name,
        id: ssl.id,
        value: index + 1,
      }));

      // Set portSelectOptions state
      setSslsSelectOptions(options);
      // console.log(
      //   'item.slug === DATA',
      //   options.find((item) => item.slug === DATA)
      // );

      // setSelectedSsl(options.find((item) => item.slug === DATA));
    }
  }, [sslsList]); // Dependencies array with portsList

  useEffect(() => {
    getAppointmentsSettingList();
    getEmptyReceivingScheduleList();
    getSslListFanc();
  }, []);

  //   const scheduleType = [
  //     {
  //       label: 'Gate',
  //       slug: 'gate',
  //       value: 1,
  //     },
  //     {
  //       label: 'Recivables',
  //       slug: 'recivables',
  //       value: 2,
  //     },
  //     {
  //       label: 'Vessel',
  //       slug: 'vessel',
  //       value: 3,
  //     },
  //   ];
  //   const typeSelectOptions = [
  //     {
  //       label: 'Terminal',
  //       slug: 'terminal',
  //       value: 'terminal',
  //     },
  //     {
  //       label: 'SSL',
  //       slug: 'ssl',
  //       value: 'ssl',
  //     },
  //     {
  //       label: 'Priority',
  //       slug: 'all',
  //       value: 'all',
  //     },
  //   ];

  //   const [form, setFormData] = React.useState({
  //     scheduleType: scheduleType[0],
  //     type: typeSelectOptions[0],
  //     size: null,
  //     ssl: null,
  //     date: new Date(),
  //     count: '',
  //     loc: null,
  //     containers: [],
  //   });
  //   const [formReciv, setFormRecivData] = React.useState({
  //     scheduleType: scheduleType[0],
  //     size: null,
  //     ssl: null,
  //     date: new Date(),
  //     count: '',
  //     loc: null,
  //     containers: [],
  //   });
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const getPerDiemList = () => {
    setLoading(true);
    getPerDiem()
      .then((res) => {
        if (res) {
          setPerDiems(res.data.per_diems);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const updateList = () => {
    setLoading(true);
    updatePerDiem()
      .then((res) => {
        if (res) {
          setPerDiems(res.data.per_diems);
          console.log('refd', res);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  useEffect(() => {
    getPerDiemList();
  }, []);

  useEffect(() => {
    const reconstructedJson = {};

    for (const perDiem of PerDiems) {
      const shippingLineAlias = perDiem.shipping_line.alias;

      const specificationSizeKey = perDiem.specification.size
        ? `${perDiem.specification.size} `
        : '';
      const specificationNameKey = perDiem.specification.name
        ? `${perDiem.specification.name} `
        : '';
      const specificationModelKey = perDiem.specification.model
        ? `${perDiem.specification.model}`
        : '';
      const specificationKey = `${
        specificationSizeKey + specificationNameKey + specificationModelKey
      }`;
      // const specificationKey = `${perDiem.specification.size} ${perDiem.specification.name} ${perDiem.specification.model}`;
      const phaseKey = `phase${perDiem.phase}`;
      const typeKey = perDiem.type === 'IB' ? 'IB' : 'OB';
      if (!reconstructedJson[shippingLineAlias]) {
        reconstructedJson[shippingLineAlias] = {};
      }

      if (!reconstructedJson[shippingLineAlias][specificationKey]) {
        reconstructedJson[shippingLineAlias][specificationKey] = {};
      }

      if (!reconstructedJson[shippingLineAlias][specificationKey][typeKey]) {
        reconstructedJson[shippingLineAlias][specificationKey][typeKey] = {};
      }

      if (
        !reconstructedJson[shippingLineAlias][specificationKey][typeKey][
          phaseKey
        ]
      ) {
        reconstructedJson[shippingLineAlias][specificationKey][typeKey][
          phaseKey
        ] = [];
      }

      // Add the perDiem object to the respective group
      reconstructedJson[shippingLineAlias][specificationKey][typeKey][
        phaseKey
      ].push({
        id: perDiem.id,
        phase: perDiem.phase,
        type: perDiem.type,
        with_chassis: perDiem.with_chassis,
        start_day: perDiem.start_day,
        end_day: perDiem.end_day,
        per_diem_amount: perDiem.per_diem_amount,
        saturday: perDiem.saturday,
        sunday: perDiem.sunday,
        saturday_lfd: perDiem.saturday_lfd,
        sunday_lfd: perDiem.sunday_lfd,
        pickup_day_included: perDiem.pickup_day_included,
        return_day_included: perDiem.return_day_included,
        holidays_included: perDiem.holidays_included,
        status: perDiem.status,
        created_at: perDiem.created_at,
        updated_at: perDiem.updated_at,
        shipping_line: perDiem.shipping_line,
        specification: perDiem.specification,
      });
    }

    setreconstructedList(reconstructedJson);
  }, [PerDiems]);

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

        if (amirbiosTop <= -10) {
          amirbios2Ref.current.classList.add(cls.fix);
          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      syncScroll('amirbios');
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        if (source === 'amirbios') {
          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    // Initial width setting
    updateWidth();
    updateHeadWidth();

    window.updateWidth = updateWidth;
    window.updateHeadWidth = updateHeadWidth;
    window.syncScroll = syncScroll;

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', updateWidth);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('scroll', updateHeadWidth);
    window.addEventListener('resize', updateHeadWidth);
    if (amirbiosRef.current) {
      amirbiosRef.current.addEventListener('scroll', () =>
        syncScroll('amirbios')
      );
    }
    if (amirbios2Ref.current) {
      amirbios2Ref.current.addEventListener('scroll', () =>
        syncScroll('amirbios2')
      );
    }

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', updateWidth);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('scroll', updateHeadWidth);
      window.removeEventListener('resize', updateHeadWidth);
      if (amirbiosRef.current) {
        amirbiosRef.current.removeEventListener('scroll', () =>
          syncScroll('amirbios')
        );
      }
      if (amirbios2Ref.current) {
        amirbios2Ref.current.removeEventListener('scroll', () =>
          syncScroll('amirbios2')
        );
      }
    };
  }, [tableWidth, spanWidth, reconstructedList]);

  useEffect(() => {
    if (amirbios2Ref.current) {
      amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
    }
  }, [spanWidth]);

  useEffect(() => {
    if (amirbios1Ref.current) {
      amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
    }
  }, [tableWidth]);

  if (amirbios2Ref.current) {
    amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
  }

  if (amirbios1Ref.current) {
    amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
  }

  return (
    <>
      {popupMessage.popUp && (
        <EditFields
          DATA={popupMessage.DATA}
          Label={popupMessage.Label}
          sslsList={sslsList}
          vessel_id={popupMessage.vessel_id}
          column_name={popupMessage.column_name}
          column_value={popupMessage.DATA}
          Close={() =>
            setPopupMessage((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}

      <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
        {loading ? (
          <Loading />
        ) : (
          <table ref={tableRef} id="table" className={cls.list}>
            <span ref={amirbios2Ref} id="amirbios1">
              <thead ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Terminal</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Platform</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Integration</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Scraping Frequency (scheduleer)</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Scraping Users (scheduleer)</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Last Scrape (scheduleer)</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Next Scrape (scheduleer)</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Last Scrape (Manually)</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Last Scrape by (Manually)</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Scraping Frequency (Manually)</th>
                </span>
                <span style={{ flex: 2 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Action</th>
                </span>
              </thead>
            </span>
            <tbody className={cls.tbody}>
              {Array.isArray(Terminals)
                ? Terminals.map((item, idx) => (
                    <tr className={cls.tableRow} key={idx}>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          <a
                            className={cls.terminalLink}
                            target="_blank"
                            href={item.scrapeable?.links.gate_schedule}>
                            <img
                              width={80}
                              src={
                                item.scrapeable.slug == 'APM'
                                  ? TerminalsIcon.APM
                                  : item.scrapeable.slug == 'FMS'
                                  ? TerminalsIcon.FMS
                                  : item.scrapeable.slug == 'EVERPORT'
                                  ? TerminalsIcon.EVERPORT
                                  : item.scrapeable.slug == 'LACT'
                                  ? TerminalsIcon.LACT
                                  : item.scrapeable.slug == 'PCT'
                                  ? TerminalsIcon.PCT
                                  : item.scrapeable.slug == 'PST'
                                  ? TerminalsIcon.PST
                                  : item.scrapeable.slug == 'Trapac'
                                  ? TerminalsIcon.Trapac
                                  : item.scrapeable.slug == 'WBCT'
                                  ? TerminalsIcon.WBCT
                                  : item.scrapeable.slug == 'WCC'
                                  ? TerminalsIcon.WCC
                                  : item.scrapeable.slug == 'YTI'
                                  ? TerminalsIcon.YTI
                                  : item.scrapeable.slug == 'TTI'
                                  ? TerminalsIcon.TTI
                                  : item.scrapeable.slug == 'ITS'
                                  ? TerminalsIcon.ITS
                                  : item.scrapeable.slug == 'LBCT'
                                  ? TerminalsIcon.LBCT
                                  : item.scrapeable.slug == 'MATSON'
                                  ? TerminalsIcon.MATSON
                                  : item.scrapeable.slug == 'SSA MARINE'
                                  ? TerminalsIcon.SSA
                                  : item.scrapeable.slug == 'SSA STL'
                                  ? TerminalsIcon.STL
                                  : item.scrapeable.slug == 'PTS'
                                  ? TerminalsIcon.PTS
                                  : ''
                              }
                            />
                            {item.scrapeable.slug}
                          </a>
                        </span>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        -
                      </td>
                      <td style={{ flex: 2 }} className={cls.shifttd}>
                        -
                      </td>

                      {/* <td style={{ flex: 1 }} className={cls.shifttd}>
                        <FormControl>
                          <Select
                            displayEmpty
                            disableUnderline={true}
                            defaultValue={item.scrape_type}
                            className={cls.selectBox}
                            onChange={(e) =>
                              handleChangeScrapeSetting(
                                item.id,
                                'scrape_type',
                                item.scrape_type === 'SCRAPE'
                                  ? 'API'
                                  : item.scrape_type === 'API'
                                  ? 'SCRAPE'
                                  : '',
                                idx
                              )
                            }
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem key={0} value={'SCRAPE'}>
                              SCRAPE
                            </MenuItem>
                            <MenuItem key={1} value={'API'}>
                              API
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <FormControl>
                          <Select
                            displayEmpty
                            disableUnderline={true}
                            defaultValue={item.scrape_frequency}
                            className={cls.selectBox}
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  15,
                                  idx
                                )
                              }
                              key={0}
                              value={15}>
                              15 min
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  30,
                                  idx
                                )
                              }
                              key={1}
                              value={30}>
                              30 min
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  60,
                                  idx
                                )
                              }
                              key={2}
                              value={60}>
                              1 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  360,
                                  idx
                                )
                              }
                              key={3}
                              value={360}>
                              6 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  720,
                                  idx
                                )
                              }
                              key={4}
                              value={720}>
                              12 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  1440,
                                  idx
                                )
                              }
                              key={5}
                              value={1440}>
                              1 day
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          {item.last_scrape
                            ? formatUpdatedAtDate(item.last_scrape)
                            : ''}
                        </span>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          {item.next_scrape
                            ? formatUpdatedAtDate(item.next_scrape)
                            : ''}
                        </span>
                      </td>
                      <td style={{ flex: 1.3 }} className={cls.shifttd}>
                        {item.status === 'DISABLED' ? (
                          <Tooltip arrow title="Disabled" placement="top">
                            <IconButton
                              aria-label="Disable"
                              onClick={() => {
                                handleChangeScrapeSetting(
                                  item.id,
                                  'status',
                                  'ENABLED',
                                  idx
                                );
                              }}>
                              <ReactSVG
                                src={Icons.close}
                                className={cls.icon}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip arrow title="Enabled" placement="top">
                            <IconButton
                              aria-label="enable"
                              onClick={() => {
                                handleChangeScrapeSetting(
                                  item.id,
                                  'status',
                                  'DISABLED',
                                  idx
                                );
                              }}>
                              <ReactSVG
                                src={Icons.tickICon}
                                className={cls.icon}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip arrow title="Refresh" placement="top">
                          <IconButton
                            aria-label="refresh"
                            onClick={() =>
                              handleChangeScrapeSetting(
                                item.id,
                                'refresh_scrape',
                                1,
                                idx
                              )
                            }>
                            <ReactSVG
                              src={Icons.refreshIcon}
                              className={cls.icon}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Stop" placement="top">
                          <IconButton
                            aria-label="stop"
                            onClick={() =>
                              handleChangeScrapeSetting(
                                item.id,
                                'blur_status',
                                item.blur_status === 0 ? 1 : 0,
                                idx
                              )
                            }>
                            <ReactSVG
                              src={
                                item.blur_status === 0
                                  ? Icons.stop
                                  : Icons.stopRed
                              }
                              className={cls.icon}
                            />
                          </IconButton>
                        </Tooltip>
                      </td> */}
                    </tr>
                  ))
                : ''}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
