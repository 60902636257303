import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  menuTuggle: {
    height: '58px',
    display: 'flex',
    right: '33px',
    top: '3px',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #E0E0E033',
    marginBottom: '15px',
    marginTop: '-2px',
    '& svg': {
      width: '24px',
      height: '24px',
    },
    '& button': {
      margin: 0,
    },
    // ['@media (max-width:800px)']: {
    //   display: 'none',
    // },
  },
  open: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    padding: '7px 0',
    borderRadius: 10,
    width: 84,
    margin: ' 1px auto',
    transition: 'all 0.3s ease-in',
    '&:hover': {
      backgroundColor: '#f8b100',
      '& svg': {
        fill: 'transparent',
      },
      '& path': {
        stroke: '#161a21',
      },
      '& rect': {
        stroke: '#161a21',
      },
      '& circle': {
        stroke: '#161a21',
      },
      '& line': {
        stroke: '#161a21',
      },
      '& polygon': {
        stroke: '#161a21',
      },

      '& > span': {
        color: '#303030',
      },
    },
  },
  close: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 0',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: 'trgb(0 0 0 / 19%)',
      '& svg': {
        fill: 'transparent',
      },
    },
  },
  active: {
    backgroundColor: '#F2AC4A',
    '& svg': {
      fill: 'transparent',
    },
    '& > span': {
      color: '#303030',
    },
    '& > div > div': {
      display: 'flex',
      animation: 'zoomIn 0.5s',
    },
  },
  listItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 'auto',
  },
  icon: {
    height: 18,
    width: 18,
    objectFit: 'contain',
    fill: 'transparent',
    '& path': {
      stroke: '#ffffff',
    },
    '& rect': {
      stroke: '#ffffff',
    },
    '& circle': {
      stroke: '#ffffff',
    },
    '& line': {
      stroke: '#ffffff',
    },
    '& polygon': {
      stroke: '#ffffff',
    },
  },

  child: {
    display: 'none',
    backgroundColor: '#000000',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    flexDirection: 'column',
    width: '100%',
    marginTop: 7,
    marginBottom: -7,
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2px 0',
    minHeight: 'auto',
    color: '#E1E4EA',
    fontFamily: 'Averta bold',
    fontSize: 10,
    // fontWeight: 600,
    letterSpacing: '0.5px',
    textDecoration: 'none',
    transition: 'all 0.3s ease-in',
    '&:hover': {
      color: '#f8b100',
    },
  },
  itemActive: {
    color: '#F6B51E',
  },
}));
