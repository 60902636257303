import { makeStyles } from '@material-ui/core/styles';
// import zIndex from '@mui/material/styles/zIndex';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '980px',
    margin: '0 auto',
    padding: '20px',
  },

  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },

  section: {
    marginBottom: '30px',
  },

  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },

  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  inputGroupWithLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderBottom: '1px solid #d0d0d0',
  },
  checkbox: {
    marginRight: '10px',
    padding: 5,
    '& svg': {
      fill: '#000',
      width: '20px',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flex: '1',
    fontWeight: 'bold',
  },
  labelWithInput: {
    display: 'block',
    alignItems: 'center',
    gap: '10px',
    flex: '1',
    fontWeight: 'bold',
  },

  labelDailyRate: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flex: '1',
    marginLeft: '55px',
    fontWeight: 'bold',
  },
  infoIcon: {
    fontRize: '12px',
    marginLeft: '5px',
    cursor: 'pointer',
  },

  textField: {
    width: '60px',
    margin: '0 10px',
  },
}));
