import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    padding: '10px 0',
    boxSizing: 'border-box',
    width: 360,
    height: '100%',
    boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 10,
    position: 'absolute',
    right: '0',
    zIndex: 10,
    top: 0,
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 15px',
    // marginBottom: 10,
    height: '65%',
  },
  bigContent: {
    height: '88% !important',
  },
  loading: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
