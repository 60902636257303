import React, { useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { fTimeDate, SizeIcons } from '@/actions/Helper';
import { Icons } from '@/assets';
import styles from './styles';
import { ErrorOutline, Done, Close } from '@material-ui/icons';

export default ({
  DATA = [],
  req,
  handleChangeNmuber,
  query,
  isExport,
  openReserve,
}) => {
  const cls = styles();
  if (DATA) {
    // console.log('DATA.has_containers', DATA.has_containers);
  }

  // const product = products?.result?.map((item, index) => {
  //   const nameObj = catigories?.result?.find((item2) => {
  //     return item2.id === item.categoryId;
  //   });
  //   let catTitle = nameObj?.title;
  //   return {
  //     ...item,
  //     catTitle,
  //   };
  // });
  const [hasContainerIcon, setHasContainerIcon] = useState();

  React.useEffect(() => {
    const hasContainer = DATA.map((item, idx) => {
      if (item.has_containers == 2) {
        setHasContainerIcon(Icons.has_containers);
      } else if (item.has_containers == 1) {
        setHasContainerIcon(Icons.has_containers_half);
      } else {
        setHasContainerIcon('');
      }
    });
    // hasContainer();
  }, []);

  const getProgressIcon = (item, type) => {
    const pool = item.pool_available || 0;
    const own = item.own_available || 0;
    const count = item.count_available;
    if (pool + own !== count) {
      return (
        <Tooltip
          arrow
          title={`The carrier is currently updating the  inventory list. We can not verify if the empties you are requesting are on own or pool chassis. However, please proceed with submitting your request and it will be shown in the next step.`}
          placement="top">
          <ErrorOutline />
        </Tooltip>
      );
    }
  };

  return DATA ? (
    <div className={cls.container}>
      <div className={cls.thead}>
        <div style={{ flex: 1.2 }}>#</div>
        <div style={{ flex: 0.7 }}>Size</div>
        <div style={{ flex: 0.7 }}>SSL</div>
        <div style={{ flex: 1 }}>Location</div>
        <div style={{ flex: 0.5 }}>Total Number</div>
        {DATA[0] && (
          <>
            {!query.chases && (
              <>
                <div style={{ flex: 0.7 }}>Number of Own Chassis </div>

                <div style={{ flex: 0.7 }}>Number of Pool Chassis </div>
              </>
            )}
          </>
        )}
        <div style={{ flex: 1 }}>Flip Available </div>
        <div style={{ flex: 1 }}>Last Update</div>

        <div style={{ flex: 0.5, borderRight: 'none' }}>Action</div>
      </div>
      <div className={cls.tbody}>
        {DATA.map((item, idx) => (
          <div className={cls.tableRow} key={idx}>
            <div className={cls.size} style={{ flex: 1.2 }}>
              <span>{idx + 1}</span>
              <ReactSVG src={SizeIcons(item.size)} />
              {/* <ReactSVG
                with="50px"
                src={Icons.has_containers}
                className={cls.hasContainersIcon}
              /> */}
              {item.container_numbers === 1 && (
                <ReactSVG
                  with="50px"
                  src={Icons.has_containers}
                  className={cls.hasContainersIcon}
                />
              )}
              {/* {item.has_containers == 2 ? (
                <ReactSVG
                  with="50px"
                  src={Icons.has_containers}
                  className={cls.hasContainersIcon}
                />
              ) : (
                ''
              )}
              {item.has_containers == 1 ? (
                <ReactSVG
                  with="50px"
                  src={Icons.has_containers_half}
                  className={cls.hasContainersIcon}
                />
              ) : (
                ''
              )}
              {item.has_containers == 0 ? (
                <ReactSVG
                  with="50px"
                  src={Icons.has_containers_empty}
                  className={cls.hasContainersIcon}
                />
              ) : (
                ''
              )} */}

              {/* {item.has_containers != 0 ? (
                <ReactSVG
                  with="50px"
                  src={Icons.has_containers}
                  className={cls.hasContainersIcon}
                />
              ) : (
                ''
              )} */}
            </div>
            <div
              style={{ flex: 0.7 }}
              // className={cls.bold}
            >
              {item.size}
            </div>
            <div style={{ flex: 0.7 }}>{item.pro}</div>
            <div style={{ flex: 1 }}>{item.loc}</div>
            <div style={{ flex: 0.5 }}>{item.count_available}</div>
            {!query.chases && (
              <>
                <div style={{ flex: 0.7 }}>
                  {isExport ? (
                    item.own_chases_count ? (
                      <Done />
                    ) : (
                      <Close />
                    )
                  ) : (
                    <div className={cls.chassisContainer}>
                      <span>
                        {item.own_available > item.count_available
                          ? item.count_available
                          : item.own_available}
                      </span>
                      {getProgressIcon(item, 'Own')}
                    </div>
                  )}
                </div>
                <div style={{ flex: 0.7 }}>
                  {isExport ? (
                    item.pool_chases_count ? (
                      <Done />
                    ) : (
                      <Close />
                    )
                  ) : (
                    <div className={cls.chassisContainer}>
                      <span>
                        {item.pool_available > item.count_available
                          ? item.count_available
                          : item.pool_available}
                      </span>
                      {getProgressIcon(item, 'Pool')}
                    </div>
                  )}
                </div>
              </>
            )}
            <div style={{ flex: 1 }}>{item.flip_available ? 'Yes' : 'No'}</div>
            <div style={{ flex: 1 }}>{fTimeDate(item.date)}</div>

            {/* <Button
              className={cls.but}
              onClick={
                () => req(item)
                // openReserve(item)
              }>
              Request
            </Button> */}
            <Button
              className={cls.but}
              onClick={() =>
                //  req(item)
                openReserve(item)
              }>
              Reserve
            </Button>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};
