import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  CountdownTimer: {
    display: 'flex',
    gap: 5,
    fontSize: 16,
    padding: '4px 18px 4px 11px',
    fontWeight: 'bold',
    background: '#FEF7E8',
    color: '#FFAD00',
    borderRadius: 50,
    width: 75,
    margin: '0px 5px',
  },
  timerText: {
    letterSpacing: 1,
  },
  '& svg': {
    width: 13,
  },
});
