import { API } from './API';
import { MapBoxToken, MapBoxAPI } from './constants';
import mm from 'moment';
import _get from 'lodash/get';
import { store } from '@/App';
import { askNotificationPerm } from '@/components/push-notification';
import { Reject, toForm } from './Helper';

import Types from './types';

export const SaveData = (data) =>
  store.dispatch({ type: Types.SaveData, data });

export const SaveNotif = (data) =>
  store.dispatch({ type: Types.SaveNotif, data });

export const AddData = (data) => store.dispatch({ type: Types.AddData, data });

export const getAutoComplete = (item) =>
  MapBoxAPI.get(item + '.json', {
    country: 'us',
    Types: 'plase',
    limit: 3,
    language: 'en-US',
    access_token: MapBoxToken,
  }).then(({ ok, data }) => {
    let ret = [];
    if (ok) {
      let arr = _get(data, 'features', []);
      ret = arr.map((item) => ({
        label: item.place_name,
        value: {
          lng: item.center[0],
          lat: item.center[1],
          address: item.place_name,
          city: item.place_name.split(',')[0],
        },
      }));
    }
    return ret;
  });

export const getInit = async () => {
  let size = await getSizes().then((res) => res);
  let ssl = await getCompanies().then((res) => res);
  // let activeUsers = await getActiveUsers().then((res) => res);
  let user = localStorage.token
    ? await getProfile().then((res) => res.profile)
    : {};
  let orderBadge = localStorage.token
    ? await getProfile().then((res) => res.inboxUnreadCount)
    : {};
  user && localStorage.setItem('enable_export', user.enable_export);
  SaveData({ size, ssl, user, orderBadge });
  return user;
};

export const getMarket = (page) =>
  API.get('market_place', { page }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return _get(data, 'data.market', []);
  });

export const getExportMarket = (page) =>
  API.get('export_market_place', { page }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return _get(data, 'data.market', []);
  });

export const checkUpdate = () =>
  API.post('check-update').then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data.data;
  });

export const updateSamrtApp = () =>
  API.post('change-update').then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

export const adminUpdateUserApp = (userId, data) =>
  API.post(`admin/change-update/${userId}`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

export const getFirstMarket = () =>
  API.get('featured_containers').then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return _get(data, 'data.market', []);
  });

export const exportOrderApprove = (data, orderId) =>
  API.post(`/export-request/order/approve/${orderId}`, data).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      return _get(data, 'data', []);
    }
  );

export const getExportContainers = (orderId) =>
  API.post(`/export-request/order-containers/${orderId}`).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      return _get(data, 'data', []);
    }
  );

export const orderConfirm = (orderId, data) =>
  API.post(`/order/confirm-transfer/${orderId}`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return _get(data, 'data', []);
  });

export const ExportOrderConfirm = (orderId, data) =>
  API.post(`export-request/order/confirm-transfer/${orderId}`, data).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      return _get(data, 'data', []);
    }
  );

export const getSizes = () =>
  API.get('specifications').then(({ ok, data }) => {
    if (!ok) return Reject(data);

    let arr = _get(data, 'data.specifications', []);
    return arr.map(({ size = '', name = '', model = '', id }) => ({
      label: `${size}-${name}-${model}`,
      value: id,
    }));
  });

//https://testapi.smart-turn.com/api/terminals/gate-schedule?start_date=2024-04-20&end_date=2024-04-30&terminal=7
export const getGateSchedule = (startDate, endDate, portId) =>
  API.get(
    `terminals/gate-schedule?start_date=${startDate}&end_date=${endDate}&port=${portId}`
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

export const getPerDiem = () =>
  API.get(`admin/per-diems`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

export const updatePerDiem = () =>
  API.post(`admin/per-diems/update`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

// export const getGateScheduleFilter = (data, portSlug) =>
//   API.get(`terminals/gate-schedule?start_date=2024-05-05&end_date=${endDate}`).then(({ ok, data }) => {
//     if (!ok) return Reject(data);
//     return data;
//   });

export const getVesselSchedule = (form) =>
  API.get(`terminals/vessel-schedule?${form}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });
export const getSslVesselSchedule = (form) =>
  API.get(`terminals/ssl-vessel-schedule?${form}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });
export const getAllVesselSchedule = (form) =>
  API.get(`terminals/all-vessel-schedule?${form}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });
export const getEmptyReceivingSchedule = (date) =>
  API.get(`terminals/restriction/${date}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });
export const getPortsList = () =>
  API.get(`terminals/port-list`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

export const setUserSettings = (req) =>
  API.post(`user-settings`, req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
  });

export const getUserSettings = (key) =>
  API.get(`user-settings?key=${key}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });
export const getTableList = (key) =>
  API.get(`user-settings?key=${key}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });
export const getVesselTableTitlesList = (key) =>
  API.get(`user-settings?key=${key}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

export const getCompanies = () =>
  API.get('companies').then(({ ok, data }) => {
    if (!ok) return Reject(data);

    let arr = _get(data, 'data.companies', []);
    return arr.map(({ name, id }) => ({
      label: name,
      value: id,
    }));
  });

export const getSearch = (par) => {
  // prefrence => numberMode
  const {
    t,
    pre,
    count,
    date,
    lat,
    long,
    address,
    city,
    size,
    ssl,
    chases,
    save_search,
  } = par;

  let dateString = date;
  let momentObj = mm(dateString, 'MM-DD-YYYY');
  let momentString = momentObj.format('YYYY-MM-DD HH:mm:ss');

  let req = {
    type: t || 'manual',
    count,
    date: momentString,
    prefrence: pre === 'true' ? 'number' : 'location',
    lat,
    long,
    address,
    city,
    specification_id: size,
    company_id: ssl,
    chases,
    save_search,
  };

  return API.post('search', req).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.inventories', []);
    else return Reject(data);
  });
};

export const getSearchExport = (par) => {
  // prefrence => numberMode
  const {
    t,
    pre,
    count,
    date,
    lat,
    long,
    address,
    city,
    size,
    ssl,
    chases,
    save_search,
  } = par;

  let dateString = date;
  let momentObj = mm(dateString, 'MM-DD-YYYY');
  let momentString = momentObj.format('YYYY-MM-DD HH:mm:ss');

  let req = {
    type: t || 'manual',
    count,
    date: momentString,
    prefrence: pre === 'true' ? 'number' : 'location',
    lat,
    long,
    address,
    city,
    specification_id: size,
    company_id: ssl,
    chases,
    save_search,
  };

  return API.post('/export-search', req).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.exports', []);
    else return Reject(data);
  });
};

export const getProfile = () =>
  API.get('profile').then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', {});
  });

export const getChassis = () =>
  API.get('own-chases').then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.chases', {});
  });

export const getInventoryShareLink = (container) =>
  API.post(`/share-link-container-v2/${container}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', {});
  });

export const shareLinkClick = (token) =>
  API.post(`/share-link-click-v2/${token}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });

export const getShareTokenInfo = (token) =>
  API.get(`/share-info/${token}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });

export const getInventoryShareList = () =>
  API.get('/share').then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', {});
  });

export const shareInventory = (data) =>
  API.post('/share', data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.chases', {});
  });

export const upProfile = (params) =>
  API.put('profile', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.user', {});
  });

export const enableContainer = (params) =>
  API.post('/container-name', null, { params }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', {});
  });

export const enableShareContainer = (params) =>
  API.post('/own-chases-enable', null, { params }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', {});
  });

export const enableFlipAvailable = (params) =>
  API.post('/flip-available', null, { params }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', {});
  });

export const saveChassis = (params) =>
  API.post('/own-chases', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', {});
  });

export const importChassis = (params) =>
  API.post('/own-chases-import', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });

export const changeNotifications = (params) =>
  API.post('/change-notification', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', {});
  });

export const addAvatar = (blob) => {
  let formData = new FormData();
  let file = new File([blob], 'profile_pic.jpg');
  formData.append('profile_pic', file);
  return API.post('profile', formData).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const getInventory = (params) =>
  API.get('inventory', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories', []);
  });

export const getExportInventory = (params) =>
  API.get('exports', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.exports', []);
  });

export const getSmartMatchList = (params) => {
  const request = 'request';
  return API.get('smart-match/index', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.list', []);
  });
};

export const getSavedSearches = (params) =>
  API.get('/save-searches', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', []);
  });

export const removeSaveSearch = (id) =>
  API.delete(`/save-searches/${id}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', []);
  });

export const addInventory = (params) => {
  const { date, count } = params;
  let req = {
    specification_id: _get(params, 'size.value', ''),
    company_id: _get(params, 'ssl.value', ''),
    count,
    status: 0,
    available_at: mm(date).format('YYYY-MM-DD'),
    lat: _get(params, 'loc.value.lat', ''),
    long: _get(params, 'loc.value.lng', ''),
    address: _get(params, 'loc.value.address', ''),
    city: _get(params, 'loc.value.city', ''),
    containers: _get(params, 'containers', ''),
  };
  return API.post('inventory', req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories', []);
  });
};

export const addExportInventory = (params) => {
  const { date, count, erd, cutoff, appointment_date } = params;
  let req = {
    specification_id: _get(params, 'size.value', ''),
    company_id: _get(params, 'ssl.value', ''),
    count,
    wanna_till: mm(date).format('YYYY-MM-DD'),
    lat: _get(params, 'loc.value.lat', ''),
    long: _get(params, 'loc.value.lng', ''),
    address: _get(params, 'loc.value.address', ''),
    city: _get(params, 'loc.value.city', ''),
    booking_number: _get(params, 'bookingNumber', ''),
    erd: mm(erd).format('YYYY-MM-DD'),
    cutoff: mm(cutoff).format('YYYY-MM-DD'),
    mto: _get(params, 'mto.value', ''),
    appointment_date: mm(appointment_date).format('YYYY-MM-DD'),
    bill_of_lading: _get(params, 'bol', ''),
    wanna_pool: _get(params, 'chassis.pool', ''),
    wanna_own: _get(params, 'chassis.own', ''),
  };
  return API.post('exports', req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.exports', []);
  });
};

export const upInventory = (params) => {
  const { id, date, count } = params;
  let req = {
    inventory_data: {
      specification_id: _get(params, 'size.value', ''),
      company_id: _get(params, 'ssl.value', ''),
      count,
      status: 0,
      available_at: mm(date).format('YYYY-MM-DD'),
      lat: _get(params, 'loc.value.lat', ''),
      long: _get(params, 'loc.value.lng', ''),
      address: _get(params, 'loc.value.address', ''),
      city: _get(params, 'loc.value.city', ''),
    },
    container_data: {
      gated_out: _get(params, 'gated_out', ''),
      gated_in: _get(params, 'gated_in', ''),
      chases: _get(params, 'chasses_name', ''),
      name: _get(params, 'container_name', ''),
    },
  };
  // console.log('req', params);

  return API.post(`container/${id}`, req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories', []);
  });
};

export const upBooking = (params, bookingId) => {
  const { id, date, count } = params;
  //
  let mtoId = params?.mto?.value;
  let req = {
    export_data: {
      lat: _get(params, 'loc.value.lat', ''),
      long: _get(params, 'loc.value.lng', ''),
      address: _get(params, 'loc.value.address', ''),
      city: _get(params, 'loc.value.city', ''),
      specification_id: _get(params, 'size.value', ''),
      company_id: _get(params, 'ssl.value', ''),
      wanna_till: mm(date).format('YYYY-MM-DD'),
    },
    booking_data: {
      name: _get(params, 'bookingNumber', null),
      cutoff: _get(params, 'cutoff', null),
      erd: _get(params, 'erd', null),
      appointment_date: _get(params, 'appointment_date', null),
      mto: mtoId ? mtoId : null,
    },
  };

  return API.post(`booking/${bookingId}/edit`, req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories', []);
  });
};

export const getMatchInventory = (params, bookingId) => {
  const { id, date, count } = params;
  //
  let mtoId = params?.mto?.value;
  let req = {
    export_id: _get(params, 'exportId.id', ''),
    empty_id: _get(params, 'inventoryId.id', ''),
    booking_id:
      params.selected.type === 'ex'
        ? params.selected.id
        : params.selectedMatch.id,
    container_id:
      params.selected.type === 'em'
        ? params.selected.id
        : params.selectedMatch.id,
  };
  // console.log('req', req);

  return API.post(`smart-match/match`, req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', []);
  });
};
export const getUnMatchInventory = (params) => {
  //

  return API.post(`smart-match/unmatch`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', []);
  });
};

export const upExportInventory = (params) => {
  const { id, date, count } = params;
  let req = {
    specification_id: _get(params, 'size.value', ''),
    company_id: _get(params, 'ssl.value', ''),
    count,
    status: 0,
    available_at: mm(date).format('YYYY-MM-DD'),
    lat: _get(params, 'loc.value.lat', ''),
    long: _get(params, 'loc.value.lng', ''),
    address: _get(params, 'loc.value.address', ''),
    city: _get(params, 'loc.value.city', ''),
  };
  return API.put(`exports/${id}`, req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories', []);
  });
};

export const delInventory = (id) =>
  API.delete(`inventory/${id}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'message', '');
  });

export const delContainer = (id) =>
  API.delete(`containers/${id}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'message', '');
  });
export const deleteAllApi = (id) =>
  API.delete(`delete-all-containers`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'message', '');
  });

export const delExportInventory = (id) =>
  API.delete(`booking/${id}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'message', '');
  });

export const importInventory = (inventory) => {
  let req = toForm({ inventory });

  return API.post('export/inventory', req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories.data', []);
  });
};

export const importExportInventory = (inventory) => {
  let req = toForm({ inventory });

  return API.post('exports/import', req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories.data', []);
  });
};

export const enableInventoryContainer = (containerId) => {
  return API.put(`containers/${containerId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};
export const enableInventoryBooking = (containerId) => {
  return API.put(`booking/${containerId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};
export const disableInventoryContainer = (containerId) => {
  return API.patch(`containers/${containerId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};
export const disableInventoryBooking = (containerId) => {
  return API.patch(`booking/${containerId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const updateInventoriContainers = (inventory_id, data) => {
  return API.post(`/containers/${inventory_id}`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const exportInventory = () =>
  API.get('export/inventory').then(({ ok, data }) => {
    if (ok) return _get(data, 'data.link', '');
    else return Reject(data);
  });

export const getReq = (type) =>
  API.get(`requests/${type}`).then(({ ok, data }) => {
    if (ok) {
      let res = _get(data, 'data.requests', []);
      let smart = _get(data, 'data.smart', []);
      if (Array.isArray(smart)) return res.concat(smart);
      else return res;
    } else return Reject(data);
  });

export const getSmart = (id) =>
  API.get(`smart/${id}`).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.requests', []);
    else return Reject(data);
  });

export const showReq = (id) =>
  API.get(`request/${id}`).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.request', {});
    else return Reject(data);
  });
export const getOrdersInbox = (params) =>
  API.get('orders/all', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', []);
  });
export const getRequestsInbox = (params) =>
  API.get('requests/all', params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', []);
  });

export const getOrder = (type) =>
  API.get(`orders/${type}`).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.orders', []);
    else return Reject(data);
  });

export const RejectOrder = (id) =>
  API.delete(`order/reject/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        msg: 'Offer Rejected.',
        data: _get(data, 'data.orders', []),
      };
    else return Reject(data);
  });
export const RejectRequest = (id) =>
  API.delete(`request/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        msg: 'Offer Rejected.',
        data: _get(data, 'data.orders', []),
      };
    else return Reject(data);
  });

export const RejectExport = (id) =>
  API.delete(`/export-request/order/reject/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        msg: 'Offer Rejected.',
        data: _get(data, 'data', []),
      };
    else return Reject(data);
  });

export const RejectOrderExport = (id) =>
  API.delete(`/export-request/order/reject/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        msg: 'Offer Rejected.',
        data: _get(data, 'data.orders', []),
      };
    else return Reject(data);
  });

export const finishOrder = (id, req) =>
  API.post(`order/finish/${id}`, req && req).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.order', {});
    else return Reject(data);
  });

export const finishOrderExport = (id, req) =>
  API.post(`export-request/order/finish/${id}`, req && req).then(
    ({ ok, data }) => {
      if (ok) return _get(data, 'data.order', {});
      else return Reject(data);
    }
  );

export const showOrder = (id) =>
  API.get(`order/${id}`).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.order', {});
    else return Reject(data);
  });

export const getChat = (id, mode, user) =>
  API.get(`order/${id}/chat?mode=${mode}`).then(({ ok, data }) => {
    if (ok)
      return {
        ls: _get(data, 'data.conversation.messages', []),
        conversation: _get(data, 'data.conversation', []),
        chn: _get(data, 'data.pusherKeys.conversationChannel.name', ''),
        headerData: _get(data, 'data.order', {}),
        contactInfo: _get(data, 'data.contactInfo', {}),
        otherPart:
          user.id === data.data.order.user_id
            ? _get(data, 'data.order.inventory.user', {})
            : _get(data, 'data.order.user', {}),
      };
    else return Reject(data);
  });
export const uploadContainerImages = (req) => {
  const formData = new FormData();

  formData.append('container_id', req.container_id);
  formData.append('offer_id', req.offer_id);

  req.images.forEach((image, index) => {
    if (image.file instanceof File) {
      formData.append(`image[${index + 1}]`, image.file);
    } else {
      console.error(`Invalid file type at index ${index}`, image.file);
    }
  });

  for (const [key, value] of formData.entries()) {
    // console.log(`${key}:`, value);
  }

  return API.post(
    '/conversation-function/pre-approve/upload-image',
    formData
  ).then(({ ok, data }) => {
    if (ok) return data;
    else return Promise.reject(new Error('Upload failed'));
  });
};

export const deleteContainerImages = (id) => {
  // for (const [key, value] of formData.entries()) {
  //   console.log(`${key}:`, value);
  // }

  return API.get(`/conversation-function/pre-approve/delete-image/${id}`).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Promise.reject(new Error('Upload failed'));
    }
  );
};

export const uploadContainerApproval = (req) => {
  const formData = new FormData();

  formData.append('container_id', req.container_id);
  formData.append('offer_id', req.offer_id);

  req.images.forEach((image, index) => {
    if (image.file instanceof File) {
      formData.append(`document[${index + 1}]`, image.file);
    } else {
      console.error(`Invalid file type at index ${index}`, image.file);
    }
  });

  for (const [key, value] of formData.entries()) {
    console.log(`${key}:`, value);
  }

  return API.post(`/order/approval/${req.orderID}`, formData).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Promise.reject(new Error('Upload failed'));
    }
  );
};

export const orderApprove = (req, orderId) => {
  const formData = new FormData();

  req.images.forEach((image, index) => {
    if (image.file instanceof File) {
      formData.append(`document`, image.file);
    } else {
      console.error(`Invalid file type at index ${index}`, image.file);
    }
  });

  API.post(`/order/approve/${orderId}`, formData).then(({ ok, data }) => {
    if (ok) return data;
    else return Promise.reject(new Error('Upload failed'));
  });
};

export const sendPreApproveData = (req) => {
  let orderID = req.orderID;
  let formData = new FormData();
  formData.append('order_type', req.order_type);
  req.containers.forEach((container, index) => {
    formData.append(
      `containers[${index}][container_id]`,
      container.container_id
    );
    formData.append(`containers[${index}][pickup_way]`, container.pickup_way);
    formData.append(
      `containers[${index}][pickup_location]`,
      container.pickup_location
    );
    formData.append(
      `containers[${index}][pickup_date_start]`,
      container.pickup_date_start
    );
    formData.append(
      `containers[${index}][pickup_date_end]`,
      container.pickup_date_end
    );
    formData.append(
      `containers[${index}][container_conditions]`,
      container.container_conditions
    );
  });

  return API.post(
    `/conversation-function/pre-approve/${orderID}/store`,
    formData
  ).then(({ ok, data }) => {
    if (ok) return data;
    else return Promise.reject(data);
  });
};

export const sendApproveData = (req) => {
  let orderID = req.orderID;
  let formData = new FormData();

  // console.log(req.containers);

  req.containers.forEach((container, index) => {
    formData.append(
      `containers[${index}][container_id]`,
      container.container_id
    );
    formData.append(
      `containers[${index}][approve_status]`,
      container.approve_status
    );
  });

  return API.post(
    `/conversation-function/approve/${orderID}/store`,
    formData
  ).then(({ ok, data }) => {
    if (ok) return data;
    else return Promise.reject(data);
  });
};

export const getAdminNewChat = (id) =>
  API.get(`admin/chats/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        ls: _get(data, 'data.conversation.messages', []),
        chn: _get(data, 'data.pusherKeys.conversationChannel.name', ''),
        headerData: _get(data, 'data.order', {}),
        user: _get(data, 'data.chatWithUser', {}),
      };
    else return Reject(data);
  });

export const getChatList = (type) =>
  API.get(`chats/${type}`).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.Chats', []);
    else return Reject(data);
  });

export const getAdminNewChatList = () =>
  API.get(`admin/chats`).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.Chats', []);
    else return Reject(data);
  });

export const addChat = (id, message, mode) => {
  return API.post(`order/${id}/chat?mode=${mode}`, toForm({ message })).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
    }
  );
};

export const addAdminChat = (id, message) =>
  API.post(`admin/chats/${id}`, toForm({ message })).then(({ ok, data }) => {
    if (!ok) return Reject(data);
  });

export const seenChat = (id, chat_id) =>
  API.put(`order/${id}/chat/${chat_id}`).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
  });

export const SupportseenChat = (chat_id) =>
  API.put(`admin/chats/${chat_id}`).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
  });

export const getSupportChatList = () =>
  API.get(`support`).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.Chats', []);
    else return Reject(data);
  });

export const SupportseenChatByUser = (chat_id) =>
  API.put(`support/${chat_id}`).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
  });

export const getSupportNewChat = (id) =>
  API.get(`support/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        ls: _get(data, 'data.conversation.messages', []),
        chn: _get(data, 'data.pusherKeys.conversationChannel.name', ''),
        headerData: _get(data, 'data.order', {}),
      };
    else return Reject(data);
  });
export const getSupportNewChatByChatId = (id) =>
  API.get(`support/chat/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        chatWithUser: _get(data, 'data.chatWithUser', []),
        ls: _get(data, 'data.conversation.messages', []),
        chn: _get(data, 'data.pusherKeys.conversationChannel.name', ''),
        headerData: _get(data, 'data.order', {}),
      };
    else return Reject(data);
  });

export const addSupportChat = (id, message) =>
  API.post(`support/${id}`, toForm({ message })).then(({ ok, data }) => {
    if (!ok) return Reject(data);
  });

export const Request = (req) =>
  API.post('request', req).then(({ ok, data }) => {
    if (ok) return 'Your request has been sent successfully.';
    else return Reject(data);
  });

export const RequestExport = (req) =>
  API.post('export-request/request', req).then(({ ok, data }) => {
    if (ok) return 'Your request has been sent successfully.';
    else return Reject(data);
  });

export const AccRequest = ({ id, count, mode }) =>
  API.post(`order/accept/${id}`, { count }).then(({ ok, data }) => {
    if (ok) return 'Offer accepted.';
    else return Reject(data);
  });

export const AccRequestExport = ({ id, count, mode }) =>
  API.post(`/export-request/order/accept/${id}`, { count }).then(
    ({ ok, data }) => {
      if (ok) return 'Offer accepted.';
      else return Reject(data);
    }
  );

export const delReq = (id, mode) =>
  API.delete(`request/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        msg: 'Request Canceled.',
        data: _get(data, 'data.requests', []),
      };
    else return Reject(data);
  });

export const delExportReq = (id) =>
  API.delete(`export-request/request/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        msg: 'Request Canceled.',
        data: _get(data, 'data.requests', []),
      };
    else return Reject(data);
  });

export const delReqOrder = (id) =>
  API.delete(`/export-request/request/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        msg: 'Request Canceled.',
        data: _get(data, 'data', []),
      };
    else return Reject(data);
  });

export const delSmart = (id) =>
  API.post(`search/stop/${id}`).then(({ ok, data }) => {
    if (ok)
      return {
        msg: 'Request Rejected.',
        data: _get(data, 'data.requests', []),
      };
    else return Reject(data);
  });

export const setFirebaseToken = (device_token) => {
  API.post('set_device_token', { device_token }).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
  });
};

export const getNotifications = () =>
  API.get('notifications').then(({ ok, data }) => {
    if (ok) return _get(data, 'data.notifications', []);
    else return Reject(data);
  });

export const seenNotif = (id) =>
  API.post(`notifications/${id}`).then(({ ok, data }) => {
    if (!ok) console.error('err => ', data);
  });

export const oauthLogin = (access_token) =>
  API.post('oauth/login', { access_token }).then(({ ok, data }) => {
    if (ok) {
      let token = _get(data, 'data.access_token', null);
      if (token) localStorage.token = token;
      let role = _get(data, 'data.roles', 'user');
      if (role) localStorage.permissions = role;
      askNotificationPerm();
      return Promise.resolve();
    } else console.error('err => ', data);
  });

export const changePassword = (req) =>
  API.post('password', req).then(({ ok, data }) => {
    if (ok) return _get(data, 'data.user', {});
    else return Reject(data);
  });

export const getReqList = () =>
  API.get('requests/all?count=7').then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    let res = _get(data, 'data.requests', []);
    let smart = _get(data, 'data.smart', []);
    if (Array.isArray(smart)) return res.concat(smart);
    else return res;
  });

export const getOrderList = () =>
  API.get('orders/all?count=7').then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return _get(data, 'data.orders', []);
  });

export const getReportList = () =>
  API.get('dashboard/monthly_report?count=10').then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return {
      ord: _get(data, 'data.orders', {}),
      req: _get(data, 'data.requests', {}),
    };
  });

export const getCompareReport = () =>
  API.get('dashboard/compare_last_month').then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return {
      com: _get(data, 'data.completedDeals', {}),
      ord: _get(data, 'data.orders', {}),
      rej: _get(data, 'data.rejectedDeals', {}),
      req: _get(data, 'data.requests', {}),
    };
  });

export const forgetPass = (email) =>
  API.post('password/forgot', { email }).then(({ ok, data }) => {
    if (ok) return _get(data, 'message', '');
    else {
      let msg = _get(data, 'message', '');
      let err = _get(data, 'errors', null);
      if (err && err.email) msg = err.email[0];
      return Promise.reject(msg);
    }
  });

export const getReportByDate = ({ start_date, end_date }) =>
  API.get('dashboard/report', { start_date, end_date }).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return {
      ord: _get(data, 'data.orders', {}),
      req: _get(data, 'data.requests', {}),
    };
  });

export const sendReferralInvite = (data) =>
  API.post('/referral_program', data).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return data;
  });

export const getReferredList = (page = 1) =>
  API.get('/referral_program', { page }).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return data;
  });

//admin apis
export const getAdminMembers = (params) =>
  API.get(`/admin/members`, params).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return data;
  });

export const getScrapeGateList = (PortId) =>
  API.get(`/admin/scrape-setting/gate?port=${PortId}`).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return data;
  });
export const getScrapeRecivablesList = (PortId) =>
  API.get(`/admin/scrape-setting/receivable?port=${PortId}`).then(
    ({ ok, data }) => {
      if (!ok) console.error('err =>', data);
      return data;
    }
  );
export const getScrapeVesselsList = (PortId, source) =>
  API.get(`/admin/scrape-setting/vessel?port=${PortId}${source}`).then(
    ({ ok, data }) => {
      if (!ok) console.error('err =>', data);
      return data;
    }
  );
export const getScrapeVesselsListPr = () =>
  API.get(`/admin/scrape-setting/vessel-prioritization`).then(
    ({ ok, data }) => {
      if (!ok) console.error('err =>', data);
      return data;
    }
  );
export const getAppointmentsSetting = (date) =>
  API.get(`admin/scrape-setting/appointment`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });
export const getAdmins = (params) =>
  API.get(`/admin/members-admin`, params).then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return data;
  });

export const adminAddMember = (req) => {
  let formData = new FormData();
  formData.append('company', req.company);
  formData.append('email', req.email);
  formData.append('last_name', req.last_name);
  formData.append('name', req.name);
  formData.append('password', req.password);
  formData.append('phone', req.phone);
  if (req.image) {
    formData.append('image', req.image);
  }

  return API.post('/admin/members', formData).then(({ ok, data }) => {
    if (ok) return data;
    else return Promise.reject(_get(data, 'data', 'something went wrong'));
  });
};

export const AddAdmin = (req) => {
  let formData = new FormData();
  formData.append('company', req.company);
  formData.append('email', req.email);
  formData.append('last_name', req.last_name);
  formData.append('name', req.name);
  formData.append('password', req.password);
  formData.append('phone', req.phone);
  if (req.image) {
    formData.append('image', req.image);
  }
  return API.post('/admin/members-admin', formData).then(({ ok, data }) => {
    if (ok) return data;
    else return Promise.reject(_get(data, 'data', 'something went wrong'));
  });
};

export const adminSendVerificationEmail = (user_id) => {
  return API.post(`/admin/resend-verify-email/${user_id}`).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Reject(data);
    }
  );
};

export const adminEditMember = (req) => {
  let formData = new FormData();
  req.image && formData.append('image', req.image);
  req.company && formData.append('company', req.company);
  req.email && formData.append('email', req.email);
  req.last_name && formData.append('last_name', req.last_name);
  req.name && formData.append('name', req.name);
  req.password && formData.append('password', req.password);
  req.phone && formData.append('phone', req.phone);
  (req.status || req.status === 0) && formData.append('status', req.status);
  formData.append('_method', 'patch');
  return API.post(`/admin/members/${req.id}`, formData).then(({ ok, data }) => {
    if (ok) return data;
    else return Reject(data);
  });
};

export const adminChangeExport = (formData) => {
  return API.post(`/admin/members/enable-export`, formData).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Reject(data);
    }
  );
};
export const adminChangeStreetTurn = (formData) => {
  return API.post(`/admin/members/enable-street-turn`, formData).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Reject(data);
    }
  );
};
export const adminChangeApoointment = (formData) => {
  return API.post(`/admin/members/enable-appointments`, formData).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Reject(data);
    }
  );
};

export const adminChangeSchedules = (formData) => {
  return API.post(`/admin/members/enable-schedules`, formData).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Reject(data);
    }
  );
};
export const adminChangeEmptyTracking = (formData) => {
  return API.post(`/admin/members/enable-empty-tracking`, formData).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Reject(data);
    }
  );
};
export const adminChangeTerminalCredentials = (formData) => {
  return API.post(`/admin/members/enable-login-credentials`, formData).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Reject(data);
    }
  );
};

export const editAdmin = (req) => {
  let formData = new FormData();
  req.image && formData.append('image', req.image);
  req.company && formData.append('company', req.company);
  req.email && formData.append('email', req.email);
  req.last_name && formData.append('last_name', req.last_name);
  req.name && formData.append('name', req.name);
  req.password && formData.append('password', req.password);
  req.phone && formData.append('phone', req.phone);
  (req.status || req.status === 0) && formData.append('status', req.status);

  formData.append('_method', 'patch');
  return API.post(`/admin/members-admin/${req.id}`, formData).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Reject(data);
    }
  );
};

export const adminDeleteMember = (id) => {
  return API.delete(`/admin/members/${id}`).then(({ ok, data }) => {
    if (ok) return data;
    else return Reject(data);
  });
};

export const deleteAdmin = (id) => {
  return API.delete(`/admin/members-admin/${id}`).then(({ ok, data }) => {
    if (ok) return data;
    else return Reject(data);
  });
};

export const getAdminInventory = (params) => {
  return API.get(`/admin/inventories`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const getAdminInventoryCompleted = (params) =>
  API.get(`/admin/inventories-completed`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });

export const getAdminInventoryConfirmed = (params) =>
  API.get(`/admin/inventories-disputed`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });

export const adminDelInventoryCompleted = (id) =>
  API.patch(`/admin/inventories-completed/restore-inventory`, null, {
    params: { order: id },
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'message', '');
  });

export const adminAddInventory = (params) => {
  const { date, count } = params;
  let req = {
    specification_id: _get(params, 'size.value', ''),
    company_id: _get(params, 'ssl.value', ''),
    count,
    status: 0,
    available_at: mm(date).format('YYYY-MM-DD'),
    lat: _get(params, 'loc.value.lat', ''),
    long: _get(params, 'loc.value.lng', ''),
    address: _get(params, 'loc.value.address', ''),
    city: _get(params, 'loc.value.city', ''),
    containers: _get(params, 'containers', ''),
    user_id: _get(params, 'user_id.value', ''),
  };
  return API.post('/admin/inventories', req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories', []);
  });
};

export const getActiveUsers = () =>
  API.get('/admin/active-users').then(({ ok, data }) => {
    if (!ok) return Reject(data);

    let arr = _get(data, 'data.data', []);
    return arr.map(({ name, id }) => ({
      label: name,
      value: id,
    }));
  });

export const getAdminFinanceDailyReport = (company) =>
  API.get(
    '/admin/finance/daily-report',
    company ? { user_id: company } : {}
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data;
  });

export const getAdminFinanceMonthlyReport = (company) =>
  API.get(
    '/admin/finance/monthly-report',
    company ? { user_id: company } : {}
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data;
  });

export const getAdminFinanceYearlyReport = (company) =>
  API.get(
    '/admin/finance/yearly-report',
    company ? { user_id: company } : {}
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data;
  });

export const getAdminFinanceCustomReport = (date, company) => {
  const dateParams = {
    from_date: date.from,
    to_date: date.to,
  };
  if (company) {
    dateParams.user_id = company;
  }

  return API.get('/admin/finance/custom-date-report', dateParams).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data.data;
    }
  );
};

export const getAdminFinanceTrackingCompanies = () => {
  return API.get('/admin/finance/tracking-companies').then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data[0];
  });
};

export const getAdminFinanceRequests = (company) => {
  return API.get(
    '/admin/finance/requests',
    company ? { user_id: company } : {}
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data;
  });
};

export const adminUpInventory = (params) => {
  const { id, date, count } = params;
  let req = {
    specification_id: _get(params, 'size.value', ''),
    company_id: _get(params, 'ssl.value', ''),
    count,
    // status: 1,
    available_at: mm(date).format('YYYY-MM-DD'),
    lat: _get(params, 'loc.value.lat', ''),
    long: _get(params, 'loc.value.lng', ''),
    address: _get(params, 'loc.value.address', ''),
    city: _get(params, 'loc.value.city', ''),
  };
  return API.put(`/admin/inventories/${id}`, req).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories', []);
  });
};

export const adminDelInventory = (id) =>
  API.delete(`/admin/inventories/${id}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'message', '');
  });

export const getAdminCompareReport = () =>
  API.get('/admin/finance/compare-last-month').then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return {
      com: _get(data, 'data.completedDeals', {}),
      ord: _get(data, 'data.orders', {}),
      rej: _get(data, 'data.rejectedDeals', {}),
      req: _get(data, 'data.requests', {}),
    };
  });

export const enableAdminInventoryContainer = (containerId) => {
  return API.put(`/admin/containers/${containerId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const disableAdminInventoryContainer = (containerId) => {
  return API.patch(`/admin/containers/${containerId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminChangeFTPIntegration = (params) => {
  return API.post(`/admin/ftp-integration`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminChangeXlsUrl = (params) => {
  return API.post(`/admin/xls-url`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};
export const adminChangeXlsUrlExport = (params) => {
  return API.post(`/admin/xls_url_export`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminGetSingleUser = (userId) => {
  return API.get(`/admin/members/${userId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data.user;
  });
};

export const adminGetTmsList = (userId) => {
  return API.get(`/admin/tms-types`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.tms_types;
  });
};

export const adminDeleteTms = (tmsId, tmsName) => {
  return API.delete(`/admin/tms-types/${tmsId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminGetExcelConfigs = (userId) => {
  return API.get(`/admin/excel-configs/${userId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.excel_config;
  });
};
export const adminGetExcelConfigsExport = (userId) => {
  return API.get(`/admin/excel-configs-export/${userId}`).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data.excel_config;
    }
  );
};

export const adminChangeActiveExcelMode = (userId, active_excel_mode) => {
  return API.post(`/admin/user-active-excel-mode/${userId}`, {
    _method: 'patch',
    active_excel_mode,
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.user;
  });
};
export const adminChangeActiveExcelModeExport = (
  userId,
  active_excel_mode_export
) => {
  return API.post(`/admin/user-active-excel-mode-export/${userId}`, {
    _method: 'patch',
    active_excel_mode_export,
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.user;
  });
};

export const adminSetTmsMailInfo = (mailInfo, userId) => {
  return API.post(`/admin/members-tms-mail/${userId}`, mailInfo).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data.excel_config;
    }
  );
};

export const adminSetTmsMailInfoExport = (mailInfo, userId) => {
  return API.post(`/admin/members-tms-mail-export/${userId}`, mailInfo).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data.excel_config;
    }
  );
};

export const adminChangeTmsType = (tms_type, userId) => {
  return API.post(`/admin/excel-configs/change-tms-type/${userId}`, {
    tms_type,
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.excel_config;
  });
};
export const adminChangeTmsTypeExport = (tms_type_export, userId) => {
  return API.post(`/admin/excel-configs-export/change-tms-type/${userId}`, {
    tms_type_export,
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.excel_config;
  });
};

export const adminGetSingleTmsType = (tmsId) => {
  return API.get(`/admin/tms-types/${tmsId}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.tms_type;
  });
};

export const adminGetUpdatedMembers = (params) => {
  return API.get(`/admin/new-update-updated-members`, params).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};

export const adminGetLogs = (params) => {
  return API.get(`/admin/app-error-logs`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data.logs;
  });
};

export const getAlertsList = (params) => {
  return API.get(`/admin/scrape-alert`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data;
  });
};
export const getAlertsFormData = (params) => {
  return API.get(`/admin/scrape-alert/form-data`, params).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data.data;
    }
  );
};

export const addAlert = (data) => {
  return API.post(`/admin/scrape-alert/store`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};
export const deleteAlert = (id) => {
  return API.delete(`/admin/scrape-alert/${id}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminUpdateUsersApp = (params) => {
  return API.post(`/admin/new-update`, params).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminSaveExcelConfigs = (excelConfig, userId) => {
  return API.post(`/admin/excel-configs/${userId}`, excelConfig).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};
export const adminSaveExcelConfigsExport = (excelConfig, userId) => {
  return API.post(`/admin/excel-configs-export/${userId}`, excelConfig).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};

export const adminSaveTmsExcelConfigs = (excelConfig, tmsId) => {
  return API.patch(`/admin/tms-types/${tmsId}`, excelConfig).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};

export const adminAddNewTms = (name) => {
  return API.post(`/admin/tms-types`, { name }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminChangeScrapeSetting = (terminalId, key, value) => {
  // Create an object where the key is dynamically set to the value
  let data = {
    [key]: value, // Dynamically set key-value
  };
  return API.post(`/admin/scrape-setting/update/${terminalId}`, data).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};

export const adminChangeScrapeSettingPr = (ID, value) => {
  // Create an object where the key is dynamically set to the value
  let data = {
    priority: value, // Dynamically set key-value
  };
  return API.post(
    `/admin/scrape-setting/vessel-prioritization/update/${ID}`,
    data
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminChangeGateShiftsStatus = (
  terminal_id,
  date,
  shift_column,
  shift_value
) => {
  // Create an object where the key is dynamically set to the value
  let data = {
    terminal_id: terminal_id,
    date: date,
    shift_column: shift_column,
    shift_value: shift_value,
  };

  console.log('data in api', data);

  return API.post(`/admin/scrape-edit/gate`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};
export const adminChangeRecivShiftsStatus = (
  terminal_id,
  date,
  restriction_id,
  shift_column,
  shift_value,
  shift_message,
  ssl_key,
  container_type,
  shift_value_real
) => {
  let data = {
    terminal_id: terminal_id,
    date: date,
    restriction_id: restriction_id,
    shift_column: shift_column,
    shift_value,

    ...(shift_message !== undefined &&
      shift_message !== null && { shift_message }),
    ssl_key: ssl_key,
    container_type: container_type,
  };

  // console.log('data in api', data);

  return API.post(`/admin/scrape-edit/receivable`, data).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};

export const adminUploadHtmlToScrape = (data) => {
  return API.post(`/admin/terminal-page-data`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminChangeVesselFieldData = (
  vessel_source,
  vessel_id,
  column_name,
  column_value
) => {
  let data = {
    vessel_source: vessel_source,
    vessel_id: vessel_id,
    column_name: column_name,
    column_value: column_value,
  };

  console.log('data in api', data);

  return API.post(`/admin/scrape-edit/vessel`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminChangeSizeTypeModel = (size_type_model, userId) => {
  return API.post(`/admin/excel-configs/change-size-type-mode/${userId}`, {
    size_type_model,
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.excel_config;
  });
};
export const adminChangeSizeTypeModelExport = (size_type_model, userId) => {
  return API.post(
    `/admin/excel-configs-export/change-size-type-mode/${userId}`,
    {
      size_type_model,
    }
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.excel_config_export;
  });
};

export const adminChangeTmsSizeTypeModel = (size_type_model, tmsId) => {
  return API.post(`/admin/tms-types/change-size-type-mode/${tmsId}`, {
    size_type_model,
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.tms_type;
  });
};

export const adminExportInventory = (company_id) => {
  return API.get(`/admin/export-excel/${company_id}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminGetAvalableInventories = () => {
  return API.get(`/admin/all-available-inventories`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data.data.stock;
  });
};

export const adminExportAvalableInventories = () =>
  API.get('/admin/download-all-available-inventories').then(({ ok, data }) => {
    if (ok) return data;
    else return Reject(data);
  });

export const adminGetEmptyListForWhatsapp = () =>
  API.get('/admin/all-empty-list-for-whatsapp').then(({ ok, data }) => {
    if (ok) return data.data.empties;
    else return Reject(data);
  });

export const adminImportInventory = (company_id, excel) => {
  let req = toForm({ excel });

  return API.post(`/admin/import-excel`, req, {
    params: { user: company_id },
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.inventories', []);
  });
};

export const adminLoginToUserAccount = (user) => {
  return API.patch(`/admin/generate-user-token`, null, {
    params: { user },
  }).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', []);
  });
};

export const adminGetReferralPrograms = () => {
  return API.get(`admin/referral_programs`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.referral_programs', []);
  });
};

export const adminAddReferralProgram = (data) => {
  return API.post(`/admin/referral_programs`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data', []);
  });
};

export const adminEditReferralProgram = (userId, data) => {
  return API.post(`/admin/referral_programs/${userId}`, data).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return _get(data, 'data', []);
    }
  );
};

export const adminDeleteReferralProgram = (userId) => {
  return API.delete(`/admin/referral_programs/${userId}`).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return _get(data, 'data', []);
    }
  );
};

export const adminGetReferredList = (page = 1) => {
  return API.get(`/admin/referred_company?${page}`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return _get(data, 'data.referred_companies', []);
  });
};

export const adminApproveReferral = (referredCompany) => {
  return API.post(`/admin/referred_company/${referredCompany}/approve`).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};

export const adminDisapproveReferral = (referredCompany) => {
  return API.post(`/admin/referred_company/${referredCompany}/disapprove`).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};

export const adminUpdateReferredCompany = (data, company_id) => {
  return API.post(`/admin/referred_company/${company_id}`, data).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      else return data;
    }
  );
};

export const adminGetWhatsappUserList = () => {
  return API.get(`/admin/whatsapp-replay-user-list`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminGetWhatsappUserMessages = (user_id, page) => {
  return API.get(
    `/admin/whatsapp-replay-user-messages?from=${user_id}&page=${page}`
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const aiWhatsappGroupList = () => {
  return API.post(`/admin/whatsapp/group-list`).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};
export const aiWhatsapp = (formToApiType, formToApigroup) => {
  return API.post(
    `/admin/whatsapp/v2/messages${formToApiType}${formToApigroup}`
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminSendWhatsappMessage = (data) => {
  return API.put(`/admin/whatsapp-send-message`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    else return data;
  });
};

export const adminChangeWhatsappReplayStatus = (data, whatsappAfterAiAnalyze) =>
  API.post(
    `/admin/whatsapp-replay-change-status/${whatsappAfterAiAnalyze}`,
    data
  ).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

export const adminEditWhatsappReplay = (data, whatsappAfterAiAnalyze) =>
  API.post(`/admin/whatsapp-edit-token/${whatsappAfterAiAnalyze}`, data).then(
    ({ ok, data }) => {
      if (!ok) return Reject(data);
      return data;
    }
  );

export const getTerminalsList = () =>
  API.get('/port-terminals').then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return data;
  });
export const getSslList = () =>
  API.get('/ssl-list').then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return data;
  });
export const getTerminalsCredList = () =>
  API.get('/credentials').then(({ ok, data }) => {
    if (!ok) console.error('err =>', data);
    return data;
  });

// export const postTerminalCredsFunc = (data) => {
//   // Prepare the request options
//   console.log("dataapooo", data);

//   // const options = {
//   //   body: JSON.stringify(data), // Convert the data object to a JSON string
//   // };

//   return API.post('/credentials', data)
//     .then((response) => response.json())
//     .then(({ ok, data }) => {
//       if (ok) return data;
//       else return Promise.reject(data.message || 'Some thing went wrong');
//     })
//     .catch((error) => {
//       console.error('Error:', error);
//       return Promise.reject(error);
//     });
// };
export const postTerminalCredsFunc = (data) =>
  API.post(`/credentials`, data).then(({ ok, data }) => {
    if (!ok) return Reject(data);
    return data;
  });

export const availableApointments = ({ ssl, size, containerId }) =>
  API.get(`/available-apt/${ssl}/${size}/${containerId}`).then(
    ({ ok, data }) => {
      if (ok) return _get(data, 'data', '');
      else return Reject(data);
    }
  );

export const availableApointmentTimes = ({ cabinId, terminalId, date }) =>
  API.get(`/available-apt-in/${cabinId}/${terminalId}/${date}`).then(
    ({ ok, data }) => {
      if (ok) return _get(data, 'data', '');
      else return Reject(data);
    }
  );

export const bookApointment = ({
  containerId,
  TerminalAppointmentAvailabilityId,
}) => {
  return API.post(
    `/make-appointment/${containerId}/${TerminalAppointmentAvailabilityId}`
  ).then(({ ok, data }) => {
    if (ok) return data;
    else return Reject(data);
  });
};

export const deleteApprovalImages = (approvalId) => {
  return API.delete(`/order/approval/delete-approval/${approvalId}`).then(
    ({ ok, data }) => {
      if (ok) return data;
      else return Promise.reject(new Error('Delete failed'));
    }
  );
};
