import React, { forwardRef, useCallback } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { MenuItem, ListItemIcon, Tooltip } from '@material-ui/core';
import styles from './styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const NavLinkRef = forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

export default forwardRef(
  ({ leftIcon, onClick, sidebarIsOpen, hasBadgeNum }, ref) => {
    const cls = styles();
    const handleMenuTap = useCallback((e) => onClick && onClick(e), [onClick]);
    const user = useSelector((state) => state.reducer.user);

    const { pathname } = useLocation();

    const enable_export = localStorage.getItem('enable_export');

    const renderMenuItem = () => (
      <MenuItem
        className={clsx(sidebarIsOpen ? cls.open : cls.close)}
        activeClassName={cls.active}
        component={NavLinkRef}
        ref={ref}
        to="/inbox"
        onClick={handleMenuTap}>
        <ListItemIcon className={cls.listItemIcon}>{leftIcon}</ListItemIcon>
        <span className="DashboardItemText">Inbox</span>
        {user.id && (
          <div className="RequestResource" style={{ width: '100%' }}>
            <div className={cls.child}>
              <MenuItem
                className={cls.item}
                activeClassName={cls.itemActive}
                component={NavLinkRef}
                ref={ref}
                to={pathname === '/inbox' ? '/inbox' : '/inbox/inbox'}
                onClick={handleMenuTap}>
                Inbox
              </MenuItem>
              {/* <MenuItem
                className={cls.item}
                activeClassName={cls.itemActive}
                component={NavLinkRef}
                ref={ref}
                to="/inHouse/export"
                onClick={handleMenuTap}>
                Export
              </MenuItem>
              <MenuItem
                className={cls.item}
                activeClassName={cls.itemActive}
                component={NavLinkRef}
                ref={ref}
                to="/inHouse/streetturn"
                onClick={handleMenuTap}>
                Street Turn
              </MenuItem> */}
            </div>
            {hasBadgeNum > 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '21px',
                  height: '20px',
                  background: 'red',
                  borderRadius: '50%',
                  position: 'absolute',
                  padding: '0px 0px 1px 0px',
                  right: 4,
                  top: 3,
                  fontSize: 12,
                  color: '#fff',
                }}>
                {hasBadgeNum}
              </div>
            )}
          </div>
        )}
      </MenuItem>
    );

    if (sidebarIsOpen) return renderMenuItem();

    return (
      <Tooltip arrow title="features" placement="right">
        {renderMenuItem()}
      </Tooltip>
    );
  }
);
