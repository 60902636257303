import {
  getAdminNewChatList,
  getChat,
  SaveData,
  seenChat,
  SupportseenChat,
  SupportseenChatByUser,
} from '@/actions';
import React, { useEffect, useState } from 'react';
import List from './List';
import Messages from './Messages';
import styles from './styles';
import { _get, removeNoScroll } from '@/actions/Helper';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import useMediaQuery from 'use-mediaquery';
import { Avatar } from '@mui/material';
import { Tooltip, Button } from '@material-ui/core';
import {
  AccountCircle,
  Close,
  EmailRounded,
  HomeRounded,
} from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import Chat from '../../../components/Chat/Chat';
import ChatCard from '@/components/Chat/ChatCard';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';
import InputChatInbox from '@/components/Chat/inputChatInbox';
import ChatCardInbox from '@/components/Chat/ChatCardInbox';
import { useOutsideAlerter } from '@/hocs';

const parseData = (data) => ({
  id: _get(data, 'order_id', ''),
  msg: _get(data, 'lastMessage.message', 'Click to Start Conversation.'),
  chat_id: _get(data, 'lastMessage.id', 0),
  update: _get(data, 'lastMessage.updated_at', new Date()),
  seen: _get(data, 'lastMessage.is_seen', 1),
  user: _get(data, 'withUser.name', ''),
  company: _get(data, 'withUser.company', ''),
  avatar: _get(data, 'withUser.image', 'A'),
  userId: _get(data, 'withUser.id', ''),
});

export default () => {
  // const [state, setState] = React.useState({
  //   DATA: [],
  //   loading: true,
  // });
  const [state, setState] = React.useState({
    headerData: {},
    user: {},
    DATA: [],
    otherPart: {},
    loading: true,
    popUp: false,
  });
  const history = useHistory();
  const listRef = React.useRef();
  const messagesEndRef = React.useRef(null);

  const params = useParams();
  const user = useSelector((state) => state.reducer.user);

  const [chatType, setChatType] = useState(null);
  const [orderStatus, setOrderStatus] = React.useState(null);
  const [orderDetail, setOrderDetail] = useState(null);
  const cls = styles();
  const orderParams = params.orderID;
  const isSmallDeviceM = useMediaQuery('(max-width: 1680px)');
  const isSmall = window.innerWidth < 1680;
  const [isSmallDevice, setIsSmallDevice] = useState(false);
  const [orderid, mode, user_id] = orderParams.split('-');

  // const type = useSelector((state) => state.reducer.ChatItem || null);
  const ChatData = useSelector((state) => state.reducer.Chat || {});
  const refresh = useSelector((state) => state.reducer.refresh || null);
  const wrapperRef = React.useRef(null);

  // const setIsSmallDeviceFunc = () => {
  //   const isSmallDeviceM = useMediaQuery('(max-width: 1680px)');
  //   setIsSmallDevice(isSmallDeviceM);
  // };

  useOutsideAlerter(wrapperRef, () => {
    const isSmall = window.innerWidth < 1680;
    if (isSmall !== isSmallDevice) {
      if (isSmall) {
        handleChatMobileClose();
      } else {
        // handleChatMobileOpen();
      }

      // setIsSmallDevice(isSmall);
    }

    // if (isSmallDevice === false) {
    //   handleChatMobileClose();
    // }
    // console.log('click for close');
  });

  const getOrderConversation = () => {
    getChat(orderid, mode, user_id)
      .then(({ ls, headerData, conversation, contactInfo, otherPart }) => {
        let ord = { ls, headerData, conversation, contactInfo, otherPart };
        setOrderDetail(ord);
        // if (chatId && messageId) {
        //   seenChat(chatId, messageId);
        // }
        // setPusher(pushClient(chn, handlePush));
        // setState((prev) => ({
        //   headerData,
        //   otherPart: prev.otherPart || otherPart,
        //   DATA: parseData(ls, user),
        //   loading: false,
        // }));
      })
      .catch((err) => {
        // notify(err, 'warning');
        setState({ DATA: [], loading: false });
      });
  };

  const handleChatMobileOpen = () => {
    const chatListContainer = document.getElementById('chatListContainer');
    const chatCloseBtn = document.getElementById('chatCloseBtn');
    if (chatListContainer && chatCloseBtn) {
      chatListContainer.style.display = 'flex';
      chatCloseBtn.style.display = 'flex';
    }
  };

  const handleChatMobileClose = () => {
    const chatListContainer = document.getElementById('chatListContainer');
    const chatCloseBtn = document.getElementById('chatCloseBtn');
    const chatOpenBtn = document.getElementById('chatOpenBtn');
    if (chatListContainer) {
      chatListContainer.style.display = 'none';
    }
    if (chatCloseBtn) {
      chatCloseBtn.style.display = 'none';
      chatOpenBtn.style.display = 'flex';
    }
  };

  React.useEffect(() => {
    if (orderDetail?.conversation.user_one === Number(user_id)) {
      setChatType('offer');
    } else if (orderDetail?.conversation.user_two === Number(user_id)) {
      setChatType('request');
    }
    setOrderStatus({
      status: orderDetail?.headerData.status_string,
      value: orderDetail?.headerData.staus,
    });
  }, [orderDetail]);

  const { id, userId } = ChatData;

  React.useEffect(() => {
    console.log('orderStatus', orderStatus);

    if (orderDetail?.conversation) {
      setTimeout(() => {
        if (
          (messagesEndRef.current && orderStatus.status === 'Pre-Approved') ||
          orderStatus.status === 'Approved'
        ) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [orderDetail?.conversation, orderStatus]);

  React.useEffect(() => {
    getOrderConversation();
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1680px)');
    const handleMediaQueryChange = (e) => {
      const isSmall = e.matches;
      if (isSmall !== isSmallDevice) {
        if (isSmall) {
          handleChatMobileClose();
          console.log('صفحه کوچک شد');
        } else {
          handleChatMobileOpen();
          console.log('صفحه بزرگ شد');
        }
        setIsSmallDevice(isSmall);
      }
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [isSmallDevice]);

  return (
    <div className={cls.chatContainer}>
      {/* {(!isSmallDevice || (isSmallDevice && userId)) && ( */}
      <div
        ref={listRef}
        id="chatMessagesContainer"
        className={cls.chatMessagesContainer}>
        <span className={cls.tableTopActionsChatIndex}>
          {/* <ReactSVG src={Icons.directInbox} className={cls.dicsounticon} /> */}
          {chatType === 'request' ? (
            <span>Street Turn Request</span>
          ) : (
            <span>Street Turn Offer</span>
          )}
          <span
            className={cls.backBtn}
            onClick={() => {
              history.push(`/inbox/${chatType}`);
              removeNoScroll();
            }}>
            <ReactSVG src={Icons.arrowBack} className={cls.dicsounticon} /> Back
          </span>
        </span>

        {
          <Messages
            setList={setState}
            user_id={user_id}
            orderDetail={orderDetail}
            setOrderDetail={setOrderDetail}
            chatType={chatType}
            orderStatus={orderStatus}
            getOrderConversation={getOrderConversation}
            // refreshChatList={() => RefreshData()}
          />
        }
        <div ref={messagesEndRef} />
      </div>
      {/* )} */}
      <div
        ref={wrapperRef}
        id="chatListContainer"
        className={
          !isSmallDevice || (isSmallDevice && userId)
            ? cls.chatListContainer
            : cls.chatListContainerMobile
        }>
        <div className={cls.tableTopActionsChat}>
          <span className={cls.tableTopActionsSpan}>
            <ReactSVG src={Icons.messageIconNew} className={cls.dicsounticon} />
            Messages
          </span>
          {/* <span>
            <Tooltip
              arrow
              title="Dear user, you are unable to chat due to security reasons.
                        After completing the second step, you will be able to chat.
                        If necessary, the support team will answer you"
              placement="bottum">
              <ReactSVG
                src={Icons.infoCircleBlack}
                className={cls.dicsounticon}
              />
            </Tooltip>
          </span> */}
        </div>

        {orderDetail ? (
          <>
            <span className={cls.chatList}>
              <>
                <ChatCardInbox
                  otherPart={orderDetail.otherPart}
                  ChatDataParse={orderDetail?.headerData}
                  userone={orderDetail?.conversation?.userone}
                  usertwo={orderDetail?.conversation?.usertwo}
                  orderStatus={orderStatus}
                  chatType={chatType}
                />
              </>
            </span>
          </>
        ) : (
          <div className={cls.loading}>
            <CircularProgress />
          </div>
        )}
        {!isSmallDevice || (isSmallDevice && userId) ? (
          ''
        ) : (
          <span
            id="chatCloseBtn"
            onClick={() => {
              handleChatMobileClose();
            }}
            className={cls.chatCloseBtn}>
            CHAT{' '}
            <ReactSVG
              src={Icons.messageIconNew}
              className={cls.ChatMessageIcon}
            />
          </span>
        )}
      </div>
      {!isSmallDevice || (isSmallDevice && userId) ? (
        ''
      ) : (
        <span
          id="chatOpenBtn"
          onClick={() => {
            handleChatMobileOpen();
          }}
          className={cls.chatOpenBtn}>
          CHAT{' '}
          <ReactSVG
            src={Icons.messageIconNew}
            className={cls.ChatMessageIcon}
          />
        </span>
      )}
    </div>
  );
};
