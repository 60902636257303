import _get from 'lodash/get';
import mm from 'moment';
import Pusher from 'pusher-js';
import { create } from 'apisauce';
import { ContainerIcons } from '@/assets';
import { getAutoComplete } from '@/actions';

export const SizeIcons = (size = '') => {
  let idx = ['20', '40-HC', '40', '45'].findIndex((i) => size.includes(i));
  return ContainerIcons[idx];
};

export const locationOpt = (value) => {
  if (value.trim()) return getAutoComplete(value).then((res) => res);
  return [];
};

export const devMode = process.env.NODE_ENV === 'production';

export const LoggedIn = () => Boolean(localStorage.token);

export const Reject = (data) =>
  Promise.reject(_get(data, 'message', 'something went wrong'));

export const fTimeDate = (date) => mm(date).format('HH:mm MM-DD-YYYY');
export const fTime = (date) => mm(date).format('HH:mm');

export const fDate = (date) => mm(date).format('MM-DD-YYYY');
export const fDate2 = (date) => mm(date).format('MM-DD-YYYY');
export const timeStamp = (date) => mm(date, 'MM/DD/YYYY').unix();

export const genSize = ({ size = '', name = '', model = '' }) =>
  `${size}-${name}-${model}`;

export const pushClient = (channel, fun, ev = 'talk-send-message') => {
  // Enable pusher logging - don't include this in production
  Pusher.logToConsole = false;
  const pusher = new Pusher('355d8d3dba1018301b6e', {
    cluster: 'us2',
    encrypted: true,
  });

  let conversationChannel = pusher.subscribe(channel);
  conversationChannel.unbind(ev);
  conversationChannel.bind(ev, fun);
  return pusher;
};

export const subscribeTokenToTopic = (token, topic) => {
  const api = create({
    baseURL: `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`,
    headers: {
      Authorization:
        'key=AAAAsbHRjY0:APA91bGVmAvXJkO-TMsR7rXg-wBS1Y3KLFFnZ2HuQg5Jisv1fNgVyCcs1ae6b4FcqRliAkTcdUqILPKg4DfmR6hNW3jGVcb3weCuFXT3ri5D-S64iqbA3zuqm9t9yBbYdxaMJf45w-lr',
    },
  });
  api.post().then(({ status, data, ok }) => {
    if (!ok) {
      console.error('err => ', data);
      console.error(`Error subscribing to topic: ${status} - ${data.text}`);
    }
  });
};

export const toForm = (data) => {
  let formData = new FormData();
  for (let key in data) {
    if (data[key] != null) formData.append(key, data[key]);
  }

  return formData;
};

export const noScroll = () => (document.body.style.overflow = 'hidden');
export const removeNoScroll = () => (document.body.style.overflow = null);

export { _get };
