import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginTop: 7.5,
  },
  listContainer: {
    width: '100%',
    overflow: 'auto',
    padding: '0 15px',
    boxSizing: 'border-box',
    marginBottom: '15px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    // [BP.up('lg')]: {
    //   flexDirection: 'row-reverse',
    //   marginRight: '-9vw',
    //   alignItems: 'flex-start',
    // },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#43424b',
  },
  filter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1,
    color: '#43424b',
  },
  but: {
    height: 45.3,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Bold',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 1.2,
    color: '#303030',
    padding: '6px 50px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '12px',
    marginLeft: '-7px',
    '&:hover': {
      background: '#b48100',
    },
  },
  icon: {
    fill: '#303030',
  },

  filterIcon: {
    height: 25,
    width: 25,
    objectFit: 'contain',
    fill: '#9f9f9f',
    marginLeft: 20,
  },
  headerBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '40px 40px 20px 40px',
  },
  text: {
    color: '#000000',
    fontSize: '20px',
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    padding: '20px 20px',
    textAlign: 'center',
    margin: '15px',
    width: '700px',
    marginRight: 'auto',
    marginLeft: 'auto',
    '& p': {
      margin: '10px 0',
    },
    '@media (max-width:900px)': {
      width: '70%',
    },
  },
  notifyContainer: {
    marginTop: 15,
    display: 'flex',
    borderRadius: 5,
    animation: 'leave 3s ease-in both',
    animationDelay: '1s',
  },
  notifList: {
    background: '#007fff',
    margin: '0 5px 0 3px',
    display: 'flex',
    borderRadius: '0 5px 5px 0',
    '&:hover': {
      background: '#007fff',
      color: '#ffffff',
    },
  },
  notify: {
    width: '150px',
    background: '#007fff',
    borderRadius: '5px 0 0 5px',
    border: 'none',
    color: '#ffffff',
    '&:hover': {
      background: '#007fff',
      color: '#ffffff',
    },
  },
  card1: {
    flex: 2,
    height: 210,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    padding: '35px 15px',
    margin: '0 10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#303030',
    whiteSpace: 'nowrap',
    '& h1': {
      fontSize: 30,
      color: '#f8b100',
      fontWeight: 'bold',
      lineHeight: 1.23,
      margin: 0,
    },
    '& h2': {
      fontSize: 20,
      color: '#f8b100',
      fontWeight: 'bold',
      lineHeight: 1.25,
      margin: 0,
    },
    '& h3': {
      fontSize: 16,
      color: '#fff',
      fontWeight: 'bold',
      lineHeight: 1.23,
      margin: 0,
    },
  },

  col2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& a': { textDecoration: 'none' },
    '& h3': {
      whiteSpace: 'break-spaces',
      textAlign: 'center',
      lineHeight: '30px',
    },
  },
}));
