import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import _get from 'lodash/get';
import clsx from 'clsx';
import * as Y from 'yup';
import styles from './styles';
import EditingChassisList from './EditingChassisList';
import {
  enableContainer,
  enableFlipAvailable,
  enableShareContainer,
} from '@/actions';
import TimeInput from '@/components/TimeInput';
import { useNotify } from 'react-admin';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import Notification from './Notification';

let updateSch = Y.object().shape({
  name: Y.string().trim().nullable().required('Name is required'),
  last_name: Y.string().trim().nullable().required('Last name is required'),
  company: Y.string().trim().nullable().required('Company is required'),
  phone: Y.string()
    .nullable()
    .min(5, 'Phone number must be at least 5 characters')
    .max(13, 'Phone number must be at most 13 characters')
    .trim()
    .required('Phone number is required'),
  zip: Y.string()
    .nullable()
    .min(5, 'ZipCode must be at least 5 characters')
    .max(10, 'ZipCode must be at most 10 characters')
    .nullable(),
  address: Y.string().trim().nullable().required('Address is required'),
  // bio: Y.string().trim().required('Bio is required'),
});

let passwordSch = Y.object().shape({
  old_password: Y.string()
    .min(8, 'Old password must be at least 8 characters')
    .nullable(),
  new_password: Y.string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters'),
});

export default ({ data, Update, ChangePass, chassis, setChassis }) => {
  const cls = styles();
  const [errs, setErrs] = useState([]);
  const [loadingCheckBox, setLoadingCheckBox] = useState(null);
  const [notification, setnotification] = useState({
    DATA: {
      whatsapp: false,
      sms: false,
      email: false,
    },
    loading: false,
    popUp: false,
  });

  const notify = useNotify();

  const [form, setFormData] = useState({
    name: '',
    last_name: '',
    company: '',
    phone: '',
    address: '',
    zip: '',
    bio: '',
    email: '',
    yard_start: '',
    yard_end: '',
    old_password: '',
    new_password: '',
    yard_start: null,
    yard_end: null,
    flip_start: null,
    flip_end: null,
  });

  const [editingChassis, setEditingChassis] = useState([]);
  const [openClock, setOpenClock] = React.useState(null); // Manage which clock's popup is open
  const [times, setTimes] = React.useState([]);

  const handleToggleContanerNames = (value) => {
    setLoadingCheckBox('container');
    let formData = {};
    if (value) {
      formData.enable = true;
    } else {
      formData.disable = true;
    }
    enableContainer(formData).then((res) => {
      if (res.user) {
        // if (value === false) {
        //   setFormData((prev) => {
        //     return {
        //       ...prev,
        //       own_chases_enabled: false,
        //       enable_container_name: false,
        //     };
        //   });
        // } else {
        setForm(value, 'enable_container_name');
        // }
        notify('container successfully updated');
        setLoadingCheckBox(null);
      } else {
        notify('something went wrong', 'warning');
        setLoadingCheckBox(null);
      }
    });
  };

  const handleToggleShare = (value) => {
    setLoadingCheckBox('chas');
    let formData = {};
    if (value) {
      formData.enable = true;
    } else {
      formData.disable = true;
    }
    enableShareContainer(formData).then((res) => {
      if (res.user) {
        notify('container successfully updated');
        setForm(value, 'own_chases_enabled');
        setLoadingCheckBox(null);
      } else {
        notify('something went wrong', 'warning');
        setLoadingCheckBox(null);
      }
    });
  };

  const handleToggleFlipAvailable = (value) => {
    setLoadingCheckBox('flip');
    let formData = {};
    if (value) {
      formData.enable = true;
    } else {
      formData.disable = true;
    }
    enableFlipAvailable(formData).then((res) => {
      if (res) {
        setLoadingCheckBox(null);
        notify('flip successfully updated');
        setForm(value, 'flip_available');
      } else {
        setLoadingCheckBox(null);
        notify('something went wrong', 'warning');
      }
    });
  };

  console.log('data', data);

  React.useEffect(() => {
    setFormData({
      name: _get(data, 'name', ''),
      last_name: _get(data, 'last_name', ''),
      company: _get(data, 'company', ''),
      phone: _get(data, 'phone', ''),
      address: _get(data, 'address', ''),
      zip: _get(data, 'zip', ''),
      bio: _get(data, 'bio', ''),
      email: _get(data, 'email', ''),
      yard_start:
        data.yard_start && !data.yard_start.includes('NaN')
          ? moment(data.yard_start, 'h:mm a')
          : null,
      yard_end:
        data.yard_end && !data.yard_end.includes('NaN')
          ? moment(data.yard_end, 'h:mm a')
          : null,
      flip_start:
        data.flip_start && !data.flip_start.includes('NaN')
          ? moment(data.flip_start, 'h:mm a')
          : null,
      flip_end:
        data.flip_end && !data.flip_end.includes('NaN')
          ? moment(data.flip_end, 'h:mm a')
          : null,
      yard_location: _get(data, 'yard_location', ''),
      yard_zip: _get(data, 'yard_zip', ''),
      yard_trucks: _get(data, 'yard_trucks', ''),
      yard_scac: _get(data, 'yard_scac', ''),
      yard_dot: _get(data, 'yard_dot', ''),
      enable_container_name: _get(data, 'enable_container_name', ''),
      own_chases_enabled: _get(data, 'own_chases_enabled', ''),
      flip_available: _get(data, 'flip_available', ''),
    });
  }, [data]);

  useEffect(() => {
    setEditingChassis(chassis.DATA);
  }, [chassis.popUp]);

  const checkError = (item) => {
    if (errs.length) {
      let a = errs.find((i) => i.path === item);
      return a ? a.message : false;
    } else return false;
  };

  const setForm = (e, it) =>
    setFormData({ ...form, [it]: e.target ? e.target.value : e });
  const ValidateUpdate = (form) => {
    let apiData = {};
    for (let key in form) {
      if (form[key] === '') {
        apiData[key] = null;
      } else {
        apiData[key] = form[key];
      }
    }
    Update(apiData);
  };
  // updateSch
  //   .validate(form, { abortEarly: false })
  //   .then(Update(form))
  //   .catch(({ inner }) => setErrs(inner));

  const ValidatePass = (form) =>
    passwordSch
      .validate(form, { abortEarly: false })
      .then(ChangePass)
      .catch(({ inner }) => setErrs(inner));

  const format = 'h:mm';

  // function onChange(value) {
  //   console.log(value && value.format(format));
  //

  useEffect(() => {
    setnotification((prev) => {
      return {
        ...prev,
        DATA: data.notification,
      };
    });
  }, [data]);

  // useEffect(() => {
  //   if (!notification.popUp) {
  //     setnotification((prev) => {
  //       return {
  //         ...prev,
  //         DATA: data.notification,
  //       };
  //     });
  //   }
  // }, [notification.popUp]);

  return (
    <div className={cls.container}>
      {notification.popUp && (
        <Notification
          Close={() => {
            setnotification((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            });
          }}
          notification={notification}
          setnotification={setnotification}
        />
      )}
      {chassis.popUp && (
        <EditingChassisList
          Close={() => {
            setChassis((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            });
          }}
          DATA={editingChassis}
          setDATA={setEditingChassis}
          setChassis={setChassis}
        />
      )}
      <div className={cls.card}>
        <Grid container spacing={3} className={cls.row}>
          <Grid item xs={12} md={6} className={cls.rowMl}>
            <span className={cls.label}>Name</span>
            <TextField
              autoComplete="new-password"
              className={cls.input}
              value={form.name}
              onChange={(e) => setForm(e, 'name')}
              variant="outlined"
              error={checkError('name')}
              helperText={checkError('name')}
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>Last Name</span>
            <TextField
              className={cls.input}
              value={form.last_name}
              onChange={(e) => setForm(e, 'last_name')}
              variant="outlined"
              error={checkError('last_name')}
              helperText={checkError('last_name')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={cls.row}>
          <Grid item xs={12} md={6} className={cls.rowMl}>
            <span className={cls.label}>Company</span>
            <TextField
              className={cls.input}
              value={form.company}
              onChange={(e) => setForm(e, 'company')}
              variant="outlined"
              error={checkError('company')}
              helperText={checkError('company')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>Phone number</span>
            <TextField
              className={cls.input}
              value={form.phone}
              onChange={(e) => setForm(e, 'phone')}
              type="tel"
              variant="outlined"
              error={checkError('phone')}
              helperText={checkError('phone')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={cls.row}>
          <Grid item xs={12} md={6} className={cls.rowMl}>
            <span className={cls.label}>Office Address</span>
            <TextField
              className={cls.input}
              value={form.address}
              onChange={(e) => setForm(e, 'address')}
              variant="outlined"
              error={checkError('address')}
              helperText={checkError('address')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>Office Zip Code</span>
            <TextField
              autoComplete="new-password"
              className={cls.input}
              value={form.zip}
              onChange={(e) => setForm(e, 'zip')}
              variant="outlined"
              error={checkError('zip')}
              helperText={checkError('zip')}
              type="text"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={cls.row}>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>Yard Address</span>
            <TextField
              type="text"
              className={cls.input}
              value={form.yard_location}
              onChange={(e) => setForm(e, 'yard_location')}
              variant="outlined"
              error={checkError('yard_location')}
              helperText={checkError('yard_location')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>Yard Zip Code</span>
            <TextField
              type="text"
              className={cls.input}
              value={form.yard_zip}
              onChange={(e) => setForm(e, 'yard_zip')}
              variant="outlined"
              error={checkError('yard_zip')}
              helperText={checkError('yard_zip')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={cls.row}>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>Yard Hours of Operation</span>
            {/* <TimeInput
              // clearable
              // // ampm={false}
              // label={selectedDate}
              // value={selectedDate}
              className={cls.date}
              value={form.yard_start}
              onChange={(time) => {
                setForm(time, 'yard_start');
              }}
            />
            <TimeInput
              className={cls.date}
              // value={form.yard_end}
              onChange={(time) => {
                setForm(time, 'yard_end');
              }}
            /> */}
            <Grid container spacing={1}>
              {/* {console.log('time', form.yard_start)} */}
              <Grid item xs={6}>
                <TimePicker
                  className={cls.date}
                  value={form.yard_start || ''}
                  onChange={(time) => {
                    setForm(time, 'yard_start');
                  }}
                  showSecond={false}
                  format="hh:mm A"
                  allowEmpty
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  className={cls.date}
                  value={form.yard_end}
                  onChange={(time) => {
                    setForm(time, 'yard_end');
                  }}
                  format="hh:mm A"
                  showSecond={false}
                  allowEmpty
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>Number of Trucks</span>
            <TextField
              type="number"
              className={cls.input}
              value={form.yard_trucks}
              onChange={(e) => setForm(e, 'yard_trucks')}
              variant="outlined"
              error={checkError('yard_trucks')}
              helperText={checkError('yard_trucks')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={cls.row}>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>SCAC</span>
            <TextField
              type="text"
              className={cls.input}
              value={form.yard_scac}
              onChange={(e) => setForm(e, 'yard_scac')}
              variant="outlined"
              error={checkError('yard_scac')}
              helperText={checkError('yard_scac')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>DOT</span>
            <TextField
              type="text"
              className={cls.input}
              value={form.yard_dot}
              onChange={(e) => setForm(e, 'yard_dot')}
              variant="outlined"
              error={checkError('yard_dot')}
              helperText={checkError('yard_dot')}
            />
          </Grid>
        </Grid>
        <Grid
          style={{ marginBottom: 20 }}
          container
          spacing={1}
          className={cls.row}>
          <Grid item xs={12} md={6} className={cls.row}>
            <FormControlLabel
              className={`${
                form.enable_container_name
                  ? cls.enabledContainer
                  : cls.disabledContainer
              }`}
              control={
                <Checkbox
                  checked={form.enable_container_name ? true : false}
                  className={`${cls.checkbox}`}
                  onChange={(e) => handleToggleContanerNames(e.target.checked)}
                />
              }
              label="Container Numbers"
            />
            {loadingCheckBox === 'container' && (
              <CircularProgress className={cls.loader} />
            )}
          </Grid>
          <Grid item xs={12} md={6} className={cls.row}>
            <FormControlLabel
              className={`${
                form.flip_available
                  ? cls.enabledContainer
                  : cls.disabledContainer
              }`}
              control={
                <Checkbox
                  checked={form.flip_available ? true : false}
                  className={`${cls.checkbox}`}
                  onChange={(e) => handleToggleFlipAvailable(e.target.checked)}
                />
              }
              label="Flip Service Available"
            />
            {/*loadingCheckBox === 'flip' && (
              <CircularProgress className={cls.loader} />
            )*/}
            {form.flip_available ? (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TimePicker
                    className={cls.date}
                    value={form.flip_start || ''}
                    onChange={(time) => {
                      setForm(time, 'flip_start');
                    }}
                    showSecond={false}
                    format="hh:mm A"
                    allowEmpty
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    className={cls.date}
                    value={form.flip_end}
                    onChange={(time) => {
                      setForm(time, 'flip_end');
                    }}
                    format="hh:mm A"
                    showSecond={false}
                    allowEmpty
                  />
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {/* <Grid item xs={12} md={6} className={cls.row}>
          <FormControlLabel
            className={`${
              form.own_chases_enabled
                ? cls.enabledContainer
                : cls.disabledContainer
            }`}
            control={
              <Checkbox
                // disabled={form.enable_container_name ? false : true}
                checked={form.own_chases_enabled == 1 ? true : false}
                className={`${cls.checkbox}`}
                onChange={(e) => handleToggleShare(e.target.checked)}
              />
            }
            label="Share Containers on Own Chassis"
          />
          {loadingCheckBox === 'chas' && (
            <CircularProgress className={cls.loader} />
          )}
        </Grid> */}
        <Grid container spacing={3} className={cls.row}>
          <Button
            onClick={() =>
              setChassis((prev) => {
                return {
                  ...prev,
                  popUp: true,
                };
              })
            }
            className={cls.chasissBtn}>
            Own Chassis List
          </Button>
        </Grid>
        <Grid container spacing={3} className={cls.row}>
          <Button
            style={{ marginTop: 0 }}
            onClick={() =>
              setnotification((prev) => {
                return {
                  ...prev,
                  popUp: true,
                };
              })
            }
            className={cls.chasissBtn}>
            Notification
          </Button>
        </Grid>

        {/* <Grid container spacing={3} className={cls.row2}>
          <Grid item xs={12}>
            <span className={cls.label}>Bio</span>
            <div className={cls.areaCont}>
              <textarea
                className={clsx(cls.input, cls.textarea)}
                value={form.bio}
                onChange={(e) => setForm(e, 'bio')}
              />
            </div>
          </Grid>
        </Grid> */}

        <Grid container spacing={3} className={cls.row}>
          <Grid item xs={12} md={6} className={cls.rowMl}>
            <span className={cls.label}>Old Password</span>
            <TextField
              autoComplete="new-password"
              type="password"
              className={cls.input}
              value={form.old_password}
              onChange={(e) => setForm(e, 'old_password')}
              variant="outlined"
              error={checkError('old_password')}
              helperText={checkError('old_password')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={cls.row}>
            <span className={cls.label}>New Password</span>
            <TextField
              type="password"
              className={cls.input}
              value={form.new_password}
              onChange={(e) => setForm(e, 'new_password')}
              variant="outlined"
              error={checkError('new_password')}
              helperText={checkError('new_password')}
            />
          </Grid>
        </Grid>

        <div className={cls.password}>
          <div className={cls.rowMl}>
            <span className={cls.label2}>
              If you have not set old password, leave it blank
            </span>
          </div>
          <div item xs={6} className={cls.row}>
            <Button
              className={clsx(cls.but, cls.red)}
              onClick={() => ValidatePass(form)}>
              Change password
            </Button>
          </div>
        </div>

        <div className={cls.butCont}>
          <Button className={cls.but}>Edit</Button>
          <Button
            className={clsx(cls.but, cls.green)}
            onClick={() => ValidateUpdate(form)}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
