import {
  getAdminNewChat,
  orderConfirm,
  RejectOrder,
  RejectOrderExport,
  RejectRequest,
  SaveData,
  seenChat,
  sendApproveData,
  sendPreApproveData,
} from '@/actions';
import { useHistory } from 'react-router-dom';
import AdminChatFooter from '@/components/Chat/AdminChatFooter';
import AdminChatHeader from '@/components/Chat/AdminChatHeader';
import React, { useEffect } from 'react';
import styles from './styles';
import { useSelector } from 'react-redux';
import {
  pushClient,
  _get,
  fTimeDate,
  locationOpt,
  noScroll,
} from '@/actions/Helper';
import Chat from '../../../components/Chat/Chat';
import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Input from '../../../components/Chat/Input';
import { Avatar, Grid, TextField } from '@mui/material';
import Select from 'react-select';
import Containers from './Containers';
import TimePicker from 'rc-time-picker';
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { values } from 'lodash';
import ImageUpload from './ImageUpload';
import { Loading, useNotify } from 'react-admin';
import { Icons } from '@/assets';
import { ReactSVG } from 'react-svg';
import TimePickerFromTo from '@/components/Chat/TimePickerFromTo';
import ErrorPopup from './ErrorPopup';
import { usePusher } from '../../../App';
import CountdownTimer from '@/components/Global/CountdownTimer';

const parseData = (data, user) =>
  data.map((i) => ({
    message: i.message,
    time: i.humans_time,
    me: i.user_id === user.id,
    name: _get(i, 'sender.name', ''),
    avatar: _get(i, 'sender.image', ''),
  }));

export default ({
  chatType,
  orderStatus,
  orderDetail,
  setOrderDetail,
  user_id,
  getOrderConversation,
}) => {
  console.log('orderDetailAsli', orderDetail);

  // const [orderDetail, setOrderDetail] = React.useState([]);

  // React.useEffect(() => {
  //   setOrderDetail(orderDetailAsli);
  // }, [orderDetailAsli]);

  const [state, setState] = React.useState({
    headerData: {},
    user: {},
    DATA: [],
    otherPart: {},
    loading: true,
    popUp: false,
  });
  const [form, setFormData] = React.useState([
    {
      container_id: null,
      pickup_way: null,
      pickup_location: null,
      pickup_date_start: null,
      pickup_date_end: null,
      container_conditions: '',
    },
  ]);

  const [formApprove, setFormApproveData] = React.useState([
    {
      container_id: null,
      approve_status: null,
    },
  ]);
  const { channels } = usePusher();

  const cls = styles();
  // const listRef = React.useRef();
  const notify = useNotify();
  const history = useHistory();
  const [openRowId, setOpenRowId] = React.useState(null);
  const [loadingCircle, setLoadingCircle] = React.useState(false);
  const user = useSelector((state) => state.reducer.user);
  const [pusher, setPusher] = React.useState(null);
  const [imageUploads, setImageUploads] = React.useState([]);
  const [containersItemsState, setContainersItemsState] = React.useState([]);
  const [requestedCount, setRequestedCount] = React.useState();
  const [accepted_count, setAccepted_count] = React.useState();
  const [count_all, setcount_all] = React.useState();
  const [contactInfo, setContactInfo] = React.useState();
  const [fromTime, setFromTime] = React.useState({ hour: 10, amPm: 'AM' });
  const [toTime, setToTime] = React.useState({ hour: 11, amPm: 'AM' });
  const [times, setTimes] = React.useState([]);
  const [selectedContainerItem, setSelectedContainerItem] = React.useState([]);
  const [preApprovedContainersList, setPreApprovedContainersList] =
    React.useState([]);
  const [approvedContainersList, setapprovedContainersList] = React.useState(
    []
  );
  const [preApproverowIDs, setPreApproverowIDs] = React.useState([]);
  const [containersPopup, setContainersPopup] = React.useState({
    DATA: null,
    loading: true,
    popUp: false,
  });
  const [imageUploadPopup, setImageUploadPopup] = React.useState({
    DATA: null,
    loading: true,
    popUp: false,
  });
  const [errorPopup, setErrorPopup] = React.useState({
    DATA: null,
    loading: true,
    popUp: false,
  });
  const pickupDropoffOpt = [
    { label: 'Pickup', value: 'pickup' },
    { label: 'Dropoff', value: 'dropoff' },
  ];
  const conditionOpts = [
    { label: 'Clean', value: 'clean' },
    { label: 'Damaged', value: 'Damaged' },
    { label: 'Repair Needed', value: 'Repair Needed' },
    { label: 'Not Checked', value: 'Not Checked' },
  ];

  const [value, setValue] = React.useState(requestedCount);

  const [selectedPickupWay, setSelectedPickupWay] = React.useState();
  useEffect(() => {
    setSelectedPickupWay(
      orderDetail?.headerData?.request_data_raw?.movement === 'dropoff'
        ? pickupDropoffOpt[1]
        : pickupDropoffOpt[0]
    );
    // noScroll();
  }, [orderDetail, containersItemsState]);

  const increment = () => {
    if (value < requestedCount) {
      setValue(value + 1);
      setcount_all(value + 1);
    }
  };

  const decrement = () => {
    if (value > 1) {
      setValue(value - 1);
      setcount_all(value - 1);
    }
  };

  useEffect(() => {
    if (form.length > count_all) {
      form.pop();
      preApproverowIDs.pop();
    }
  }, [count_all]);

  useEffect(() => {
    if (form.length > count_all) {
      setcount_all(form.length);
      setValue(form.length);
    }
  }, [form]);

  React.useEffect(() => {
    setValue(requestedCount);
  }, [requestedCount]);

  const updateFormData = (index, key, value) => {
    setFormData((prev) => {
      const updatedData = [...prev];

      if (!updatedData[index]) {
        updatedData[index] = {};
      }

      const keys = key.split('.');
      let current = updatedData[index];

      keys.forEach((k, i) => {
        if (i === keys.length - 1) {
          current[k] = value;
        } else {
          if (!current[k]) {
            current[k] = {};
          }
          current = current[k];
        }
      });

      if (key === 'pickup_way' && value === 'dropoff') {
        updatedData[index].pickup_location =
          orderDetail?.headerData?.request_data_raw?.location;
      } else if (key === 'pickup_way' && value === 'pickup') {
        updatedData[index].pickup_location = null;
      }

      return updatedData;
    });
  };

  const updateFormApproveData = (index, key, value) => {
    setFormApproveData((prev) => {
      const updatedData = [...prev];

      if (!updatedData[index]) {
        updatedData[index] = {};
      }

      const keys = key.split('.');
      let current = updatedData[index];

      keys.forEach((k, i) => {
        if (i === keys.length - 1) {
          current[k] = value;
        } else {
          if (!current[k]) {
            current[k] = {};
          }
          current = current[k];
        }
      });

      return updatedData;
    });
  };

  const updateFormApproveDates = (index, key, value) => {
    const currentDate = new Date();

    const fromDateTime = new Date(currentDate);
    fromDateTime.setHours(7, 0, 0, 0);

    const toDateTime = new Date(currentDate);
    toDateTime.setHours(16, 0, 0, 0);

    updateFormData(index, 'pickup_date_start', fromDateTime);
    updateFormData(index, 'pickup_date_end', toDateTime);
    setSelectedPickupWay(form.pickup_way);
    updateFormData(index, 'pickup_way', selectedPickupWay?.value);
  };

  const RejectEmpty = (id) => {
    setLoadingCircle(true);
    RejectOrder(id)
      .then(({ data, msg }) => {
        if (chatType === 'request') {
          history.push(`/inbox/request`);
        } else {
          history.push(`/inbox/offer`);
        }
        // let DATA = ParseOrder(data);
        // setData(DATA);
        // setFilter({ open: false, DATA });
        notify(msg);
      })
      .catch((err) => notify(err, 'warning'));
    // }
  };

  function extractTime(input) {
    if (!input) {
      return null;
      // throw new Error('Invalid input');
    }

    const date = typeof input === 'string' ? new Date(input) : input;

    if (isNaN(date)) {
      throw new Error('Invalid date format');
    }

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  React.useEffect(() => {
    const messages = orderDetail?.conversation.messages || [];

    messages.forEach((message) => {
      if (message.is_seen === 0) {
        seenChat(orderDetail?.headerData.id, message.id);
      }
    });

    for (const msg of orderDetail?.conversation.messages || []) {
      if (msg.message_type === '3' && msg.message_type === '2') {
      }
    }
    for (const msg of orderDetail?.conversation.messages || []) {
      if (msg.message_type === '1') {
        setPreApprovedContainersList(
          orderDetail?.headerData.pre_approved_request_data
        );
        setapprovedContainersList(
          orderDetail?.headerData.approved_request_data
        );
      }
    }
    setContactInfo(orderDetail?.contactInfo);
  }, [orderDetail]);

  const RejectExport = (id) => {
    RejectOrderExport(id)
      .then(({ data, msg }) => {
        // let DATA = ParseOrder(data);
        // setData(DATA);
        // setFilter({ open: false, DATA });
        notify(msg);
      })
      .catch((err) => notify(err, 'warning'));
  };
  React.useEffect(() => {
    setRequestedCount(orderDetail?.headerData.requested_count);
    setAccepted_count(orderDetail?.headerData.accepted_count);
    setcount_all(
      orderDetail?.headerData.accepted_count
        ? orderDetail?.headerData.accepted_count
        : orderDetail?.headerData.requested_count
    );
  }, [orderDetail]);

  React.useEffect(() => {
    setContainersItemsState((prevState) => {
      return Array.from({ length: count_all }, (_, index) => {
        const prevItem = prevState?.[index] || {};
        return {
          ...prevItem,
          id: index,
          orderStatus: orderDetail?.headerData?.staus,
          size:
            orderDetail?.headerData?.cabin?.specifications?.size +
            '-' +
            orderDetail?.headerData?.cabin?.specifications?.name +
            '-' +
            orderDetail?.headerData?.cabin?.specifications?.model,
          ssl: orderDetail?.headerData?.cabin?.company?.name,
          pickup_way: orderDetail?.headerData?.request_data_raw?.movement,
          location: orderDetail?.headerData?.inventory?.address?.city,
          inventory: orderDetail?.headerData?.inventory,
          preApprovedContainersList: preApprovedContainersList,
          approvedContainersList: approvedContainersList,
          approvedcontainer: approvedContainersList[index],
          preapprovedcontainer: preApprovedContainersList[index],
        };
      });
    });
  }, [orderDetail, count_all, preApprovedContainersList]);

  console.log('containersItemsState', containersItemsState);

  const updateSelectedContainerImages = (index, apiData) => {
    setContainersItemsState((prevState) => {
      return prevState.map((item, idx) => {
        if (index === idx) {
          return {
            ...item,
            inventory: {
              ...item.inventory,
              containers: item.inventory.containers.map((container) => {
                if (container.id === Number(apiData?.data?.container_id)) {
                  return {
                    ...container,
                    images: [
                      ...(container.images || []),
                      ...apiData.data.images,
                    ],
                  };
                }
                return container;
              }),
            },
            selectedContainer:
              item.selectedContainer?.id === Number(apiData?.data?.container_id)
                ? {
                    ...item.selectedContainer,
                    images: [
                      ...(item.selectedContainer.images || []),
                      ...apiData.data.images,
                    ],
                  }
                : item.selectedContainer,
          };
        }
        return item;
      });
    });
  };

  const updateContainerApproval = (index, apiData) => {
    setContainersItemsState((prevState) =>
      prevState.map((item, idx) => {
        if (index === idx) {
          return {
            ...item,
            preapprovedcontainer: {
              ...item.preapprovedcontainer,
              container:
                item.preapprovedcontainer?.container?.id ===
                Number(apiData?.data?.container_id)
                  ? {
                      ...item.preapprovedcontainer.container,
                      approvals: [
                        ...(item.preapprovedcontainer.container?.approvals ||
                          []),
                        ...apiData.data.approvals,
                      ],
                    }
                  : item.preapprovedcontainer.container,
            },
          };
        }
        return item;
      })
    );
  };

  const addItemToOldList = (newItems, oldList) => {
    setFormData((prevFormData) => {
      const newPreApproverowIDs = newItems?.map(
        (item) => item?.preApproverowID
      );
      return prevFormData?.filter((data) =>
        newPreApproverowIDs?.includes(data?.id)
      );
    });
    if (!newItems || newItems.length === 0) {
      return oldList.map((item) => ({
        ...item,
        selectedContainerID: null,
        selectedContainer: null,
      }));
    }
    const updatedList = [...oldList];
    newItems.forEach((newItem, index) => {
      updateFormApproveDates(index);
      updateFormData(index, 'container_id', newItem?.preApproverowID);
      updateFormData(
        index,
        'pickup_way',
        orderDetail?.headerData?.request_data_raw?.movement
      );

      if (index < updatedList.length) {
        updatedList[index] = {
          ...updatedList[index],
          id: newItem?.preApproverowID,
          selectedContainerID: newItem?.item?.id || null,
          selectedContainer: newItem?.item || null,
        };
      } else {
        updatedList[index] = {
          id: newItem?.preApproverowID,
          selectedContainerID: newItem?.item?.id || null,
          selectedContainer: newItem?.item || null,
        };
      }
    });

    for (let i = newItems.length; i < updatedList.length; i++) {
      updatedList[i] = {
        ...updatedList[i],
        selectedContainerID: null,
        selectedContainer: null,
      };
    }
    return updatedList;
  };

  const extractPreApproverowIDs = (newItems) => {
    return newItems.map((item) => item.preApproverowID);
  };

  const updateContainersList = () => {
    const updatedList = addItemToOldList(
      selectedContainerItem || [],
      containersItemsState
    );
    setContainersItemsState(updatedList);

    const preApproverowIDs = extractPreApproverowIDs(
      selectedContainerItem || []
    );
    setPreApproverowIDs(preApproverowIDs);
  };

  React.useEffect(() => {
    updateContainersList();
  }, [selectedContainerItem]);

  const handleContainersSelect = (
    containers,
    preApprovedContainers,
    preApprovedIndexContainerId
  ) => {
    setContainersPopup((prev) => {
      return {
        ...prev,
        popUp: true,
        DATA: containers,
        requestedCount: requestedCount,
        preApprovedContainers: preApprovedContainers,
        preApprovedIndexContainerId: preApprovedIndexContainerId,
      };
    });
  };
  const handleImageUpload = (containers, hasImage, containerID, index) => {
    setImageUploadPopup((prev) => {
      return {
        ...prev,
        popUp: true,
        DATA: containers,
        hasImage: hasImage,
        orderStatus: orderStatus,
        chatType: chatType,
        containerID,
        orderID: orderDetail?.headerData.id,
        index: index,
      };
    });
  };
  const handleErrorPopup = (containers, mode) => {
    setErrorPopup((prev) => {
      return {
        ...prev,
        popUp: true,
        DATA: containers,
        mode: mode,
        orderStatus: orderStatus,
        chatType: chatType,
        orderID: orderDetail?.headerData.id,
      };
    });
  };
  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  const validateFormData = (form) => {
    let errors = {};
    console.log('formValidate', form);

    for (let i = 0; i < form.length; i++) {
      const data = form[i];

      for (const key in data) {
        if (key === 'container_conditions') continue;
        const value = data[key];

        if (value === null || value === undefined || value === '') {
          const fieldName =
            key === 'pickup_way'
              ? 'Pickup Way'
              : key === 'pickup_location'
              ? 'Pickup Location'
              : key;

          if (!errors[fieldName]) {
            errors[fieldName] = [];
          }
          errors[fieldName].push(i + 1);

          continue;
        }

        if (typeof value === 'object') {
          for (const nestedKey in value) {
            const nestedValue = value[nestedKey];
            if (
              nestedValue === null ||
              nestedValue === undefined ||
              nestedValue === ''
            ) {
              if (!errors[nestedKey]) {
                errors[nestedKey] = [];
              }
              errors[nestedKey].push(i + 1);
            }
          }
        }
      }
    }

    let errorMessages = [];
    for (const field in errors) {
      const rows = errors[field].join(' and ');
      errorMessages.push(
        `Fill out the '${field}' for row(s) ${rows} to proceed.`
      );
    }
    setLoadingCircle(false);

    return errorMessages.length > 0 ? errorMessages.join('\n') : null;
  };

  const formatDateTime = (date) => {
    const d = new Date(date);

    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const formattedToTime = formatDateTime(toTime);
  const formattedFromTime = formatDateTime(fromTime);

  const formatPickupDate = (pickupDate) => {
    if (!pickupDate || typeof pickupDate !== 'object') return null;

    const { start_time, end_time } = pickupDate;
    // return `${start_time || ''} - ${end_time || ''}`;
    return formatDateTime(start_time);
  };
  const handlePreApproveData = () => {
    setLoadingCircle(true);
    const validationError = validateFormData(form);
    if (validationError) {
      notify(validationError, 'warning');
      return;
    }

    const formattedData = form.map((container) => ({
      ...container,
      pickup_date_start: formatDateTime(container.pickup_date_start),
      pickup_date_end: formatDateTime(container.pickup_date_end),
    }));

    const payload = {
      orderID: orderDetail?.headerData.id,
      order_type: orderDetail?.headerData.mode,
      containers: formattedData,
    };
    sendPreApproveData(payload)
      .then((res) => {
        setLoadingCircle(true);

        notify(res.message, 'success');
      })
      .catch((err) => {
        setLoadingCircle(true);

        notify(err.message, 'warning');
      });
  };

  const handleApproveData = () => {
    setLoadingCircle(true);
    const validationError = validateFormData(formApprove);
    if (validationError) {
      notify(validationError, 'warning');
      return;
    }

    const formattedData = formApprove.map((container) => ({
      ...container,
    }));

    const payload = {
      orderID: orderDetail?.headerData.id,
      containers: formattedData,
    };
    sendApproveData(payload)
      .then((res) => {
        setLoadingCircle(false);
        notify(res.message, 'success');
      })
      .catch((err) => {
        setLoadingCircle(true);

        notify(err.message, 'warning');
      });
  };

  console.log('orderDetail', orderDetail);
  console.log('form', form);

  React.useEffect(() => () => pusher && pusher.disconnect(), [pusher]);

  useEffect(() => {
    if (channels?.privateChannel) {
      const handleNewOrder = (data) => {
        console.log('data:', data);
        getOrderConversation();
      };

      channels.privateChannel.bind('OrderChange', handleNewOrder);
      channels.privateChannel.bind('OrderChange', handleNewOrder);
      channels.privateChannel.bind('OrderLastMessage', handleNewOrder);

      return () => {
        channels.privateChannel.unbind('OrderChange', handleNewOrder);
        channels.privateChannel.unbind('OrderChange', handleNewOrder);
        channels.privateChannel.unbind('OrderLastMessage', handleNewOrder);
      };
    }
  }, [channels]);

  const getLocationLabel = (form) => {
    const allPickup = form.every((item) => item.pickup_way === 'pickup');
    const allDropoff = form.every((item) => item.pickup_way === 'dropoff');

    console.log('allPickup', allPickup);
    console.log('allDropoff', allDropoff);

    if (allPickup) return 'Pickup';
    if (allDropoff) return 'Dropoff';
    if (!allPickup || !allDropoff) return 'Pickup/Dropoff';
    return 'Pickup/Dropoff';
  };
  const setLocationLabel = () => {
    const data = orderDetail.headerData.pre_approved_request_data;
    const allPickup = data.every((item) => item.pickup_way === 'pickup');
    const allDropoff = data.every((item) => item.pickup_way === 'dropoff');

    if (allPickup) return 'Pickup';
    if (allDropoff) return 'Dropoff';
    if (!allPickup || !allDropoff) return 'Pickup/Dropoff';
    return 'Pickup/Dropoff';
  };

  return (
    <div className={cls.messagesContainer}>
      {containersPopup.popUp && (
        <Containers
          DATA={containersPopup.DATA}
          preApprovedContainers={containersPopup.preApprovedContainers}
          preApprovedIndexContainerId={
            containersPopup.preApprovedIndexContainerId
          }
          requestedCount={requestedCount}
          preApproverowIDs={preApproverowIDs}
          setSelectedContainerItem={setSelectedContainerItem}
          Close={() =>
            setContainersPopup((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}
      {imageUploadPopup.popUp && (
        <ImageUpload
          DATA={imageUploadPopup.DATA}
          hasImage={imageUploadPopup.hasImage}
          orderStatus={imageUploadPopup.orderStatus}
          chatType={imageUploadPopup.chatType}
          orderID={imageUploadPopup.orderID}
          containerID={imageUploadPopup.containerID}
          index={imageUploadPopup.index}
          setSelectedContainerItem={setSelectedContainerItem}
          updateFormApproveData={updateFormApproveData}
          updateSelectedContainerImages={updateSelectedContainerImages}
          updateContainerApproval={updateContainerApproval}
          Close={() =>
            setImageUploadPopup((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}
      {errorPopup.popUp && (
        <ErrorPopup
          setLoadingCircle
          loadingCircle
          orderID={errorPopup.orderID}
          mode={errorPopup.mode}
          RejectEmpty={RejectEmpty}
          chatType={chatType}
          orderDetail={orderDetail}
          Close={() =>
            setErrorPopup((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}

      {!orderDetail ? (
        <div className={cls.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={cls.flowContainer}>
            <div id="chatslistLeft" className={cls.chatBox}>
              <span className={cls.topSection}>
                {chatType == 'request' ? (
                  orderDetail?.headerData.staus === 6 ? (
                    orderDetail?.headerData.dispute_request_data.owner
                      ?.accept === false ? (
                      <div className={cls.infoChatDiv}>
                        <ReactSVG
                          src={Icons.xCircle}
                          className={cls.infoCircleIcoRejected}
                        />
                        <span className={cls.infoChat}>
                          <span>
                            This Street Turn transaction has been diputed by the
                            other party.
                          </span>
                        </span>
                      </div>
                    ) : (
                      <div className={cls.infoChatDiv}>
                        <ReactSVG
                          src={Icons.xCircle}
                          className={cls.infoCircleIcoRejected}
                        />
                        <span className={cls.infoChat}>
                          <span>
                            You have successfully disputed this Street Turn
                            transaction.
                          </span>
                        </span>
                      </div>
                    )
                  ) : orderDetail?.headerData.staus === 1 ? (
                    <div className={cls.infoChatDiv}>
                      <ReactSVG
                        src={Icons.infoCircle}
                        className={cls.infoCircleIcon}
                      />
                      <span className={cls.infoChat}>
                        <span>Your request has been sent.</span>
                        <span>
                          Your reservation will be confirmed once it is
                          pre-approved.
                        </span>
                      </span>
                    </div>
                  ) : orderDetail?.headerData.staus === 3 ? (
                    <div className={cls.infoChatDiv}>
                      <ReactSVG
                        src={Icons.infoCircle}
                        className={cls.infoCircleIcon}
                      />
                      <span className={cls.infoChat}>
                        <span>Your request has been Pre-Approved.</span>
                        <span>
                          Upload the approval to complete the street turn, or
                          decline the offer.
                        </span>
                      </span>
                    </div>
                  ) : orderDetail?.headerData.staus === 4 ? (
                    <div className={cls.infoChatDiv}>
                      <ReactSVG
                        src={Icons.infoCircle}
                        className={cls.infoCircleIcon}
                      />
                      <span className={cls.infoChat}>
                        <span>Well Done! Your request has been approved.</span>
                        <span>
                          If there's an issue, you can dispute the transaction.
                        </span>
                      </span>
                    </div>
                  ) : orderDetail?.headerData.staus === 0 ? (
                    <div className={cls.infoChatDiv}>
                      <ReactSVG
                        src={Icons.xCircle}
                        className={cls.infoCircleIcoRejected}
                      />
                      <span className={cls.infoChat}>
                        <span>Your request has been canceled.</span>
                        {/* <span>
                          If there's an issue, you can dispute the transaction.
                        </span> */}
                      </span>
                    </div>
                  ) : (
                    <span className={cls.infoChat}>
                      <span>waiting for recive response.</span>
                    </span>
                  )
                ) : chatType == 'offer' ? (
                  <div className={cls.infoChatDiv}>
                    <ReactSVG
                      src={
                        orderDetail?.headerData.staus === 0 ||
                        orderDetail?.headerData.staus === 6
                          ? Icons.xCircle
                          : Icons.clock
                      }
                      className={
                        orderDetail?.headerData.staus === 0 ||
                        orderDetail?.headerData.staus === 6
                          ? cls.infoCircleIcoRejected
                          : cls.infoCircleIcon
                      }
                    />
                    {orderDetail?.headerData.staus === 1 ? (
                      <span className={cls.infoChat}>
                        {new Date() <
                        new Date(
                          new Date(
                            orderDetail?.headerData?.status_changes[0]?.updated_at
                          ).getTime() +
                            2 * 60 * 60 * 1000
                        ) ? (
                          <span className={cls.CountdownSpan}>
                            <span className={cls.CountdownSpanTitle}>
                              Respond within{' '}
                            </span>
                            <CountdownTimer
                              startTime={
                                orderDetail?.headerData?.status_changes[0]
                                  ?.updated_at
                              }
                            />{' '}
                            <span className={cls.CountdownSpanTitle}>
                              to maintain your response rate.
                            </span>
                          </span>
                        ) : null}
                        <span>
                          Your empty containers remain available to others until
                          you pre-approve the request.
                        </span>
                      </span>
                    ) : orderDetail?.headerData.staus === 0 ? (
                      <span className={cls.infoChat}>
                        <span>Your order has been canceled.</span>
                      </span>
                    ) : orderDetail?.headerData.staus === 4 ? (
                      <span className={cls.infoChat}>
                        <div className={cls.infoChatDiv}>
                          <span className={cls.infoChat}>
                            <span>
                              Well Done! Your order has been approved.
                            </span>
                            <span>
                              If there's an issue, you can dispute the
                              transaction.
                            </span>
                          </span>
                        </div>{' '}
                      </span>
                    ) : orderDetail?.headerData.staus === 3 ? (
                      <span className={cls.infoChat}>
                        <span>You have pre-approved the request.</span>
                        <span>
                          The street Turn will be completed once the other party
                          confirms and uploads the approval.
                        </span>
                      </span>
                    ) : orderDetail?.headerData.staus === 6 ? (
                      orderDetail?.headerData.dispute_request_data.owner
                        ?.accept === false ? (
                        <div className={cls.infoChatDiv}>
                          <span className={cls.infoChat}>
                            <span>
                              You have successfully disputed this Street Turn
                              transaction.
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div className={cls.infoChatDiv}>
                          <span className={cls.infoChat}>
                            <span>
                              This Street Turn transaction has been diputed by
                              the other party.
                            </span>
                          </span>
                        </div>
                      )
                    ) : (
                      <span className={cls.infoChat}>
                        <span>waiting for recive response.</span>
                      </span>
                    )}
                  </div>
                ) : (
                  <span className={cls.infoChat}>
                    <span>waiting for recive response.</span>
                  </span>
                )}
                <span className={cls.msgPopupBtns}>
                  {orderStatus.value === 4 ? (
                    <Button
                      className={clsx(cls.declineBtn)}
                      onClick={(e) => {
                        handleErrorPopup(orderDetail?.headerData.id, 'dispute');
                      }}>
                      Dispute
                    </Button>
                  ) : orderStatus.value >= 5 ? (
                    ''
                  ) : orderStatus.value !== 0 ? (
                    chatType == 'request' ? (
                      <Button
                        className={clsx(cls.declineBtn)}
                        onClick={(e) => {
                          handleErrorPopup(orderDetail?.headerData.id);
                        }}>
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        className={clsx(cls.declineBtn)}
                        onClick={(e) => {
                          handleErrorPopup(orderDetail?.headerData.id);
                          // RejectEmpty(orderDetail?.headerData.id);
                        }}>
                        Decline
                      </Button>
                    )
                  ) : (
                    ''
                  )}
                </span>
              </span>
              <div className={cls.rowSection}>
                <span className={cls.sectionSpacer} style={{ marginTop: 24 }}>
                  <div className={cls.tickIconBorder}>
                    <div className={cls.tickIconNew}>
                      <ReactSVG src={Icons.newTick} />
                    </div>
                  </div>
                  <span className={cls.sectionSpacerTitle}>
                    Reservation
                    {orderDetail?.conversation?.user_one === Number(user_id)
                      ? ' Received '
                      : ' Sent '}
                    at{' '}
                    {fTimeDate(
                      orderDetail?.headerData?.status_changes[0]?.updated_at
                    )}
                  </span>
                </span>
                <span className={cls.sectionDetail}>
                  <table id="table" className={cls.list}>
                    <thead id="amirbios1">
                      <tr id="amirbios2" className={cls.thead}>
                        <th style={{ flex: 0.3 }}>Name</th>
                        <th style={{ flex: 0.25 }}>Size</th>
                        <th style={{ flex: 0.3 }}>SSL</th>
                        <th style={{ flex: 0.15 }}>Number</th>
                        <th style={{ flex: 0.35 }}>City</th>
                        <th style={{ flex: 0.2 }}>Chassis</th>
                        <th style={{ flex: 0.15 }}>Flip Service</th>
                        {orderDetail?.headerData?.reserved_request_data
                          ?.flip_service === 1 ? (
                          <th style={{ flex: 0.3 }}>Flip Hours</th>
                        ) : (
                          ''
                        )}
                        <th style={{ flex: 0.25 }}>
                          Request
                          <Tooltip
                            // enterDelay={200}
                            // leaveDelay={10000000}
                            arrow
                            title={
                              <div
                                // style={{ width: 'max-content' }}
                                className={cls.tooltipInfoDiv}>
                                <span>
                                  Pickup: The company that needs empty
                                  containers will pick up the empty containers.
                                </span>
                                <span>
                                  Dropoff: The company that owns the containers
                                  will drop them off.
                                </span>
                              </div>
                            }
                            placement="top">
                            <ReactSVG
                              src={Icons.infoCircleBlack}
                              className={cls.infoTooltip}
                            />
                          </Tooltip>
                        </th>
                        <th
                          style={{
                            flex:
                              orderDetail?.headerData?.request_data_raw
                                .hoursLimit === 'Custom'
                                ? 0.35
                                : 0.3,
                          }}>
                          Haul Window
                        </th>
                        <th style={{ flex: 0.2 }}>Photo Required</th>
                        {orderDetail?.headerData?.request_data_raw?.movement ===
                        'pickup' ? (
                          ''
                        ) : (
                          <th style={{ flex: 0.7 }}>Dropoff Location</th>
                        )}
                      </tr>
                    </thead>
                    <tbody className={cls.tbody}>
                      <tr className={cls.tableRow}>
                        <td style={{ flex: 0.3 }}>
                          {orderDetail?.headerData.staus !== 1
                            ? chatType === 'offer'
                              ? `${
                                  orderDetail?.conversation?.usertwo.company ||
                                  'Unknown'
                                }`
                              : `${
                                  orderDetail?.conversation?.userone.company ||
                                  'Unknown'
                                }`
                            : 'Unknown'}
                        </td>
                        <td style={{ flex: 0.25 }}>
                          {orderDetail?.headerData.cabin.specifications.size +
                            '-' +
                            orderDetail?.headerData.cabin.specifications.name +
                            '-' +
                            orderDetail?.headerData.cabin.specifications.model}
                        </td>
                        <td style={{ flex: 0.3 }}>
                          {orderDetail?.headerData.cabin.company.name}
                        </td>
                        <td style={{ flex: 0.15 }}>
                          {orderDetail?.headerData.requested_count}
                        </td>
                        <td style={{ flex: 0.35 }}>
                          {orderDetail?.headerData.address.city}
                        </td>
                        <td style={{ flex: 0.2 }}>
                          {orderDetail?.headerData.chase_type === 'normal'
                            ? 'Own/Pool'
                            : orderDetail?.headerData.chase_type === 'own'
                            ? 'Own'
                            : 'Pool'}
                        </td>
                        <td style={{ flex: 0.15 }}>
                          {orderDetail?.headerData?.reserved_request_data
                            ?.flip_service === 1
                            ? 'Yes'
                            : 'No'}
                        </td>
                        {orderDetail?.headerData?.reserved_request_data
                          ?.flip_service === 1 ? (
                          <td style={{ flex: 0.3 }}>
                            {
                              orderDetail?.headerData?.reserved_request_data
                                ?.flip_start
                            }{' '}
                            -
                            {
                              orderDetail?.headerData?.reserved_request_data
                                ?.flip_end
                            }
                          </td>
                        ) : (
                          ''
                        )}
                        <td style={{ flex: 0.25 }}>
                          {orderDetail?.headerData?.request_data_raw
                            ?.movement === 'dropoff'
                            ? 'Dropoff'
                            : orderDetail?.headerData?.request_data_raw
                                ?.movement === 'pickup'
                            ? 'Pick Up'
                            : orderDetail?.headerData?.request_data_raw
                                ?.movement}
                        </td>
                        <td
                          style={{
                            flex:
                              orderDetail?.headerData?.request_data_raw
                                .hoursLimit === 'Custom'
                                ? 0.35
                                : 0.3,
                          }}>
                          {orderDetail?.headerData?.request_data_raw
                            .hoursLimit === 'Custom'
                            ? `${orderDetail?.headerData?.request_data_raw.startTime} -
                              ${orderDetail?.headerData?.request_data_raw.endTime}`
                            : orderDetail?.headerData?.request_data_raw
                                .hoursLimit}
                        </td>
                        <td style={{ flex: 0.2 }}>
                          {
                            orderDetail?.headerData?.request_data_raw
                              ?.pictureNeeded
                          }
                        </td>
                        {orderDetail?.headerData?.request_data_raw?.movement ===
                        'pickup' ? (
                          ''
                        ) : (
                          <td style={{ flex: 0.7 }}>
                            {orderDetail?.headerData?.request_data_raw?.location
                              ?.value
                              ? orderDetail?.headerData?.request_data_raw
                                  ?.location.value.city
                              : orderDetail?.headerData?.request_data_raw
                                  ?.location
                              ? orderDetail?.headerData?.request_data_raw
                                  ?.location
                              : '-'}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </span>
              </div>

              <div className={cls.rowSection}>
                <span className={cls.sectionSpacer}>
                  {orderStatus?.value === 1 ? (
                    <>
                      <div className={cls.tickIconBorder}>
                        <div className={cls.pendingProgressborder}>
                          <div className={cls.pendingProgressNow}>
                            <span className={cls.pendingProgressNowText}>
                              2
                            </span>
                          </div>
                        </div>
                      </div>
                      <span
                        className={cls.pendingSectionSpacerTitle}
                        style={{ backgroundColor: '#335CFF', color: 'white' }}>
                        Pre-Approval & Information Sharing
                      </span>
                    </>
                  ) : orderStatus?.value === 0 &&
                    containersItemsState[0]?.preApprovedContainersList
                      .length === 0 ? (
                    <>
                      <div className={cls.tickIconBorder}>
                        <span className={cls.pendingProgress}>2</span>
                      </div>
                      <span className={cls.pendingSectionSpacerTitle}>
                        Pre-Approval & Information Sharing
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={cls.sectionSpacer}>
                        <div className={cls.tickIconBorder}>
                          <div className={cls.tickIconNew}>
                            <ReactSVG src={Icons.newTick} />
                          </div>
                        </div>
                        <span className={cls.sectionSpacerTitle}>
                          You have Pre-approved the request |{' '}
                          {fTimeDate(
                            orderDetail?.headerData?.status_changes[1]
                              ?.updated_at
                          )}
                        </span>
                      </span>
                    </>
                  )}
                </span>
                {chatType === 'offer' ? (
                  containersItemsState[0]?.orderStatus === 1 ? (
                    <div className={cls.sectionDetailInprogress}>
                      <div className={cls.numberSelectDiv}>
                        <span className={cls.textCounterTitle}>
                          Select the containers to share, choose whether to pick
                          them up or have the requesting company drop them off
                          at your location. Set any pickup/drop-off criteria,
                          select the container condition, and upload a picture
                          if required. Then, pre-approve the request.
                        </span>
                        <span className={cls.numberSelectSpan}>
                          <div
                            className={`${cls.input} ${cls.containerNumberInput}`}>
                            <span
                              className={
                                cls.numberCounterTitle
                              }>{`Number to Pre-Approve `}</span>
                            <div className={cls.numbercounter}>
                              <Button
                                className={
                                  value <= 1
                                    ? cls.numberbuttonDisable
                                    : cls.numberbutton
                                }
                                onClick={(e) => {
                                  decrement();
                                }}>
                                -
                              </Button>
                              <span className={cls.numbervalue}>{value}</span>
                              <Button
                                className={
                                  value >= requestedCount
                                    ? cls.numberbuttonDisable
                                    : cls.numberbutton
                                }
                                onClick={(e) => {
                                  increment();
                                }}>
                                +
                              </Button>
                            </div>
                          </div>
                        </span>
                      </div>
                      <span className={cls.sectionDetailPreApprove}>
                        <table id="table" className={cls.listPreApprove}>
                          <thead id="amirbios1">
                            <tr id="amirbios2" className={cls.theadPreApprove}>
                              <th style={{ flex: 0.1 }}>#</th>
                              <th style={{ flex: 0.4 }}>Size</th>
                              <th style={{ flex: 0.45 }}>SSL</th>
                              <th style={{ flex: 0.5 }}>
                                Container #
                                <span className={cls.requiredField}>*</span>
                              </th>
                              <th style={{ flex: 0.4 }}>Chassis #</th>
                              <th style={{ flex: 0.4 }}>Chassis Type</th>
                              <th style={{ flex: 0.5 }}>
                                Pickup/Dropoff
                                <span className={cls.requiredField}>*</span>
                              </th>
                              <th style={{ flex: 1.2 }}>
                                {getLocationLabel(form)} Location
                                <span className={cls.requiredField}>*</span>
                              </th>
                              <th style={{ flex: 0.6 }}>
                                {getLocationLabel(form)} Date
                                <span className={cls.requiredField}>*</span>
                              </th>
                              <th style={{ flex: 0.5 }}>Container Condition</th>
                              <th style={{ flex: 0.32 }}>
                                Container Picture
                                {orderDetail?.headerData?.request_data_raw
                                  ?.pictureNeeded === 'Yes' ? (
                                  <span className={cls.requiredField}>*</span>
                                ) : (
                                  ''
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody className={cls.tableBody}>
                            {containersItemsState?.map((item, index) => (
                              <tr className={cls.tableRowPreApprove}>
                                <td style={{ flex: 0.1 }}>{index + 1}</td>
                                <td style={{ flex: 0.4 }}>{item.size}</td>
                                <td style={{ flex: 0.45 }}>{item.ssl}</td>
                                <td
                                  style={{ flex: 0.5 }}
                                  onClick={() => {
                                    handleContainersSelect(item);
                                  }}>
                                  {item.selectedContainer ? (
                                    <span className={cls.containerSelectBtn}>
                                      {item.selectedContainer?.name}
                                    </span>
                                  ) : (
                                    <span className={cls.containerSelectBtn}>
                                      Select
                                      <ReactSVG
                                        src={Icons.arrowDownNew}
                                        className={cls.emptyTableIcon}
                                      />
                                    </span>
                                  )}
                                </td>
                                <td style={{ flex: 0.4 }}>
                                  {item.selectedContainer ? (
                                    item.selectedContainer?.chases
                                  ) : (
                                    <span className={cls.containerPending}>
                                      <ReactSVG
                                        src={Icons.infoCirclePending}
                                        className={cls.emptyTableIcon}
                                      />
                                      Pending
                                    </span>
                                  )}
                                </td>
                                <td style={{ flex: 0.4 }}>
                                  {item.selectedContainer ? (
                                    item.selectedContainer?.own_chases === 1 ? (
                                      'Own'
                                    ) : (
                                      'Pool'
                                    )
                                  ) : (
                                    <span className={cls.containerPending}>
                                      <ReactSVG
                                        src={Icons.infoCirclePending}
                                        className={cls.emptyTableIcon}
                                      />
                                      Pending
                                    </span>
                                  )}
                                </td>
                                <td style={{ flex: 0.5 }}>
                                  <Select
                                    isDisabled={
                                      item.selectedContainer ? false : true
                                    }
                                    placeholder="Select"
                                    className={clsx(cls.select)}
                                    style={{
                                      background: 'red !important',
                                    }}
                                    value={{
                                      label:
                                        form[index]?.pickup_way === 'dropoff'
                                          ? 'Dropoff'
                                          : form[index]?.pickup_way ===
                                              'pickup' ||
                                            form[index]?.pickup_way ===
                                              'flexible'
                                          ? 'Pickup'
                                          : null,
                                      value: form[index]?.pickup_way,
                                    }}
                                    onChange={(pickupWay) => {
                                      // setSelectedPickupWay(pickupWay);
                                      updateFormData(
                                        index,
                                        'pickup_way',
                                        pickupWay.value
                                      );
                                      // updateFormData(
                                      //   index,
                                      //   'container_id',
                                      //   item.selectedContainer
                                      //     ? item.selectedContainer?.id
                                      //     : ''
                                      // );
                                      // updateFormApproveDates(index);
                                    }}
                                    options={pickupDropoffOpt}
                                  />
                                </td>
                                <td style={{ flex: 1.2 }}>
                                  <TextField
                                    disabled={!item.selectedContainer}
                                    value={form[index]?.pickup_location ?? ''}
                                    type="text"
                                    placeholder="Enter location"
                                    className={cls.input}
                                    onChange={(e) =>
                                      updateFormData(
                                        index,
                                        'pickup_location',
                                        e.target.value
                                      )
                                    }
                                    InputProps={{ disableUnderline: true }}
                                  />
                                  {/* <AsyncSelect
                                isDisabled={
                                  item.selectedContainer ? false : true
                                }
                                placeholder="Location"
                                className={cls.loc}
                                value={form?.pickup_location}
                                loadOptions={locationOpt}
                                onChange={(item) =>
                                  updateFormData(
                                    index,
                                    'pickup_location',
                                    item.value.city
                                  )
                                }
                                cacheOptions
                                defaultOptions
                              /> */}
                                </td>
                                <td style={{ flex: 0.6 }}>
                                  <TimePickerFromTo
                                    disabled={
                                      item.selectedContainer ? false : true
                                    }
                                    rowId={item.id}
                                    isOpen={openRowId === item.id}
                                    setOpenRowId={setOpenRowId}
                                    toTime={toTime}
                                    setToTime={setToTime}
                                    fromTime={fromTime}
                                    setFromTime={setFromTime}
                                    times={times}
                                    setTimes={setTimes}
                                    index={index}
                                    updateFormData={updateFormData}
                                    // onChange={(e) =>
                                    //   updateFormData(index, 'fromTime', times)
                                    // }
                                  />
                                </td>
                                <td style={{ flex: 0.5 }}>
                                  <Select
                                    isDisabled={
                                      item.selectedContainer ? false : true
                                    }
                                    placeholder="Select"
                                    className={clsx(cls.select)}
                                    value={form.container_conditions}
                                    onChange={(item) =>
                                      updateFormData(
                                        index,
                                        'container_conditions',
                                        item.value
                                      )
                                    }
                                    options={conditionOpts}
                                  />
                                </td>
                                <td style={{ flex: 0.32 }}>
                                  {item.selectedContainer ? (
                                    item.selectedContainer.images.length > 0 ? (
                                      <IconButton
                                        onClick={() =>
                                          handleImageUpload(
                                            item,
                                            'withImage',
                                            item.id,
                                            index
                                          )
                                        }
                                        className={
                                          cls.imageUploadActionBtnTick
                                        }>
                                        <ReactSVG
                                          // onClick={() => {
                                          //   handleImageUpload(item);
                                          // }}
                                          src={Icons.tickSquare}
                                          className={cls.galleryAddedIcon}
                                        />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        onClick={() =>
                                          handleImageUpload(
                                            item,
                                            'withImage',
                                            item.id,
                                            index
                                          )
                                        }
                                        className={cls.imageUploadActionBtn}>
                                        <ReactSVG
                                          // onClick={() => {
                                          //   handleImageUpload(item);
                                          // }}
                                          src={Icons.galleryAddBlue}
                                          className={cls.galleryAddBlueIcon}
                                        />
                                      </IconButton>
                                    )
                                  ) : (
                                    <ReactSVG
                                      src={Icons.galleryAdd}
                                      className={cls.galleryAddIcon}
                                    />
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <span className={cls.msgPopupBtns}>
                            {loadingCircle ? (
                              <Button className={clsx(cls.preApproveBtn)}>
                                <CircularProgress />
                              </Button>
                            ) : (
                              <Button
                                disabled={
                                  preApproverowIDs.length !== 0 ? false : true
                                }
                                className={clsx(cls.preApproveBtn)}
                                onClick={(e) => {
                                  handlePreApproveData(form);
                                }}>
                                <ReactSVG
                                  src={Icons.tickCircle}
                                  className={cls.emptyTableIcon}
                                />
                                Pre-Approve
                              </Button>
                            )}
                          </span>
                        </table>
                      </span>
                    </div>
                  ) : containersItemsState[0]?.orderStatus >= 3 ||
                    (containersItemsState[0]?.orderStatus === 0 &&
                      containersItemsState[0]?.preApprovedContainersList
                        .length > 0) ? (
                    <div className={cls.sectionDetailPassed}>
                      <div className={cls.numberSelectDiv}>
                        <span className={cls.preApproveTableTitle}>
                          Select the containers to share, choose whether to pick
                          them up or have the requesting company drop them off
                          at your location. Set any pickup/drop-off criteria,
                          select the container condition, and upload a picture
                          if required. Then, pre-approve the request.
                        </span>
                      </div>
                      <span className={cls.sectionDetailPreApprove}>
                        <table id="table" className={cls.listPreApprove}>
                          <thead id="amirbios1">
                            <tr id="amirbios2" className={cls.theadPreApprove}>
                              <th style={{ flex: 0.1 }}>#</th>
                              <th style={{ flex: 0.5 }}>Size</th>
                              <th style={{ flex: 0.5 }}>SSL</th>
                              <th style={{ flex: 0.5 }}>Container #</th>
                              <th style={{ flex: 0.4 }}>Chassis #</th>
                              <th style={{ flex: 0.4 }}>Chassis Type</th>
                              <th style={{ flex: 0.5 }}>Pickup/Dropoff</th>
                              <th style={{ flex: 1.2 }}>
                                {setLocationLabel()} Location
                              </th>
                              <th style={{ flex: 0.6 }}>
                                {setLocationLabel()} Date
                              </th>
                              <th style={{ flex: 0.5 }}>Container Condition</th>
                              <th style={{ flex: 0.5 }}>Container Picture</th>
                            </tr>
                          </thead>
                          <tbody className={cls.tableBody}>
                            {containersItemsState?.map((item, index) => (
                              <tr className={cls.tableRowPreApprove}>
                                <td style={{ flex: 0.1 }}>{index + 1}</td>
                                <td style={{ flex: 0.5 }}>{item.size}</td>
                                <td style={{ flex: 0.5 }}>{item.ssl}</td>
                                <td
                                  style={{ flex: 0.5 }}
                                  // onClick={() => {
                                  //   handleContainersSelect(
                                  //     item,
                                  //     'preApprovedContainers',
                                  //     item.preapprovedcontainer?.container?.id
                                  //   );
                                  // }}
                                >
                                  {item.preapprovedcontainer?.container?.name}
                                  {/* <span className={cls.containerSelectBtn}>
                                </span> */}
                                </td>
                                <td style={{ flex: 0.4 }}>
                                  {item.preapprovedcontainer?.container?.chases}
                                </td>
                                <td style={{ flex: 0.4 }}>
                                  {item.preapprovedcontainer?.container
                                    ?.own_chases === 1
                                    ? 'Own'
                                    : 'Pool'}
                                </td>
                                <td style={{ flex: 0.5 }}>
                                  {item.preapprovedcontainer?.pickup_way ===
                                  'dropoff'
                                    ? 'Dropoff'
                                    : 'Pickup'}
                                </td>
                                <td style={{ flex: 1.2 }}>
                                  {item.preapprovedcontainer?.pickup_location}
                                </td>
                                <td style={{ flex: 0.6 }}>
                                  {extractTime(
                                    item.preapprovedcontainer?.pickup_date_start
                                  )}{' '}
                                  -{' '}
                                  {extractTime(
                                    item.preapprovedcontainer?.pickup_date_end
                                  )}
                                </td>
                                <td style={{ flex: 0.5 }}>
                                  {item.preapprovedcontainer
                                    ?.container_conditions === 'undefined'
                                    ? '-'
                                    : item.preapprovedcontainer
                                        ?.container_conditions}
                                </td>
                                <td style={{ flex: 0.5 }}>
                                  {item.preapprovedcontainer?.container?.images
                                    .length > 0 ? (
                                    <IconButton
                                      onClick={() =>
                                        handleImageUpload(
                                          item,
                                          'preApprovedImage'
                                        )
                                      }
                                      className={cls.imageUploadActionBtn}>
                                      <ReactSVG
                                        // onClick={() => {
                                        //   handleImageUpload(item);
                                        // }}
                                        src={Icons.imageView}
                                        className={cls.galleryViewIcon}
                                      />
                                    </IconButton>
                                  ) : (
                                    <ReactSVG
                                      src={Icons.galleryAdd}
                                      className={cls.galleryAddIcon}
                                    />
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </span>
                      <span className={cls.usersInfoSectonOffer}>
                        <Grid
                          className={cls.usersInfoSectonBody}
                          container
                          spacing={2}>
                          <Grid item xs={12} sm={5} lg={5}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.frame}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Name:
                              </span>{' '}
                              {contactInfo?.name + ' ' + contactInfo?.last_name}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={4}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.callCalling}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Phone:
                              </span>{' '}
                              {contactInfo?.phone}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={3} lg={3}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.sms}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Email:
                              </span>{' '}
                              {contactInfo?.email}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={5} lg={5}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.buildings}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Company:
                              </span>{' '}
                              {contactInfo?.company}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={4}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.buildings}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                SCAC:
                              </span>{' '}
                              {contactInfo?.yard_scac}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={3} lg={3}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.buildings}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                DOT:
                              </span>
                              {contactInfo?.yard_dot}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={5} lg={5}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.locationNew}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Office Address:
                              </span>{' '}
                              {contactInfo?.address}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={4}>
                            <span className={cls.usersInfoSectonBodySpan}>
                              <ReactSVG
                                src={Icons.locationNew}
                                className={cls.usersInfoSectonBodyIcons}
                              />
                              <span className={cls.usersInfoSectonBodyTitles}>
                                Yard Address:
                              </span>{' '}
                              {contactInfo?.yard_location}
                            </span>
                          </Grid>
                        </Grid>
                      </span>
                    </div>
                  ) : (
                    <span className={cls.sectionDetailEmpty}>
                      <span className={cls.emptyTableHandler}>
                        <ReactSVG
                          src={Icons.emptySection}
                          className={cls.emptyTableIcon}
                        />
                        This order has been canceled.
                      </span>
                    </span>
                  )
                ) : containersItemsState[0]?.orderStatus === 1 ? (
                  <span className={cls.sectionDetailEmpty}>
                    <span className={cls.emptyTableHandler}>
                      <ReactSVG
                        src={Icons.emptySection}
                        className={cls.emptyTableIcon}
                      />
                      This step is in progress. Once your request is
                      pre-approved by the company that owns the containers, the
                      required details will be available.
                    </span>
                  </span>
                ) : containersItemsState[0]?.orderStatus === 0 &&
                  containersItemsState[0]?.preApprovedContainersList?.length ===
                    0 ? (
                  <span className={cls.sectionDetailEmpty}>
                    <span className={cls.emptyTableHandler}>
                      <ReactSVG
                        src={Icons.emptySection}
                        className={cls.emptyTableIcon}
                      />
                      This request has been canceled.
                    </span>
                  </span>
                ) : (
                  <div className={cls.sectionDetailPassed}>
                    <div className={cls.numberSelectDiv}>
                      <span className={cls.preApproveTableTitle}></span>
                    </div>

                    <table id="table" className={cls.listPreApprove}>
                      <thead id="amirbios1">
                        <tr id="amirbios2" className={cls.theadPreApprove}>
                          <th style={{ flex: 0.1 }}>#</th>
                          <th style={{ flex: 0.5 }}>Size</th>
                          <th style={{ flex: 0.5 }}>SSL</th>
                          <th style={{ flex: 0.5 }}>Container #</th>
                          <th style={{ flex: 0.4 }}>Chassis #</th>
                          <th style={{ flex: 0.4 }}>Chassis Type</th>
                          <th style={{ flex: 0.5 }}>Pickup/Dropoff</th>
                          <th style={{ flex: 1.2 }}>
                            {setLocationLabel()} Location
                          </th>
                          <th style={{ flex: 0.6 }}>
                            {setLocationLabel()} Date
                          </th>
                          <th style={{ flex: 0.5 }}>Container Condition</th>
                          <th style={{ flex: 0.5 }}>Container Picture</th>
                        </tr>
                      </thead>
                      <tbody className={cls.tableBody}>
                        {containersItemsState?.map((item, index) => (
                          <tr className={cls.tableRowPreApprove}>
                            <td style={{ flex: 0.1 }}>{index + 1}</td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.sizType}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.ssl}
                            </td>
                            <td
                              style={{ flex: 0.5 }}
                              // onClick={() => {
                              //   handleContainersSelect(
                              //     item,
                              //     'preApprovedContainers',
                              //     item?.preapprovedcontainer?.container_id
                              //   );
                              // }}
                            >
                              {/* <span className={cls.containerSelectBtn}>
                              </span> */}
                              {item?.preapprovedcontainer?.container?.name ||
                                '-'}
                            </td>
                            <td style={{ flex: 0.4 }}>
                              {item?.preapprovedcontainer?.container?.chases}
                            </td>
                            <td style={{ flex: 0.4 }}>
                              {item?.preapprovedcontainer?.container
                                ?.own_chases === 1
                                ? 'Own'
                                : 'Pool' || '-'}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.pickup_way ===
                              'dropoff'
                                ? 'Dropoff'
                                : 'Pickup'}
                            </td>
                            <Tooltip
                              arrow
                              title={item.preapprovedcontainer?.pickup_location}
                              placement="top">
                              <td
                                // className={cls.preApprovePickupLocation}
                                style={{ flex: 1.2 }}>
                                {item.preapprovedcontainer?.pickup_location}
                              </td>
                            </Tooltip>
                            <td style={{ flex: 0.6 }}>
                              {extractTime(
                                item.preapprovedcontainer?.pickup_date_start
                              )}{' '}
                              -{' '}
                              {extractTime(
                                item.preapprovedcontainer?.pickup_date_end
                              )}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer
                                ?.container_conditions === null
                                ? '-'
                                : item.preapprovedcontainer
                                    ?.container_conditions === 'undefined'
                                ? '-'
                                : item.preapprovedcontainer
                                    ?.container_conditions}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              <Tooltip
                                arrow
                                title="View pictures"
                                placement="top">
                                {item?.preapprovedcontainer?.container?.images
                                  .length <= 0 ? (
                                  <ReactSVG
                                    src={Icons.galleryAdd}
                                    className={cls.galleryAddIcon}
                                  />
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      handleImageUpload(
                                        item,
                                        'preApprovedImage'
                                      )
                                    }
                                    className={cls.imageUploadActionBtn}>
                                    <ReactSVG
                                      // onClick={() => {
                                      //   handleImageUpload(item);
                                      // }}
                                      src={Icons.imageView}
                                      className={cls.galleryViewIcon}
                                    />
                                  </IconButton>
                                )}
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <span className={cls.usersInfoSecton}>
                      <span className={cls.usersInfoSectonTitle}>
                        Here is the contact information of the other party!
                      </span>
                      <span className={cls.usersInfoSectonSubTitle}>
                        If you plan to street turn the empty container, use the
                        link below. Once you receive approval, upload the
                        approval document and confirm the street turn. 
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                        }}>
                        {contactInfo?.transferLinks?.map((link, index) => (
                          <a
                            key={index}
                            target="_blank"
                            href={
                              typeof link !== 'object'
                                ? `mailto: ${link}`
                                : link.link
                            }>
                            <Button className={clsx(cls.usersInfoSectonSSlBtn)}>
                              {typeof link !== 'object'
                                ? link
                                : link.buttonText}
                            </Button>
                          </a>
                        ))}
                      </div>
                      <Grid
                        className={cls.usersInfoSectonBody}
                        container
                        spacing={2}>
                        <Grid item xs={12} sm={5} lg={5}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.frame}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Name:
                            </span>{' '}
                            {contactInfo?.name + ' ' + contactInfo?.last_name}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.callCalling}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Phone:
                            </span>{' '}
                            {contactInfo?.phone}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.sms}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Email:
                            </span>{' '}
                            {contactInfo?.email}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={5} lg={5}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.buildings}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Company:
                            </span>{' '}
                            {contactInfo?.company}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.buildings}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              SCAC:
                            </span>{' '}
                            {contactInfo?.yard_scac}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.buildings}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              DOT:
                            </span>
                            {contactInfo?.yard_dot}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={5} lg={5}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.locationNew}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Office Address:
                            </span>{' '}
                            {contactInfo?.address}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                          <span className={cls.usersInfoSectonBodySpan}>
                            <ReactSVG
                              src={Icons.locationNew}
                              className={cls.usersInfoSectonBodyIcons}
                            />
                            <span className={cls.usersInfoSectonBodyTitles}>
                              Yard Address:
                            </span>{' '}
                            {contactInfo?.yard_location}
                          </span>
                        </Grid>
                      </Grid>
                    </span>
                  </div>
                )}
              </div>
              <div className={cls.rowSection}>
                <span className={cls.sectionSpacer}>
                  {orderStatus?.value >= 4 ||
                  containersItemsState[0]?.approvedContainersList.length > 0 ? (
                    <>
                      <div className={cls.tickIconBorder}>
                        <div className={cls.tickIconNew}>
                          <ReactSVG src={Icons.newTick} />
                        </div>
                      </div>
                      <span className={cls.sectionSpacerTitle}>
                        Approved at{' '}
                        {fTimeDate(
                          orderDetail?.headerData?.status_changes[2]?.updated_at
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      {orderStatus?.value === 3 ? (
                        <>
                          <div className={cls.tickIconBorder}>
                            <div className={cls.pendingProgressborder}>
                              <div className={cls.pendingProgressNow}>
                                <span className={cls.pendingProgressNowText}>
                                  3
                                </span>
                              </div>
                            </div>
                          </div>
                          <span
                            className={cls.pendingSectionSpacerTitle}
                            style={{
                              backgroundColor: '#335CFF',
                              color: 'white',
                            }}>
                            Approval & Street Turn Completion.
                          </span>
                        </>
                      ) : (
                        <>
                          <div className={cls.tickIconBorder}>
                            <span className={cls.pendingProgress}>3</span>
                          </div>

                          <span className={cls.pendingSectionSpacerTitle}>
                            Approval & Street Turn Completion.
                          </span>
                        </>
                      )}
                    </>
                  )}
                </span>
                {chatType === 'offer' ? (
                  containersItemsState[0]?.orderStatus >= 4 ||
                  containersItemsState[0]?.approvedContainersList.length > 0 ? (
                    <span className={cls.sectionDetailApprove}>
                      <table id="table" className={cls.listPreApprove}>
                        <thead id="amirbios1">
                          <tr id="amirbios2" className={cls.theadPreApprove}>
                            <th style={{ flex: 0.1 }}>#</th>
                            <th style={{ flex: 0.5 }}>Size</th>
                            <th style={{ flex: 0.5 }}>SSL</th>
                            <th style={{ flex: 0.5 }}>Container #</th>
                            <th style={{ flex: 0.4 }}>Chassis #</th>
                            <th style={{ flex: 0.4 }}>Chassis Type</th>
                            <th style={{ flex: 0.5 }}>Pickup/Dropoff</th>
                            <th style={{ flex: 1.2 }}>
                              {form[0]?.pickup_way === 'dropoff'
                                ? 'Dropoff'
                                : form[0]?.pickup_way === 'pickup'
                                ? 'Pickup'
                                : selectedPickupWay?.label}{' '}
                              Location
                            </th>
                            <th style={{ flex: 0.7 }}>
                              {form[0]?.pickup_way === 'dropoff'
                                ? 'Dropoff'
                                : form[0]?.pickup_way === 'pickup'
                                ? 'Pickup'
                                : selectedPickupWay?.label}{' '}
                              Date
                            </th>
                            <th style={{ flex: 0.5 }}>Approval</th>
                            <th style={{ flex: 0.7 }}>Status</th>
                          </tr>
                        </thead>
                        <tbody className={cls.tableBody}>
                          {containersItemsState?.map((item, index) => (
                            <tr className={cls.tableRowPreApprove}>
                              <td style={{ flex: 0.1 }}>{index + 1}</td>
                              <td style={{ flex: 0.5 }}>{item.size}</td>
                              <td style={{ flex: 0.5 }}>{item.ssl}</td>
                              <td
                                style={{ flex: 0.5 }}
                                // onClick={() => {
                                //   handleContainersSelect(
                                //     item,
                                //     'preApprovedContainers',
                                //     item.approvedcontainer?.container?.id
                                //   );
                                // }}
                              >
                                {item.approvedcontainer?.container?.name}
                                {/* <span className={cls.containerSelectBtn}>
                                </span> */}
                              </td>
                              <td style={{ flex: 0.4 }}>
                                {item.approvedcontainer?.container?.chases}
                              </td>
                              <td style={{ flex: 0.4 }}>
                                {item.approvedcontainer?.container
                                  ?.own_chases === 1
                                  ? 'Own'
                                  : 'Pool'}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                {item.approvedcontainer?.pickup_way ===
                                'dropoff'
                                  ? 'Dropoff'
                                  : 'Pickup'}
                              </td>
                              <td style={{ flex: 1.2 }}>
                                {item.approvedcontainer?.pickup_location}
                              </td>
                              <td style={{ flex: 0.7 }}>
                                {extractTime(
                                  item.approvedcontainer?.pickup_date_start
                                )}{' '}
                                -{' '}
                                {extractTime(
                                  item.approvedcontainer?.pickup_date_end
                                )}
                              </td>
                              <td style={{ flex: 0.5 }}>
                                <Tooltip
                                  arrow
                                  title={
                                    item.approvedcontainer?.approve_status ===
                                    '0'
                                      ? 'This Container has been declined'
                                      : 'View Approval'
                                  }
                                  placement="top">
                                  {item.approvedcontainer?.container?.approvals
                                    ?.length > 0 &&
                                  item.approvedcontainer?.approve_status !==
                                    '0' ? (
                                    <IconButton
                                      onClick={() =>
                                        handleImageUpload(item, 'approvedImage')
                                      }
                                      className={cls.imageUploadActionBtn}>
                                      <ReactSVG
                                        // onClick={() => {
                                        //   handleImageUpload(item);
                                        // }}
                                        src={Icons.imageView}
                                        className={cls.galleryViewIcon}
                                      />
                                    </IconButton>
                                  ) : (
                                    <ReactSVG
                                      src={Icons.galleryAdd}
                                      className={cls.galleryAddIcon}
                                    />
                                  )}
                                </Tooltip>
                              </td>
                              <td style={{ flex: 0.7 }}>
                                {item.approvedcontainer?.approve_status ===
                                '0' ? (
                                  <span className={cls.declinedText}>
                                    Declined
                                  </span>
                                ) : (
                                  <span className={cls.confirmedText}>
                                    Confirmed
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </span>
                  ) : containersItemsState[0]?.orderStatus === 3 ? (
                    <span className={cls.sectionDetailEmptyEnd}>
                      <span className={cls.emptyTableHandler}>
                        <ReactSVG
                          src={Icons.emptySection}
                          className={cls.emptyTableIcon}
                        />
                        <span>This step is in progress.</span>
                        <span>
                          Once the requesting company approves your offered
                          container and uploads the approval, the street turn
                          will be completed.
                        </span>
                      </span>
                    </span>
                  ) : containersItemsState[0]?.orderStatus === 0 &&
                    containersItemsState[0]?.approvedContainersList?.length ===
                      0 ? (
                    <span className={cls.sectionDetailEmptyEnd}>
                      <span className={cls.emptyTableHandler}>
                        <ReactSVG
                          src={Icons.emptySection}
                          className={cls.emptyTableIcon}
                        />
                        This order has been canceled.
                      </span>
                    </span>
                  ) : (
                    <span className={cls.sectionDetailEmptyEnd}>
                      <span className={cls.emptyTableHandler}>
                        <ReactSVG
                          src={Icons.emptySection}
                          className={cls.emptyTableIcon}
                        />
                        This step has not started yet. Once it begins and gets
                        approved, the information will be available.
                      </span>
                    </span>
                  )
                ) : containersItemsState[0]?.orderStatus === 3 ? (
                  <span className={cls.sectionDetailApprove}>
                    <div className={cls.numberSelectDiv}>
                      <span className={cls.preApproveTableTitle}>
                        Confirm the containers and upload the approval to
                        complete the street turn, or decline the offer.
                      </span>
                    </div>
                    <table id="table" className={cls.listPreApprove}>
                      <thead id="amirbios1">
                        <tr id="amirbios2" className={cls.theadPreApprove}>
                          <th style={{ flex: 0.1 }}>#</th>
                          <th style={{ flex: 0.5 }}>Size</th>
                          <th style={{ flex: 0.5 }}>SSL</th>
                          <th style={{ flex: 0.5 }}>Container #</th>
                          <th style={{ flex: 0.4 }}>Chassis #</th>
                          <th style={{ flex: 0.4 }}>Chassis Type</th>
                          <th style={{ flex: 1.2 }}>
                            {setLocationLabel()} Location
                          </th>
                          <th style={{ flex: 0.6 }}>
                            {setLocationLabel()} Date
                          </th>
                          <th style={{ flex: 0.7 }}>Container Condition</th>
                          <th style={{ flex: 0.8 }}>
                            Approval
                            <span className={cls.requiredField}>*</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className={cls.tableBody}>
                        {containersItemsState?.map((item, index) => (
                          <tr className={cls.tableRowPreApprove}>
                            <td style={{ flex: 0.1 }}>{index + 1}</td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.sizType}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.ssl}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.preapprovedcontainer?.container?.name}
                            </td>
                            <td style={{ flex: 0.4 }}>
                              {item.preapprovedcontainer?.container?.chases}
                            </td>
                            <td style={{ flex: 0.4 }}>
                              {item.preapprovedcontainer?.container
                                ?.own_chases === 1
                                ? 'Own'
                                : 'Pool'}
                            </td>
                            <Tooltip
                              arrow
                              title={item.preapprovedcontainer?.pickup_location}
                              placement="top">
                              <td
                                // className={cls.preApprovePickupLocation}
                                style={{ flex: 1.2 }}>
                                {item.preapprovedcontainer?.pickup_location}
                              </td>
                            </Tooltip>
                            <td style={{ flex: 0.6 }}>
                              {extractTime(
                                item.preapprovedcontainer?.pickup_date_start
                              )}{' '}
                              -{' '}
                              {extractTime(
                                item.preapprovedcontainer?.pickup_date_end
                              )}
                            </td>
                            <td style={{ flex: 0.7 }}>
                              <span className={cls.containerConditions}>
                                {item.preapprovedcontainer
                                  ?.container_conditions === null
                                  ? '-'
                                  : item.preapprovedcontainer
                                      ?.container_conditions === 'undefined'
                                  ? '-'
                                  : item.preapprovedcontainer
                                      ?.container_conditions}
                              </span>
                            </td>
                            <td style={{ flex: 0.8 }}>
                              <Tooltip
                                arrow
                                title="Confirm & Upload Approval"
                                placement="top">
                                <Button
                                  style={{
                                    background:
                                      formApprove[index]?.approve_status === 1
                                        ? '#1fc16b47'
                                        : 'transparent',
                                  }}
                                  className={clsx(cls.cunfirmBtn)}
                                  onClick={(e) => {
                                    handleImageUpload(
                                      item,
                                      'approvalFile',
                                      item.preapprovedcontainer?.container.id,
                                      index
                                    );
                                  }}>
                                  Confirm
                                </Button>
                              </Tooltip>
                              <Button
                                style={{
                                  background:
                                    formApprove[index]?.approve_status === 0
                                      ? '#ff000033'
                                      : 'transparent',
                                }}
                                className={clsx(cls.declinedBtn)}
                                onClick={(e) => {
                                  updateFormApproveData(
                                    index,
                                    'approve_status',
                                    0
                                  );
                                  updateFormApproveData(
                                    index,
                                    'container_id',
                                    item.preApprovedContainersList
                                      ? item.preapprovedcontainer?.container.id
                                      : ''
                                  );
                                }}>
                                Decline
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <span className={cls.msgPopupBtns}>
                        {loadingCircle ? (
                          <Button className={clsx(cls.preApproveBtn)}>
                            <CircularProgress />
                          </Button>
                        ) : (
                          <Button
                            disabled={
                              formApprove[0].container_id ? false : true
                            }
                            className={clsx(cls.preApproveBtn)}
                            onClick={(e) => {
                              handleApproveData(formApprove);
                            }}>
                            <ReactSVG
                              src={Icons.tickCircle}
                              className={cls.emptyTableIcon}
                            />
                            Approve
                          </Button>
                        )}
                      </span>
                    </table>
                  </span>
                ) : containersItemsState[0]?.orderStatus >= 4 ||
                  containersItemsState[0]?.approvedContainersList.length > 0 ? (
                  <span className={cls.sectionDetailApprove}>
                    <table id="table" className={cls.listPreApprove}>
                      <thead id="amirbios1">
                        <tr id="amirbios2" className={cls.theadPreApprove}>
                          <th style={{ flex: 0.1 }}>#</th>
                          <th style={{ flex: 0.5 }}>Size</th>
                          <th style={{ flex: 0.5 }}>SSL</th>
                          <th style={{ flex: 0.5 }}>Container #</th>
                          <th style={{ flex: 0.4 }}>Chassis #</th>
                          <th style={{ flex: 0.4 }}>Chassis Type</th>
                          <th style={{ flex: 0.5 }}>Pickup/Dropoff</th>
                          <th style={{ flex: 1.2 }}>
                            {setLocationLabel()} Location
                          </th>
                          <th style={{ flex: 0.6 }}>
                            {setLocationLabel()} Date
                          </th>
                          <th style={{ flex: 0.5 }}>Approval</th>
                          <th style={{ flex: 0.5 }}>Status</th>
                        </tr>
                      </thead>
                      <tbody className={cls.tableBody}>
                        {containersItemsState?.map((item, index) => (
                          <tr className={cls.tableRowPreApprove}>
                            <td style={{ flex: 0.1 }}>{index + 1}</td>
                            <td style={{ flex: 0.5 }}>{item.size}</td>
                            <td style={{ flex: 0.5 }}>{item.ssl}</td>
                            <td
                              style={{ flex: 0.5 }}
                              // onClick={() => {
                              //   handleContainersSelect(
                              //     item,
                              //     'preApprovedContainers',
                              //     item.approvedcontainer.container.id
                              //   );
                              // }}
                            >
                              {/* <span className={cls.containerSelectBtn}>
                              </span> */}
                              {item.approvedcontainer?.container?.name}
                            </td>
                            <td style={{ flex: 0.4 }}>
                              {item.approvedcontainer?.container?.chases}
                            </td>
                            <td style={{ flex: 0.4 }}>
                              {item.approvedcontainer?.container?.own_chases ===
                              1
                                ? 'Own'
                                : 'Pool'}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              {item.approvedcontainer?.pickup_way === 'dropoff'
                                ? 'Dropoff'
                                : 'Pickup'}
                            </td>
                            <td style={{ flex: 1.2 }}>
                              {item.approvedcontainer?.pickup_location}
                            </td>
                            <td style={{ flex: 0.6 }}>
                              {extractTime(
                                item.approvedcontainer?.pickup_date_start
                              )}{' '}
                              -{' '}
                              {extractTime(
                                item.approvedcontainer?.pickup_date_end
                              )}
                            </td>
                            <td style={{ flex: 0.5 }}>
                              <Tooltip
                                arrow
                                title={
                                  item.approvedcontainer?.approve_status === '0'
                                    ? 'This Container has been declined'
                                    : 'View Approval'
                                }
                                placement="top">
                                {item.approvedcontainer?.container?.approvals
                                  ?.length > 0 &&
                                item.approvedcontainer?.approve_status !==
                                  '0' ? (
                                  <IconButton
                                    onClick={() =>
                                      handleImageUpload(item, 'approvedImage')
                                    }
                                    className={cls.tickIcon}>
                                    <ReactSVG
                                      // onClick={() => {
                                      //   handleImageUpload(item);
                                      // }}
                                      src={Icons.imageView}
                                      className={cls.galleryViewIcon}
                                    />
                                  </IconButton>
                                ) : (
                                  <ReactSVG
                                    src={Icons.galleryAdd}
                                    className={cls.galleryAddIcon}
                                  />
                                )}
                              </Tooltip>
                            </td>{' '}
                            <td style={{ flex: 0.5 }}>
                              {item.approvedcontainer?.approve_status ===
                              '0' ? (
                                <span className={cls.declinedText}>
                                  Declined
                                </span>
                              ) : (
                                <span className={cls.confirmedText}>
                                  Confirmed
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </span>
                ) : containersItemsState[0]?.orderStatus === 0 &&
                  containersItemsState[0]?.approvedContainersList?.length ===
                    0 ? (
                  <span className={cls.sectionDetailEmptyEnd}>
                    <span className={cls.emptyTableHandler}>
                      <ReactSVG
                        src={Icons.emptySection}
                        className={cls.emptyTableIcon}
                      />
                      This request has been canceled.
                    </span>
                  </span>
                ) : (
                  <span className={cls.sectionDetailEmptyEnd}>
                    <span className={cls.emptyTableHandler}>
                      <ReactSVG
                        src={Icons.emptySection}
                        className={cls.emptyTableIcon}
                      />
                      This step has not started yet. Once it begins, the
                      information will be available.
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
