import React, { useEffect, useState, useRef } from 'react';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import closeIcon from '../../../assets/img/closeIcon.svg';
import {
  Modal,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { useOutsideAlerter } from '@/hocs';
import { Tooltip, Grid, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import { TextareaAutosize, FormControl } from '@mui/material';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import {
  adminUploadHtmlToScrape,
  orderApprove,
  orderConfirm,
  uploadContainerImages,
} from '@/actions';
import { Loading, useNotify } from 'react-admin';
import { ReactSVG } from 'react-svg';
import { Icons } from '@/assets';

export default ({
  Close,
  orderID,
  RejectEmpty,
  mode,
  setLoadingCircle,
  loadingCircle,
  chatType,
  orderDetail,
}) => {
  const cls = styles();
  const wrapperRef = useRef(null);
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => {
    if (state) {
      const user = state.reducer?.user;
      return user;
    }
  });

  const [formData, setFormData] = useState(
    chatType === 'request'
      ? {
          'Container not in acceptable condition.': false,
          'Container not available at listed location.': false,
          'Shipping line approval revoked.': false,
          'Time constraints prevented completion.': false,
          'Export booking canceled or modified.': false,
          'Unexpected high per diem fees.': false,
          'Other reasons': false,
          Otherreasonsmessage: '',
        }
      : {
          'Container gated in before pickup.': false,
          'Operational issues prevented release.': false,
          'Container was already assigned elsewhere.': false,
          'Container used for dual transaction.': false,
          'Container moved to a different location.': false,
          'Trucking company lost equipment.': false,
          'Other reasons': false,
          Otherreasonsmessage: '',
        }
  );

  const dipute = (orderID) => {
    setLoading(true);
    if (formData['Other reasons'] && !formData.Otherreasonsmessage.trim()) {
      notify('Please enter your reason in the text area.', 'warning');
      return;
    }

    const isConfirm = false;
    orderConfirm(orderID, { accept: isConfirm, data: formData }).then((res) => {
      setLoading(false);
      if (res) {
        notify(
          `Successfully ${isConfirm ? 'confirmed' : 'disputed'}`,
          'success'
        );
        Close();
      }
    });
  };

  const handleCheckboxChange = (name) => (event) => {
    const { checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  return (
    <div className={cls.popup}>
      <div className={cls.uploadImageCard}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.closeP} onClick={Close} />
        </Tooltip>
        <div ref={wrapperRef} className={cls.popupCard}>
          <span className={cls.tableTopActions}>
            <ReactSVG src={Icons.declineIcon} className={cls.dicsounticon} />
            {mode === 'dispute'
              ? 'Dispute Confirmation'
              : 'Decline Confirmation'}
          </span>
          {mode === 'dispute' ? (
            <span className={cls.disputeTextSpan}>
              <span className={cls.disputeTex}>
                Dear <span style={{ fontWeight: 'bold' }}>{user.company}</span>,
                please select or type your reason for the dispute.
              </span>
              {Object.keys(formData).map((key) =>
                key !== 'Otherreasonsmessage' ? (
                  <div key={key} className={cls.inputGroup}>
                    <Checkbox
                      checked={formData[key]}
                      onChange={handleCheckboxChange(key)}
                      className={cls.checkbox}
                    />
                    <label className={cls.label}>{key}</label>
                  </div>
                ) : null
              )}

              {formData['Other reasons'] && (
                <FormControl className={cls.radioBtns}>
                  <TextareaAutosize
                    className={cls.inputTextArea}
                    placeholder="Enter your reason..."
                    value={formData.Otherreasonsmessage ?? ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormData((prev) => ({
                        ...prev,
                        Otherreasonsmessage: value,
                      }));
                    }}
                    minRows={4}
                    maxRows={4}
                    maxLength={200}
                  />
                </FormControl>
              )}
            </span>
          ) : (
            <span className={cls.declineTextSpan}>
              {'Are you sure you want to Decline?'}
            </span>
          )}
        </div>
        {mode === 'dispute' ? (
          <div className={cls.disputePopupBtns} style={{ marginTop: '20px' }}>
            <Button className={clsx(cls.cancelBtn)} onClick={() => Close()}>
              Cancel
            </Button>
            {loading ? (
              <Button className={clsx(cls.submitBtn)}>
                <CircularProgress />
              </Button>
            ) : (
              <Button
                className={clsx(cls.submitBtn)}
                onClick={() => dipute(orderID)}>
                Confirm
              </Button>
            )}
          </div>
        ) : (
          <div className={cls.declinePopupBtns} style={{ marginTop: '20px' }}>
            <Button className={clsx(cls.cancelBtn)} onClick={() => Close()}>
              No, Cancel
            </Button>
            {loading ? (
              <Button className={clsx(cls.submitBtn)}>
                <CircularProgress />
              </Button>
            ) : loading ? (
              <Button className={clsx(cls.submitBtn)}>
                <CircularProgress />
              </Button>
            ) : (
              <Button
                className={clsx(cls.submitBtn)}
                onClick={() => RejectEmpty(orderID)}>
                Yes, Decline
              </Button>
            )}
          </div>
        )}{' '}
      </div>
    </div>
  );
};
