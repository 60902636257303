import Types from './types';

const initialState = {
  size: [],
  ssl: [],
  user: { name: '' },
  orderBadge: {},
  Chat: null,
  containerData: [],
  ChatItem: null,
  AIChat: null,
  notif: {
    request: null,
    order: null,
    chat: null,
    archive: null,
    support: null,
    update: null,
    containerChange: null,
  },
  refresh: null,
  refreshChat: null,
  refreshPage: '',
  ContactInfoPopup: false,
  editMemberPopup: false,
  addMemberPopup: false,
  deleteMemberPopup: false,
  openUserMenu: false,
  whatsappNewMessages: [],
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case Types.SaveData:
      return Object.assign({}, state, data);

    case Types.SaveNotif:
      return Object.assign({}, state, {
        notif: { ...state.notif, ...data },
      });

    case Types.AddData:
      return Object.assign({}, state, {
        [data.key]: [...state[data.key], data.value],
      });

    default:
      return state;
  }
};
