import React, { useState } from 'react';
import {
  Card,
  Avatar,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import _get from 'lodash/get';
import {
  AccountCircle,
  RoomRounded,
  CallRounded,
  HomeRounded,
  MailRounded,
  WatchLaterSharp,
  LocalShippingSharp,
  ApartmentSharp,
} from '@material-ui/icons';

import styles from './styles';
import { enableContainer } from '@/actions';
import ChassisList from './ChassisList';
import ReservationTimePicker from '@/components/Chat/ReservationTimePicker';

export default ({ data, openPopUp, notify, chassis, setChassis }) => {
  const cls = styles();
  const [enableContainerName, setEnableContainerName] = useState(false);
  const [DATA, setData] = useState({
    avatar: null,
    name: '',
    com: '',
    phone: '',
    add: '',
    bio: '',
    email: '',
  });
  const [openClock, setOpenClock] = React.useState(null); // Manage which clock's popup is open
  const [times, setTimes] = React.useState([]);

  React.useEffect(() => {
    console.log('user data', data);
    setData({
      avatar: data.image,
      name: _get(data, 'name', '') + ' ' + (_get(data, 'last_name', '') || ''),
      com: _get(data, 'company', ''),
      phone: _get(data, 'phone', ''),
      add: _get(data, 'address', ''),
      bio: _get(data, 'bio', ''),
      email: _get(data, 'email', ''),
      yard_start: _get(data, 'yard_start', ''),
      yard_end: _get(data, 'yard_end', ''),
      yard_location: _get(data, 'yard_location', ''),
      yard_zip: _get(data, 'yard_zip', ''),
      yard_trucks: _get(data, 'yard_trucks', ''),
      yard_scac: _get(data, 'yard_scac', ''),
      yard_dot: _get(data, 'yard_dot', ''),
      enable_container_name: _get(data, 'enable_container_name', ''),
      flip_available: _get(data, 'flip_available', ''),
      flip_start: _get(data, 'flip_start', ''),
      flip_end: _get(data, 'flip_end', ''),
      own_chases_enabled: _get(data, 'own_chases_enabled', ''),
      zip: _get(data, 'zip', ''),
    });
    if (data.enable_container_name === 1) {
      setEnableContainerName(true);
    } else {
      setEnableContainerName(false);
    }
  }, [data]);

  return (
    <>
      {chassis.popUp && (
        <ChassisList
          DATA={chassis.DATA}
          Close={() =>
            setChassis((prev) => {
              return {
                ...prev,
                popUp: false,
              };
            })
          }
        />
      )}
      <Card className={cls.card}>
        <div className={cls.content}>
          <div className={cls.avatarCont} onClick={openPopUp}>
            <Avatar className={cls.avatar} src={DATA.avatar} alt={DATA.name} />
            <span className={cls.avatarText}>change profile photo</span>
          </div>
          <div className={cls.row}>
            <AccountCircle className={cls.icon} />
            <span>
              <span className={cls.pTitle}>Name: </span>
              {DATA.name || ''}
            </span>
          </div>
          <div className={cls.row}>
            <HomeRounded className={cls.icon} />
            <span>
              <span className={cls.pTitle}>Company: </span> {DATA.com || ''}
            </span>
          </div>
          <div className={cls.row}>
            <CallRounded className={cls.icon} />
            <span>
              <span className={cls.pTitle}>Phone: </span>
              {DATA.phone || ''}
            </span>
          </div>
          <div className={cls.row}>
            <MailRounded className={cls.icon} />
            <span>
              <span className={cls.pTitle}>Email: </span>
              {DATA.email || ''}
            </span>
          </div>
          <div className={cls.row}>
            <RoomRounded className={cls.icon} />
            <span>
              <span className={cls.pTitle}>Office Address: </span>
              {`${DATA.add || ''} ${DATA.zip ? '-' : ''} ${DATA.zip || ''}`}
            </span>
          </div>
          <div className={cls.row}>
            <WatchLaterSharp className={cls.icon} />
            <span>
              <span className={cls.pTitle}>Yard Hours of Operation: </span>
              {`${DATA.yard_start || ''} ${DATA.yard_end ? '-' : ''} ${
                DATA.yard_end || ''
              }`}
            </span>
          </div>
          <div className={cls.row}>
            <RoomRounded className={cls.icon} />
            <span>
              <span className={cls.pTitle}>Yard Address: </span>
              {`${DATA.yard_location || ''} ${DATA.yard_zip ? '-' : ''} ${
                DATA.yard_zip || ''
              }`}
            </span>
          </div>{' '}
          <div className={cls.row}>
            <LocalShippingSharp className={cls.icon} />
            <span>
              <span className={cls.pTitle}>Number of Trucks: </span>
              {`${DATA.yard_trucks || ''}`}
            </span>
          </div>{' '}
          <div className={cls.row}>
            <ApartmentSharp className={cls.icon} />
            <span>
              <span className={cls.pTitle}>SCAC: </span>
              {`${DATA.yard_scac || ''}`}
            </span>
          </div>{' '}
          <div className={cls.row}>
            <ApartmentSharp className={cls.icon} />
            <span>
              <span className={cls.pTitle}>DOT: </span>
              {`${DATA.yard_dot || ''}`}
            </span>
          </div>
          <div className={cls.row} style={{ marginTop: '-5px' }}>
            <FormControlLabel
              className={`${
                DATA.flip_available
                  ? cls.enabledContainer
                  : cls.disabledContainer
              }`}
              control={
                <Checkbox
                  checked={DATA.flip_available ? true : false}
                  className={`${cls.checkbox}`}
                  disabled={true}
                  // onChange={(e) => handleToggleContanerNames(e.target.checked)}
                />
              }
              label="Flip Service Available"
            />
            {`${DATA.flip_start || ''} ${DATA.flip_end ? '-' : ''} ${
              DATA.flip_end || ''
            }`}
          </div>
          <div className={cls.row} style={{ marginTop: '-15px' }}>
            <FormControlLabel
              className={`${
                DATA.enable_container_name
                  ? cls.enabledContainer
                  : cls.disabledContainer
              }`}
              control={
                <Checkbox
                  checked={DATA.enable_container_name ? true : false}
                  className={`${cls.checkbox}`}
                  disabled={true}
                  // onChange={(e) => handleToggleContanerNames(e.target.checked)}
                />
              }
              label="Container Numbers"
            />
          </div>
          {/* {DATA.enable_container_name === 1 && ( */}
          <div className={cls.row} style={{ marginTop: '-15px' }}>
            <FormControlLabel
              className={`${
                DATA.own_chases_enabled
                  ? cls.enabledContainer
                  : cls.disabledContainer
              }`}
              control={
                <Checkbox
                  checked={DATA.own_chases_enabled ? true : false}
                  className={`${cls.checkbox}`}
                  disabled={true}
                  // onChange={(e) => handleToggleContanerNames(e.target.checked)}
                />
              }
              label="Share Containers on Own Chassis"
            />
          </div>
          {/* )} */}
          <Button
            onClick={() =>
              setChassis((prev) => {
                return {
                  ...prev,
                  popUp: true,
                };
              })
            }
            className={cls.chasissBtn}>
            Own Chassis List
          </Button>
          {/* <div className={cls.row}>
            <span className={cls.bio}>
              <span>Bio: </span>
              {DATA.bio}
            </span>
          </div> */}
        </div>
      </Card>
    </>
  );
};
