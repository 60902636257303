import React, { useEffect, useState, useRef } from 'react';
import { Loading, useNotify } from 'react-admin';
import {
  Button,
  TextField,
  Tooltip,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';

import { locationOpt, _get } from '@/actions/Helper';
import DateInput from '@/components/DateInput';

import styles from './setAppointmentStyle';
import { availableApointmentTimes, bookApointment } from '@/actions';
import Containers from '../AIGroup/Containers';
import { size } from 'lodash';
import { TerminalsIcon } from '@/assets';
import clsx from 'clsx';

export default ({
  Update,
  CloseAppointmentSet,
  dataSet,
  containerId,
  cabinId,
  container,
  shift,
  terminal,
  containerAppointments,
  terminalId,
  dat,
}) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const Sizes = useSelector((state) => state.reducer.size);
  const SSLs = useSelector((state) => state.reducer.ssl);
  const [selectedData, setSelectedData] = useState(new Date());
  const [Data, setData] = React.useState([]);
  const notify = useNotify();
  const parseData = ({ id, size, ssl, date, count_all, address }) => ({
    id: containerId,
    date,
    count: count_all,
    size: Sizes.find(({ label }) => label === size),
    ssl: SSLs.find(({ label }) => label === ssl),
    loc: {
      '& svg': {
        fill: '#f8b100',
      },

      label: _get(address, 'address', ''),
      value: {
        lng: _get(address, 'long', ''),
        lat: _get(address, 'lat', ''),
        address: _get(address, 'address', ''),
        city: _get(address, 'city', ''),
      },
    },
    container_name: container.name,
    chasses_name: container.chases,
  });

  useEffect(() => {
    getData();
  }, []);

  console.log('selectedData', selectedData);

  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    if (format == 1) {
      return `${year}-${monthNumber}-${day}`;
    } else {
      return { day, dayNumber, dayName, monthNumber, month, year };
    }
  }

  const [containers, setContainers] = React.useState(dataSet?.containers);

  const [form, setFormData] = React.useState({
    id: null,
    gated_in: container.gated_in,
    gated_out: container.gated_out,
    container_name: container.name,
    chasses_name: container.chases,
    size: null,
    ssl: null,
    date: null,
    count: 0,
    loc: null,
  });

  React.useEffect(() => {
    setFormData(parseData(dataSet));
  }, [dataSet, Sizes, SSLs]);

  const setForm = (it, value) => setFormData({ ...form, [it]: value });

  const handleUpdateContainers = (name, value, index) => {
    setContainers((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const submit = () => {
    setLoading(true);
    bookApointment({
      containerId: containerId,
      TerminalAppointmentAvailabilityId: selectedData,
    })
      .then((res) => {
        setLoading(false);
        CloseAppointmentSet();
        // const color = res.message === 'success' ? 'success' : 'warning';
        notify(res.message, 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };
  const getData = () => {
    availableApointmentTimes({
      cabinId: cabinId,
      terminalId: terminalId,
      date: dat,
    })
      .then((res) => {
        setData(res.availabilities);
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  // const getInventoryContainers = () => {
  //   let inventoryContainers = [];
  //   dataSet.containers.forEach((container) => {
  //     console.log('con', container);
  //     console.log('ff', form);

  //     // if (container.status) {
  //     inventoryContainers.push({
  //       size: dataSet.size,
  //       ssl: dataSet.ssl,
  //       location: dataSet.loc,
  //       lfd: formatDateTimeToUTC(container.last_free_day),
  //       aptType: 'None',
  //       chassesType:
  //         container.own_chases === 0
  //           ? 'Pool'
  //           : container.own_chases === 1
  //           ? 'Own'
  //           : '',
  //       terminalApt: container.return_appointment || 'No Apt.',
  //       name: container.name ? container.name : '',
  //       id: container.id ? container.id : null,
  //       chases: container.chases ? container.chases : '',
  //       status: container.status ? container.status : null,
  //       gated_in: container.gated_in
  //         ? formatDateTimeToUTC(container.gated_in)
  //         : null,
  //       gated_out: container.gated_out
  //         ? formatDateTimeToUTC(container.gated_out)
  //         : null,
  //     });
  //     // }
  //   });
  //   setContainers(inventoryContainers);
  // };

  // useEffect(() => {
  //   getInventoryContainers();
  // }, []);

  return (
    <div
      id="app_modal"
      onClick={(e) => e.target.id === 'app_modal' && CloseAppointmentSet()}
      className={cls.popup}>
      <div className={cls.card}>
        <>
          <Tooltip arrow title="Close" placement="top">
            <CloseRounded
              className={cls.closeP}
              onClick={CloseAppointmentSet}
            />
          </Tooltip>
          <table style={{ marginBottom: '30px' }} className={cls.list}>
            <thead>
              <tr className={cls.thead}>
                <th style={{ flex: 1 }}>Terminal</th>
                <th style={{ flex: 1 }}>Date</th>
                <th style={{ flex: 1 }}>Shift</th>
              </tr>
            </thead>
            <tbody className={cls.tbody}>
              <>
                {container.id === containerId && (
                  <tr
                    style={{ border: '1px solid #e9e9e9' }}
                    className={cls.tableRow}>
                    <td style={{ flex: 1 }}>{terminal}</td>
                    <td style={{ flex: 1 }}>{dat}</td>
                    <td style={{ flex: 1 }}>{shift}</td>
                  </tr>
                )}
              </>
            </tbody>
          </table>
          <div className={cls.timesDiv}>
            <span className={cls.span1Header}>
              <span className={cls.span2Header}>Time Slot</span>
              <span className={cls.span2Header}>Available Appointments</span>
            </span>
            {Data?.map((data) => (
              <span
                className={
                  data?.id === selectedData
                    ? cls.span1Selected
                    : /*: containerAppointments?.some(
                        (item) => item.taa_id === data?.id
                      )
                    ? cls.span1Booked*/
                      cls.span1
                }
                onClick={() => setSelectedData(data.id)}>
                <span className={cls.span2}>
                  {data?.raw_data?.date.replace(
                    /(\d{2}:\d{2})-(\d{2}:\d{2})/,
                    '$1  -  $2'
                  )}
                </span>
                <span className={cls.span2}>
                  {data?.raw_data?.appointmentsCount}
                </span>
              </span>
            ))}
          </div>

          {loading ? (
            <Button className={clsx(cls.submitBtn)}>
              <CircularProgress />
            </Button>
          ) : (
            <Button className={clsx(cls.submitBtn)} onClick={submit}>
              Make Booking
            </Button>
          )}
        </>
      </div>
    </div>
  );
};
