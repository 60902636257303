// in src/Menu.js
import React, { useEffect } from 'react';
import { BaseIcon } from '@/Utils';
import { ChatBarIcons, SidebarIcons as Icons } from '@/assets';
import MenuItemLink from './MenuItemLink';
import RequestMenu from './RequestMenu';
import OrderMenu from './OrderMenu';
import styles from './styles';
import { usePermissions } from 'react-admin';
import ContentMenu from './ContentMenu';
import { SaveData, SaveNotif } from '@/actions';
import IntegrationMenu from './IntegrationMenu';
import MembersMenu from './MembersMenu';
import FeaturesMenu from './FeaturesMenu';
import InventoryMenu from './InventoryMenu';
import SearchMenu from './SearchMenu';
import ExportMenu from './ExportMenu';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TerminalMenu from './TerminalMenu';
import AiMenu from './AiMenu';
import ScheduleAdmin from './ScheduleAdmin';
import InHouseMenu from './InHouseMenu';
import LogsMenu from './LogsMenu';
import InboxMenu from './InboxMenu';
import { ReactSVG } from 'react-svg';
import { IconButton } from '@mui/material';
import useMediaQuery from 'use-mediaquery';
import { usePusher } from '../../../App';

export default ({ onMenuClick }) => {
  const cls = styles();

  const { pathname } = useLocation();
  const isSmallDevice = useMediaQuery('(min-width: 1000px)');
  const isMobileDevice = useMediaQuery('(min-width: 763px)');
  const { channels } = usePusher();

  // const AINotif = useSelector(
  //   (state) => state.reducer.notif['whatsapp-messages'] || null
  // );
  const user = useSelector((state) => state?.reducer.user);
  const orderBadge = useSelector((state) => state?.reducer.orderBadge);
  const notif = useSelector((state) => state.reducer.notif || {});

  const [seenCount, setSeenCount] = React.useState(orderBadge.sum);

  const enable_schedule = user?.metas?.enable_schedules?.value;
  const street_turn = user?.metas?.enable_street_turn?.value;
  const mptyTrackingEnable = enable_schedule;
  const enable_street_turn = street_turn;

  useEffect(() => {
    if (channels?.privateChannel) {
      const handleNewOrder = (data) => {
        setSeenCount(data?.message?.sum);
      };

      channels.privateChannel.bind('InboxUnreadCount', handleNewOrder);

      return () => {
        channels.privateChannel.unbind('InboxUnreadCount', handleNewOrder);
      };
    }
  }, [channels]);

  useEffect(() => {
    setSeenCount(orderBadge.sum);
  }, [orderBadge]);

  const MenuItem = ({
    to,
    title,
    icon,
    onClick,
    hasBadge = false,
    hasBadgeNum = 0,
  }) => (
    <div style={{ position: 'relative' }}>
      <MenuItemLink
        key={to}
        to={`/${to}`}
        primaryText={title}
        leftIcon={<BaseIcon src={icon} className={cls.icon} />}
        onClick={onClick}
        sidebarIsOpen
        hasBadge={hasBadge}
      />
      {hasBadge && (
        <div
          style={{
            width: '15px',
            height: '15px',
            background: 'red',
            borderRadius: '50%',
            position: 'absolute',
            right: 33,
            top: 3,
          }}
        />
      )}
      {hasBadgeNum > 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '20px',
            height: '20px',
            background: 'red',
            borderRadius: '50%',
            position: 'absolute',
            right: 9,
            top: 3,
            fontSize: 12,
            color: '#fff',
          }}>
          {hasBadgeNum}
        </div>
      )}
    </div>
  );
  const { permissions } = usePermissions();

  const closeMenu = () => {
    SaveData({ openUserMenu: false });
  };

  const openSupport = () => {
    SaveData({ ChatItem: 'support', Chat: null });
    if (notif.support) SaveNotif({ support: null });
  };

  const handleToggleMenuBar = () => {
    document.getElementById('menuBar').style.display = 'none';
    document.getElementById('menuOpenIcon').style.display = 'flex';
    document.getElementById('amirsalam').style.marginLeft = '2px';
    // window.updateWidth();
    // window.updateHeadWidth();
    // window.syncScroll('amirbios');
    if (typeof window.updateWidth === 'function') {
      window.updateWidth();
    } else {
      console.warn('window.updateWidth is not defined in this page!');
    }

    if (typeof window.updateHeadWidth === 'function') {
      window.updateHeadWidth();
    } else {
      console.warn('window.updateHeadWidth is not defined in this page!');
    }

    if (typeof window.syncScroll === 'function') {
      window.syncScroll('amirbios');
    } else {
      console.warn('window.syncScroll is not defined in this page!');
    }
  };

  return (
    <div>
      {permissions === 'admin' && [
        <span className={cls.menuTuggle}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              if (isMobileDevice) {
                handleToggleMenuBar();
              } else {
                closeMenu();
              }
            }}>
            <ReactSVG src={Icons.menuHandler} />{' '}
          </IconButton>
        </span>,
        <MembersMenu
          leftIcon={<BaseIcon src={Icons.MembersNew} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <ContentMenu
          leftIcon={<BaseIcon src={Icons.ContentNew} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <ExportMenu
          leftIcon={<BaseIcon src={Icons.ExportNew} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <ScheduleAdmin
          leftIcon={<BaseIcon src={Icons.scheduleIcon} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <MenuItem
          onClick={() => closeMenu()}
          to="chat"
          title="Chat"
          icon={Icons.ChatNew}
        />,
        <MenuItem
          onClick={() => closeMenu()}
          to="finance"
          title="Finance"
          icon={Icons.FinanceNew}
        />,
        <IntegrationMenu
          leftIcon={
            <BaseIcon src={Icons.IntegrationNew} className={cls.icon} />
          }
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <FeaturesMenu
          leftIcon={<BaseIcon src={Icons.FeatureNew} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <AiMenu
          leftIcon={<BaseIcon src={Icons.AiNew} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        <LogsMenu
          leftIcon={<BaseIcon src={Icons.AlertsNew} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,

        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="AI"
        //   title="AI"
        //   icon={ChatBarIcons.AI}
        //   // hasBadge={AINotif && !pathname.includes('AI') ? true : false}
        // />,
        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="logs"
        //   title="Logs"
        //   icon={Icons.Logs}
        // />,
        <MenuItem
          onClick={() => closeMenu()}
          to="docs"
          title="Docs"
          icon={Icons.DocsNew}
        />,
      ]}
      {permissions === 'user' && [
        <span className={cls.menuTuggle}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => handleToggleMenuBar()}>
            <ReactSVG src={Icons.menuHandler} />{' '}
          </IconButton>
        </span>,
        <MenuItem
          onClick={() => closeMenu()}
          to="dashboard"
          title="Dashboard"
          icon={Icons.homeIcon}
        />,
        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="search"
        //   title="Smart Search"
        //   icon={Icons.Search}
        // />,
        <SearchMenu
          leftIcon={<BaseIcon src={Icons.searchIcon} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        // <RequestMenu
        //   leftIcon={<BaseIcon src={Icons.Request} className={cls.icon} />}
        //   onClick={onMenuClick}
        //   sidebarIsOpen
        // />,
        // <OrderMenu
        //   leftIcon={<BaseIcon src={Icons.Request} className={cls.icon} />}
        //   onClick={onMenuClick}
        //   sidebarIsOpen
        // />,
        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="inbox/offer"
        //   title="Inbox"
        //   hasBadgeNum={seenCount > 0 ? seenCount : 0}
        //   icon={Icons.inboxIcon}
        // />,
        <InboxMenu
          leftIcon={<BaseIcon src={Icons.inboxIcon} className={cls.icon} />}
          hasBadgeNum={seenCount}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        // <MenuItem
        //   onClick={() => closeMenu()}
        //   to="inventory"
        //   title="Inventory"
        //   icon={Icons.Inventory}
        // />,
        <InventoryMenu
          leftIcon={<BaseIcon src={Icons.inventoryIcon} className={cls.icon} />}
          onClick={onMenuClick}
          sidebarIsOpen
        />,
        enable_street_turn === '1' ? (
          <MenuItem
            onClick={() => closeMenu()}
            to="inHouse"
            title="In House"
            icon={Icons.inHouseIcon}
          />
        ) : (
          // <InHouseMenu
          //   leftIcon={
          //     <BaseIcon src={Icons.inHouseIcon} className={cls.icon} />
          //   }
          //   onClick={onMenuClick}
          //   sidebarIsOpen
          // />
          ''
        ),
        <MenuItem
          onClick={() => closeMenu()}
          to="report"
          title="Report"
          icon={Icons.reportIcon}
        />,
        mptyTrackingEnable === '1' ? (
          <TerminalMenu
            leftIcon={
              <BaseIcon src={Icons.scheduleIcon} className={cls.icon} />
            }
            onClick={onMenuClick}
            sidebarIsOpen
          />
        ) : (
          ''
        ),
      ]}
      {/* <span onClick={() => openSupport()}>support</span> */}
    </div>
  );
};
