import React, { useState } from 'react';
import { useNotify, Loading } from 'react-admin';
import qs from 'query-string';
import { Header, List, Map } from '@/components/Search';
import {
  getSavedSearches,
  getSearch,
  getSearchExport,
  Request,
  RequestExport,
} from '@/actions';
import { _get, genSize } from '@/actions/Helper';
import styles from './styles';
import { ParseInventoryList } from '@/actions/ParseData';
import SavedSearches from '../Search/SavedSearches';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { List as ListIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import SearchDetail from './index';

const parseData = (data) =>
  data.map((item) => ({
    //map
    name: _get(item, 'address.city', ''),
    pos: {
      lat: _get(item, 'address.lat', ''),
      lng: _get(item, 'address.long', ''),
    },

    // list
    id: _get(item, 'id', 0),
    size: genSize(_get(item, 'cabin.specifications', {})),
    pro: _get(item, 'cabin.company.name', ''),
    count: _get(item, 'count', 0),
    loc: _get(item, 'address.city', ''),
    date: _get(item, 'updated_at', ''),
    has_containers: _get(item, 'has_containers', ''),
    container_numbers: _get(item, 'container_numbers', ''),
    flip_available: _get(item, 'with_flip', ''),
    own_chases_count: _get(item, 'wanna_own', ''),
    pool_chases_count: _get(item, 'wanna_pool', ''),
    chase_type: _get(item, 'chase_type', ''),
    count_available: item.count_available
      ? _get(item, 'count_available', 0)
      : _get(item, 'count', 0),
    own_available: _get(item, 'own_available', 0),
    pool_available: _get(item, 'pool_available', 0),
  }));

export default ({ history }) => {
  const cls = styles();
  const notify = useNotify();
  const query = qs.parse(_get(history, 'location.search', null));
  const [showNotify, setShowNotify] = useState(false);
  const [savedSearches, setSavedSearches] = useState([]);
  const [showSavesSearches, setShowSavedSearches] = useState(false);
  const [state, setState] = React.useState({
    loading: true,
    DATA: [],
    RemNum: 0,
  });

  // const history = useHistory();

  const activeNotify = () => {
    alert('api not exist');
    // getSearch({ ...query, save_search: true })
    //   .then((res) => {
    //     setState({
    //       DATA: parseData(res),
    //       loading: false,
    //       RemNum: query.count,
    //     });
    //   })
    //   .then((res) => {
    //     notify('success', 'success');
    //     fetchSavedSearches();
    //   })
    //   .catch((err) => {
    //     if (err.includes('No Export has been found for this search')) {
    //       notify(
    //         'You will be notified when a matching container is added to the platform .',
    //         'success'
    //       );
    //       fetchSavedSearches();
    //       setTimeout(() => {
    //         history.push('/search');
    //       }, 2000);
    //     } else {
    //       notify(err, 'warning');
    //     }
    //   });
  };

  const fetchSavedSearches = () => {
    // getSavedSearches().then((data) => {
    //   setSavedSearches(ParseInventoryList(data));
    // });
  };

  React.useEffect(() => {
    // fetchSavedSearches();
  }, []);

  React.useEffect(() => {
    if (query)
      getSearchExport(query)
        .then((res) => {
          setState({
            DATA: parseData(res),
            loading: false,
            RemNum: query.count,
          });
        })
        .catch((err) => {
          notify(err, 'warning');
          setShowNotify(true);
          setState({
            DATA: [],
            loading: false,
          });
        });
  }, [history, notify]);

  const req = ({ id, count_available, chase_type }) => {
    setState({ ...state, loading: true });
    if (count_available > state.RemNum) count_available = state.RemNum;

    RequestExport({
      export_id: id,
      count: count_available,
      chase_type,
    })
      .then((res) => {
        let RemNum = state.RemNum - count_available;
        if (RemNum < 0) RemNum = 0;
        let DATA = state.DATA.filter((i) => i.id !== id);
        setState({ DATA, RemNum, loading: false });
        notify(res);
        if (RemNum === 0) {
          history.push('/requests');
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setState({ ...state, loading: false });
      });
  };

  const smart = () => {
    alert('api not exist');
    // getSearch({ ...query, t: 'smart' })
    //   .then(() => {
    //     notify('Smart Search created.');
    //     history.push('/requests');
    //   })
    //   .catch((err) => notify(err, 'warning'));
  };

  const handleChangeNmuber = (number, index) => {
    setState((prev) => {
      return {
        ...prev,
        DATA: [
          ...prev.DATA.slice(0, index),
          {
            ...prev.DATA[index],
            count: String(number),
          },
          ...prev.DATA.slice(index + 1),
        ],
      };
    });
  };

  return (
    <SearchDetail
      showSavesSearches={showSavesSearches}
      savedSearches={savedSearches}
      setShowSavedSearches={setShowSavedSearches}
      notify={notify}
      fetchSavedSearches={fetchSavedSearches}
      state={state}
      query={query}
      handleChangeNmuber={handleChangeNmuber}
      showNotify={showNotify}
      activeNotify={activeNotify}
      smart={smart}
      req={req}
      openReserve
      isExport={true}
    />
  );
};
