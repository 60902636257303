import { makeStyles } from '@material-ui/core/styles';
import { Icons } from '@/assets';
export default makeStyles(({ breakpoints: BP }) => ({
  pageContent: {
    display: 'flex',
  },
  content: {
    minWidth: '96.5%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 20px',
    overflow: 'auto',
    transition: 'all ease 0.3s',
  },
  profileTabs: {
    background: '#ffffff',
    margin: '0 20px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    justifyContent: 'space-between',
    borderRadius: '5px',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    '@media (max-width:763px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  profileTabsBtn: {
    border: '1px solid #e5e5e5',
    color: '#777575',
    background: '#e5e5e5',
    width: 100,
  },
  activeProfileTabsBtn: {
    background: '#f8b100',
    border: '1px solid #f8b100',
    color: '#ffffff',
    width: 100,
    '&:hover': {
      background: '#f8b100',
    },
  },
  profileBtnsSpan: {
    display: 'flex',
    gap: '10px',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '14px 4px 5px',
    [BP.down('sm')]: {
      flexDirection: 'column',
      margin: '10px',
    },
  },

  containersTableContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    flex: '1',
  },
  closeModal: {
    textAlign: 'end',
    '& img': {
      width: '10px',
      height: '10px',
      cursor: 'pointer',
    },
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000000',
    margin: '0 0 20px 0',
  },
  updateContainersBtn: {
    background: '#007fff',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '20px 15px 0 auto',
    display: 'block',
  },
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    // zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999999999999999999999',
  },

  popUpCard: {
    position: 'relative',
    padding: '30px 5px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    minHeight: '200px',
    maxWidth: '80%',
    animation: 'zoomIn 0.7s',
    height: 'auto',
    minWidth: 400,
  },

  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },

  xlSpan: {
    display: 'flex',
    margin: '15px 0',
    borderRadius: '4px',
    fontSize: '0.875rem',
    lineHeight: '1.75rem',
    color: '#298AE2',
    display: 'flex',
    alignItems: 'center',
  },

  filtersfooter: {
    display: 'flex',
  },
  hiddenFiltersDiv: {
    display: 'none',
    marginTop: '10px',
  },
  moreLink: {
    margin: '10px 0px',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px !important',
    },
  },
  terminalLargView: {
    ['@media (max-width:1440px)']: {
      display: 'none',
    },
  },
  hidsection: {
    ['@media (min-width:1440px)']: {
      display: 'none',
    },
  },
  card: {
    padding: '14px 35px 17px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    transition: 'all ease 0.5s',
    overflow: 'visible',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 0,
    marginRight: '4px',
  },
  arrowSpan: {
    display: 'flex',
    gap: '15px',
    cursor: 'pointer',
    '&:hover': {
      color: '#f8b100',
    },
  },

  list: {
    margin: '20px 0 0px',
    width: '100%',
    minWidth: 1300,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [BP.down('sm')]: {
      marginTop: 15,
      marginBottom: 10,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ['@media (max-width:1440px)']: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    ['@media (max-width:1440px)']: {
      marginTop: 10,
    },
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#303030',
  },
  tabsRow: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  titleView: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#303030',
    padding: 13,
    marginLeft: 16,
  },

  titleBlue: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1,
    color: '#007fff',
    margin: '0 20px 0 10px',
    cursor: 'pointer',
  },

  import: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    cursor: 'pointer',
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1,
    color: '#44a570',
    '& > svg': {
      height: 28,
      width: 28,
    },
  },

  printIcon: {
    height: 28,
    width: 28,
    fill: '#007fff',
    marginLeft: 10,
    cursor: 'pointer',
  },
  select: {
    width: '100%',
    fontFamily: 'Averta Light',
    '& > div': {
      // border: 'solid 1px #cccccc',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    // border: 'solid 1px #cccccc',
    padding: '0 10px',
    '& input': {
      width: '100%',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  loc: {
    '& svg': {
      fill: '#f8b100',
    },

    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'solid 1px #cccccc',
    },
  },
  ml15: {
    marginLeft: 15,
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #cccccc',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  text: {
    fontFamily: 'Averta Light',
    fontSize: 13,
    fontWeight: 300,
    lineHeight: 1.5,
    color: '#303030',
  },
  but: {
    width: 77,
    height: 27,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
  },
  red: {
    backgroundColor: '#e4464b !important',
  },
  blue: {
    backgroundColor: '#007fff !important',
    marginLeft: 10,
  },
  rowRound: {
    width: 180,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 20,
    backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    marginLeft: 25,
  },
  text2: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease-in',
  },
  tableBody: {
    boxShadow: '0 0 10px #00000021',
  },
  tableRowLeft: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    height: '100%',
    // boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    // borderBottom: '1px solid #979797',
    // backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    borderTop: '1px solid #e9e9e9',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',
    // border: '1px solid #e9e9e9',
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },

  tableRow: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    height: '100%',
    boxShadow: '0px 0px 2px 0 rgb(0 0 0 / 60%)',
    // borderBottom: '1px solid #979797',
    // backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',
    // border: '1px solid #e9e9e9',
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
    '&:hover': {
      background: '#f2f2f2',
    },
  },
  tableRowCount: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    height: 'auto',
    // border: '1px solid #e9e9e9',
    // backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',

    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },

  thead: {
    display: 'flex',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    background: '#f1f1f1',
    textAlign: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 5px 0 rgba(0, 0, 0, 0.2)',
    [BP.up('lg')]: {
      fontSize: 13,
    },
    '& div': {
      whiteSpace: 'nowrap',
    },
  },
  arrowTop: {
    marginLeft: '-8px',
  },
  arrowDown: {
    marginLeft: '-8px',
  },

  tbody: {
    // display: 'flex',
    // flexDirection: 'column',
    // width:'fit-content'
  },
  resText: {
    color: '#007fff',
  },
  bigText: {
    lineHeight: 2.7,
    color: '#303030',
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 3.86,
    color: '#43424b',
  },
  editIcon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  openIcon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  replyIcon: {
    height: 20,
    width: 20,
    fill: '#000000',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  delIcon: {
    height: 20,
    width: 20,
    fill: '#e4464b',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  loading: {
    height: '100%',
    margin: 0,
  },
  tabActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  multiRowTd: {
    display: 'flex',
    flexDirection: 'row',
  },
  shifttdmultiRow: {
    display: 'flex',
    justifyContent: 'space-evenly',
    // borderLeft: '1px solid #e9e9e9',
    // borderRight: '1px solid #e9e9e9',
    alignItems: 'center',
    minWidth: 90,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  shifttdd: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    height: 61,
    alignItems: 'center',
    minWidth: 90,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  tableRowMulti: {
    minWidth: 'fit-content',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    height: 'max-content',
    // backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    borderBottom: '0.5px solid #e9e9e9',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: '0px 5px',
    },
  },
  after_ai_partsRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  after_ai_partRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  listContainer: {
    overflow: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    transition: 'all ease 0.3s',
    '&::-webkit-scrollbar-track': {
      transition: 'all 1s ease',
      background: '#bebebe',
    },
    '&::-webkit-scrollbar-thumb': {
      transition: 'all 1s ease',
      borderRadius: '3px',
      background: '#494949',
    },
    '&::-webkit-scrollbar': {
      transition: 'all 1s ease',
      width: '8px',
    },
    // '&:hover': {
    //   transition: 'all 1s ease',
    //   '&::-webkit-scrollbar-track': {
    //     background: '#bebebe',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     borderRadius: '3px',
    //     background: '#494949',
    //   },
    // },
  },
  checkbox: {
    '& svg': {
      fill: '#f8b100',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  textInput: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #cccccc',
    marginTop: 5,
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ShareEmail: {
    color: '#ffffff',
    background: '#007fff',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#ffffff',
      background: '#007fff',
    },
  },
  suggestionsContainer: {
    borderBottom: '1px solid rgb(218,220,224)',
    paddingBottom: '10px',
  },
  copyActions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '6px',
    '& button': {
      padding: 6,
    },
  },
  copySection: {
    flexDirection: 'column',
    borderRadius: '4px',
    background: '#d5d5d5',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 10px',
    '& div': {
      flex: '1',
      paddingLeft: '10px',
    },
  },
  shareLinkContainer: {
    flex: '1',
  },
  modalBox: {
    width: '400px',
    minHeight: '397px',
    background: '#FFFFFF',
    borderRadius: '5px',
    padding: '10px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  modalBoxExport: {
    width: '800px',
    minHeight: '397px',
    background: '#FFFFFF',
    borderRadius: '5px',
    padding: '10px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  shareLinkBox: {
    position: 'fixed',
    bottom: 15,
    left: 15,
    minHeight: '250px !important',
    zIndex: '9999',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
  },
  containersTableContainer: {
    maxHeight: '300px',
    overflow: 'auto',
    flex: '1',
  },
  closeModal: {
    textAlign: 'end',
    '& img': {
      width: '10px',
      height: '10px',
      cursor: 'pointer',
    },
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#000000',
    margin: '0 0 20px 0',
  },
  editContainerInput: {
    background: 'transparent',
    padding: '4px 2px 5px 8px',
    borderRadius: '5px',
    border: '1px solid black',
  },
  updateContainersBtnSpan: {
    display: 'flex',
    justifyContent: 'space-between',
    justifyContent: 'flex-end',
  },
  updateContainersBtnClose: {
    background: '#007fff',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '15px',
    display: 'block',
  },
  updateContainersBtnSave: {
    background: '#3c845d',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    margin: '15px',
    display: 'block',
  },
  containerTable: {
    width: '100%',
    '& tr': {
      display: 'flex',
    },
    '& th': {
      color: '#777575',
      fontSize: '13px',
      padding: '10px',
    },
    '& td': {
      padding: '0 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000000',
      fontSize: '13px',
      height: '27px',
      borderBottom: '1px solid #dedede',
      '& label': {
        margin: 0,
      },
      '& svg': {
        fill: '#000000',
      },
    },
  },
  containerChecBox: {
    accentColor: '#000000',
  },
  chassisNameContainer: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 25,
      height: 25,
      margin: '0 10px',
    },
  },

  inviteIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0',
    borderBottom: '1px solid rgb(218,220,224)',
  },
  inviteIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    fontWeight: 'bold',
    borderBottom: '2px solid #007fff',
    padding: '10px 6px',
    color: '#007fff',
  },
  email: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  removeEmail: {
    width: 28,
    height: 28,
  },
  emailInputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 5,
    borderBottom: '1px solid rgb(218,220,224)',
    paddingBottom: '8px',
    '& input': {
      flex: '1',
    },
  },
  emailBadge: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgb(218,220,224)',
    padding: '1.5px',
    borderRadius: '16px',
    gap: 6,
    width: 'fit-content',
  },
  emailAvatar: {
    width: 28,
    height: 28,
  },
  removeEmailIcon: {
    width: '1rem',
  },
  suggestion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 0',
  },
  suggestionInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  suggestionAvatar: {
    width: 30,
    height: 30,
  },
  emailCheckBox: {
    '& svg': {
      fill: '#007fff',
    },
    '& hover': {
      background: '#007fff45',
    },
  },
  counttd: {
    display: 'flex',
    justifyContent: 'space-evenly',
    // borderLeft: '1px solid #e9e9e9',
    // borderRight: '1px solid #e9e9e9',
    // height: "61px",
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  shifttd: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    minHeight: '61px',
    alignItems: 'center',
    minWidth: 90,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  shifttdEnd: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #393939',
    minHeight: 61,
    alignItems: 'center',
    minWidth: 90,
    flexDirection: 'column',
    overflow: 'hidden',
  },

  tableTopMargin: {
    marginTop: 60,
  },
  terminalLink: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'auto',
    color: '#43424b',
  },
  spaceEmpty: {
    width: '50px',
  },
  containerActions: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3px',
    gap: '15px',
    '@media (max-width:600px)': {
      flexDirection: 'column',
      gap: '20px',
    },
  },
  containerRowRound: {
    overflow: 'hidden',
    width: '100%',
    minWidth: 160,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    backgroundColor: '#d7d7d7',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    cursor: 'pointer',
    ['@media (max-width:450px)']: {
      marginLeft: 0,
      marginTop: 5,
      width: '100%',
    },
  },
  chassisText: {
    textAlign: 'center',
    display: 'flex',
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 5,
    flex: '1',
    border: '1px solid #ffffff',
    '&:hover': {
      background: '#edecec',
    },
    '&:first-child': {
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  activeChassis: {
    backgroundColor: '#f8b100 !important',
    color: '#000 !important',
    '&:hover': {
      background: '#ffd616 !important',
    },
  },
  textInput: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 35,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #cccccc',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
    // overflow: 'hidden',
  },
  containerBadge: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    width: '50px',
    minWidth: '50px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'solid 1px #cccccc',
    '& input': {
      fontFamily: 'Averta Light',
      padding: '5px 10px',
    },
  },

  fix: {
    background: '#f0f0f0',
    position: 'fixed',
    top: 65,
    overflowY: 'hidden' /* Hide vertical scrollbar */,
    overflowX: 'scroll' /* Hide horizontal scrollbar */,
    scrollbarWidth: 'none',
    boxShadow: '0 0 8px 0px #00000066',
  },
  tableTitleSorting: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    height: 61,
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    justifyContent: 'center',
    '&&:hover': {
      background: '#f0f0f0',
      transition: 'all ease 0.3s',
    },
  },
  tableTitleTop: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 61,
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    justifyContent: 'center',
    marginBottom: '-2px',
    '&&:hover': {
      background: '#f0f0f0',
      transition: 'all ease 0.3s',
    },
  },
  tableTitleTop1: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 61,
    borderRight: '1px solid #393939',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    justifyContent: 'center',
    marginBottom: '-2px',
    '&&:hover': {
      background: '#f0f0f0',
      transition: 'all ease 0.3s',
    },
  },
  tableTitleTopEnd: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #393939',
    height: 61,
    minWidth: '88px',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    justifyContent: 'center',
    '&&:hover': {
      background: '#f0f0f0',
      transition: 'all ease 0.3s',
    },
  },

  noClick: {
    background: '#007fff',
    width: '77px',
    position: 'absolute',
    height: 'inherit',
  },
  tableTitleSortingThis: {
    display: 'flex',
    justifyContent: 'space-evenly',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    height: 61,
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&&:hover': {
      background: '#f0f0f0',
      transition: 'all ease 0.3s',
    },
  },

  thdate: {
    display: 'flex',
    justifyContent: 'center',
    height: 60,
    alignItems: 'center',
  },
  open: {
    background: '#0080001f',
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '8px',
    padding: '0px 11px',
    margin: '18px 0px',
    justifyContent: 'center',
    fontSize: 9,
  },
  WhatsappMessageDiv: {
    maxHeight: '300px',
    overflow: 'auto',
    flex: '1',
    background: '#d8d8d8',
    padding: '15px',
    display: 'flex',
    borderRadius: '5px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fromUserDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
  wpMessageContent: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 5px',
  },
  iconPerson: {
    '& > svg': {
      width: '30px',
      height: '30px',
      background: '#e0e0e0',
      borderRadius: '50%',
      padding: '6px',
    },
  },
  // close: {
  //   background: '#ff00001c',
  //   height: '-webkit-fill-available',
  //   display: 'flex',
  //   alignItems: 'center',
  //   width: 'fit-content',
  //   borderRadius: '8px',
  //   padding: '0px 8px',
  //   margin: '18px 0px',
  //   justifyContent: 'center',
  //   fontSize: 9,
  // },
  nullStatus: {
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '8px',
    padding: '0px 20px',
    margin: '18px 0px',
    justifyContent: 'center',
  },
  tableTh: {
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '40px',
    // border: "1px solid #afafaf",
    borderBottom: 'none',
    borderTop: 'none',
    justifyContent: 'center',
  },
  tableDateShowToday: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  tableDateShow: {
    display: 'flex',
    color: '#a1a1a1',
    flexDirection: 'column',
    padding: '8px 12px 8px !important',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    fontSize: '12px',
    gap: 1,
  },
  terminalsNameLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    ['&:hover']: {
      ['.makeStyles-tooltiptext-175']: {
        visibility: 'visible',
      },
    },
  },
  tooltiptext: {
    visibility: 'hidden',
    width: 120,
    backgroundColor: 'black',
    color: '#fff',
    textAlign: 'center',
    borderRadius: 6,
    padding: '5px 0',

    /* Position the tooltip */
    position: 'absolute',
    zIndex: 1,
  },

  dayName: {
    color: '#a1a1a1',
  },
  dayNameToday: {
    color: '#f8b100',
  },
  dayNumberNormal: {
    fontSize: 19,
    padding: '7px 9px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayNumberToday: {
    minWidth: 19,
    fontSize: 19,
    padding: '7px 9px',
    background: '#f8b100',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  find: {
    width: 133,
    height: 40.3,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Bold',
    fontSize: 21,
    fontWeight: 'bold',
    lineHeight: 1.86,
    color: '#303030',
    padding: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd616',
    },
    [BP.down('xs')]: {
      fontSize: '1rem',
      width: '33vw',
      height: 30,
      '& > .MuiButton-label': {
        height: 30,
      },
    },
  },
  findIcon: {
    width: 28,
    height: 40,
    fill: '#303030',
  },
  searchbtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
