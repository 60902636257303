import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  profileCont: {
    display: 'flex',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  userName: {
    fontSize: 15,
    fontWeight: 300,
    letterSpacing: 'normal',
    color: '#ffffff',
    padding: 5,
    fontFamily: 'Averta',
    textTransform: 'none',
  },
  bellIcon: {
    position: 'relative',
    padding: '6px 7px',
    minWidth: 'auto',
    borderRadius: '50px',
    border: '1px solid #F2AC4A !important',
    // margin: '0 15px',
    '& img': {
      height: 27,
      '@media (max-width:1160px)': {
        height: '22px',
      },
    },
    animation: ({ notif }) =>
      notif.chat || notif.order || notif.request ? 'heartBeat 1s' : '',
    '@media (max-width:1160px)': {
      margin: '10px',
    },
    '@media (max-width:800px)': {
      margin: '5px',
    },
  },
  paper: {
    top: '75px !important',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    borderRadius: 10,
    overflow: 'visible',
    '&::before': {
      position: 'absolute',
      right: 16,
      top: -13,
      width: 25,
      height: 25,
      backgroundColor: '#fff',
      content: '""',
      transform: 'rotate(-45deg)',
      borderRadius: '0 5px',
      zIndex: 0,
    },
  },
  notifIcon: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    fontSize: '14px',
    fontFamily: 'Averta',
    color: '#ffffff',
  },
  list: {
    width: 310,
    padding: '0 !important',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 15px',
    margin: '10px 0',
    outline: 'none',
    maxHeight: 400,
  },
  loading: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
  redDot: {
    position: 'absolute',
    top: 10,
    right: 9,
    width: 9,
    height: 9,
    borderRadius: 10,
    backgroundColor: '#e4464b',
    display: ({ notif }) =>
      notif.chat || notif.order || notif.request || notif['whatsapp-messages']
        ? 'block'
        : 'none',
  },
  empty: {
    fontSize: 16,
    color: '#303030',
    fontFamily: 'Averta Light',
  },
  but: {
    fontSize: 15,
    color: '#303030',
    fontFamily: 'Averta Light',
    textAlign: 'center',
    display: 'block',
    marginBottom: 5,
  },
});
