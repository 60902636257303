import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  profileCont: {
    display: 'flex',
  },
  avatar: {
    width: 30,
    height: 30,
    // marginRight: 8,
    backgroundColor: '#303030',
  },
  userName: {
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 'normal',
    color: '#525866',
    padding: 5,
    fontFamily: 'Averta',
    textTransform: 'none',
  },
  userNameDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    padding: '6px 10px 6px 6px',
    borderRadius: '50px',
    border: '1px solid #F2AC4A !important',
  },
  userNameSpan: {
    display: 'flex',
    alignItems: 'center',
    color: '#525866',
    gap: 10,
  },
  name: {
    color: '#525866',

    '@media (max-width:800px)': {
      display: 'none',
    },
  },
});
