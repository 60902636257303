import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  containerTime: {
    position: 'relative',
    width: 'fit-content',
  },
  inputTime: {
    padding: '9px 0px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    textAlign: 'center',
    width: '150px',
  },
  popupTime: {
    position: 'absolute',
    top: '0px',
    left: '165px',
    borderRadius: '8px',
    background: '#fff',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    border: '1px solid #FFAD00',
    padding: '10px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  sectionTime: {
    width: 'max-Content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 30,
    padding: '3px 12px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ddd',
  },
  arrowSpan: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelTime: {
    display: 'block',
    fontSize: '14px',
    marginBottom: '5px',
    width: 30,
    color: '#999999',
  },
  timePickerTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    // padding: '8px',
    borderRadius: '8px',
  },
  buttonTime: {
    background: 'transparent',
    border: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    padding: 4,
    // rotate: '90deg',
  },
  amPmButtonTime: {
    borderRadius: '6px',
    border: 'none',
    fontSize: '14px',
    padding: '6px',
    cursor: 'pointer',
  },
  hourTextTime: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#999999',
  },
  setTimeButtonTime: {
    marginTop: '10px',
    padding: '8px 20px',
    border: 'none',
    background: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    fontSize: '14px',
    cursor: 'pointer',
  },
}));
