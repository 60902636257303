import React, { useEffect } from 'react';
import { useNotify, Loading, Button } from 'react-admin';
import { GetAppRounded } from '@material-ui/icons';
import { Filter } from '@/components/Global';
import { ParseInventoryList, ParseSmartMatchList } from '@/actions/ParseData';
import { noScroll, removeNoScroll } from '@/actions/Helper';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { fDate } from '@/actions/Helper';
import {
  getInventory,
  delInventory,
  upInventory,
  importInventory,
  exportInventory,
  updateInventoriContainers,
  getSmartMatchList,
  getTableList,
  setUserSettings,
  getUserSettings,
} from '@/actions';
import Add from './Add';
import List from './List';
import Edit from './Edit';
import styles from './styles';
import Pagination from '@/components/pagination';
import clsx from 'clsx';
import { format } from 'date-fns';

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [state, setState] = React.useState({
    edit: null,
    file: null,
  });
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [],
  });
  const [loading, setLoading] = React.useState(true);
  const [loadingSmartMatch, setLoadingSmartMatch] = React.useState(false);
  const [DATA, setData] = React.useState([]);
  const [smartMatchStatus, setSmartMatchStatus] = React.useState();
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });

  const [EmptiesTableTheadTitle, setEmptiesTableTheadTitle] = React.useState(
    []
  );
  const [ExportsTableTheadTitle, setExportsTableTheadTitle] = React.useState(
    []
  );

  const [form, setFormData] = React.useState({
    specification_id: null,
    ssl_id: null,
    loc: null,
    //numberMode
    prefrence: false,
  });

  useEffect(() => {
    getUserSettings('smartMatch')
      .then((res) => {
        const resData = res.data.settings.data;

        if (resData === null || resData === undefined || resData === false) {
          setSmartMatchStatus(false);
        } else if (resData === true) {
          setSmartMatchStatus(true);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  }, []);

  const setForm = (it, value) => setFormData({ ...form, [it]: value });

  const filterWithApi = (filterData) => {
    let form = {};
    if (filterData.lfd) {
      form.empty_lfd = fDate(filterData.lfd);
    }
    if (filterData.chassis.own) {
      form.own_chassis_type = filterData.chassis.own;
    }
    if (filterData.chassis.pool) {
      form.pool_chassis_type = filterData.chassis.pool;
    }
    if (filterData.loc) {
      form.empty_location = filterData.loc.label;
    }
    if (filterData.locex) {
      form.export_location = filterData.locex.label;
    }
    if (filterData.size) {
      form.specification_id = filterData.size.value;
    }
    if (filterData.ssl) {
      form.ssl = filterData.ssl.value;
    }
    if (filterData.erd) {
      form.export_erd = fDate(filterData.erd);
    }
    if (filterData.cutoff) {
      form.export_cutoff = fDate(filterData.cutoff);
    }
    form.page = pagination.currentPage;

    setFormData(form);
  };

  const getStreetTurnTableHeadList = () => {
    setLoading(true);

    getTableList('streetTurnEmptiesTable')
      .then((res) => {
        if (res.data?.settings?.data) {
          setEmptiesTableTheadTitle(res.data?.settings?.data);
        } else {
          setEmptiesTableTheadTitle([
            {
              label: 'Container',
              flex: 1,
              show: true,
              object: 'name',
            },
            {
              label: 'Chassis',
              flex: 1,
              show: true,
              object: 'chases',
            },
            {
              label: 'Gated Out',
              flex: 1,
              show: false,
              object: 'gated_out',
            },
            {
              label: 'Gated In',
              flex: 1,
              show: false,
              object: 'gated_in',
            },
            { label: 'Empty Location', flex: 1.5, show: true, object: 'loc' },
            {
              label: 'Return Apt',
              flex: 1,
              show: true,
              object: 'return_appointment',
            },
            {
              label: 'Return Location',
              flex: 1.5,
              show: true,
              object: 'return_terminals',
            },
            { label: 'Dual Terminal', flex: 1, show: true, object: 'dual' },
            { label: 'LFD', flex: 1, show: true, object: 'lfd' },
            {
              label: 'Est. PerDiem',
              flex: 1,
              show: true,
              object: 'est_per_diem',
            },
          ]);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });

    getTableList('streetTurnExportsTable')
      .then((res) => {
        if (res.data?.settings?.data) {
          setExportsTableTheadTitle(res.data?.settings?.data);
        } else {
          setExportsTableTheadTitle([
            {
              label: 'Booking',
              flex: 1.5,
              show: true,
              object: 'booking',
            },
            {
              label: 'Container',
              flex: 1.3,
              show: false,
              object: 'name',
            },
            {
              label: 'Empty Released',
              flex: 1.9,
              show: false,
              object: 'empty_released',
            },
            {
              label: 'Gated In',
              flex: 1.2,
              show: false,
              object: 'gated_in',
            },
            {
              label: 'Vessel',
              flex: 1,
              show: false,
              object: 'vessel',
            },
            {
              label: 'Voyage',
              flex: 1,
              show: false,
              object: 'voyage',
            },
            {
              label: 'Terminal Apt',
              flex: 1,
              show: false,
              object: 'terminal_apointment',
            },
            {
              label: 'ERD',
              flex: 1,
              show: true,
              object: 'erd',
            },
            {
              label: 'Cutoff',
              flex: 1,
              show: true,
              object: 'cutoff',
            },
            {
              label: 'Pickup Location',
              flex: 1.8,
              show: true,
              object: 'pickup_location',
            },
            {
              label: 'Terminal Return',
              flex: 1.9,
              show: false,
              object: 'terminal_return',
            },
            {
              label: 'Vessel Departure',
              flex: 1.8,
              show: false,
              object: 'vessel_departure',
            },
          ]);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  React.useEffect(() => {
    getStreetTurnTableHeadList();
  }, []);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 35,
      height: 18,
      padding: 0,
      overflow: 'visible',
      margin: theme.spacing(0.8),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#007AFF',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#007AFF',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 26,
      // border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: '#d5d5d5',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const toggleShow = () => {
    setLoadingSmartMatch(true);

    if (smartMatchStatus === true) {
      const req = {
        key: 'smartMatch',
        data: false,
      };

      setUserSettings(req)
        .then((res) => {
          setTimeout(function () {
            setLoadingSmartMatch(false);
          }, 1000);
        })
        .catch((err) => {
          notify(err, 'warning');
          setLoading(false);
        });

      setSmartMatchStatus(false);
    } else {
      const req = {
        key: 'smartMatch',
        data: true,
      };

      setUserSettings(req)
        .then((res) => {
          setTimeout(function () {
            setLoadingSmartMatch(false);
          }, 1000);
        })
        .catch((err) => {
          notify(err, 'warning');
          setLoading(false);
        });

      setSmartMatchStatus(true);
    }
  };

  const getInventoryMatchList = () => {
    // setLoading(true);
    getSmartMatchList(
      form
        ? { ...form, page: pagination.currentPage }
        : { page: pagination.currentPage }
    )
      .then((res) => {
        // let DATA = ParseInventoryList(res.data);
        let DATA = res;
        // let DATATableOne = ParseSmartMatchList(res);
        let pages = [];
        for (let i = 1; i <= res.last_page; i++) {
          pages.push(i);
        }
        setPagination((prev) => {
          return {
            ...prev,
            pages,
          };
        });
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getInventoryMatchList();
  }, [pagination.currentPage, form]);

  React.useEffect(() => {
    if (state.file) {
      setLoading(true);
      importInventory(state.file)
        .then((res) => {
          let DATA = ParseInventoryList(res);
          setData(DATA);
          setFilter((f) => ({ ...f, DATA }));
          setLoading(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setData([]);
          setFilter((f) => ({ ...f, DATA: [] }));
          setLoading(false);
        });
    }
  }, [state.file, notify]);

  const Del = (id) =>
    delInventory(id)
      .then((msg) => {
        notify(msg);
        getInventory()
          .then((res) => {
            let DATA = ParseInventoryList(res.data);
            setData(DATA);
            setFilter({ ...filter, DATA });
            setLoading(false);
          })
          .catch((err) => notify(err, 'warning'));
      })
      .catch((err) => notify(err, 'warning'));

  const openPopUp = (item) => {
    noScroll();
    setState({ ...state, edit: item });
  };

  const Update = (form, inventory_id, containers) => {
    for (let el in form) {
      if (!form[el]) {
        notify('Can not add, Please check fields.', 'warning');
        return;
      }
    }

    return upInventory(form)
      .then((res) => {
        updateInventoriContainers(inventory_id, { containers }).then(() => {
          notify('Updated Successfully.');
          getInventoryMatchList();
          setFilter({ ...filter, DATA });
          closePopup();
        });
      })
      .catch((err) => notify(err, 'warning'));
  };

  const closePopup = () => {
    removeNoScroll();
    setState({ ...state, edit: null });
  };

  const handleExport = () => {
    setLoading(true);
    exportInventory()
      .then((res) => {
        setLoading(false);
        window.open(res, '_blank');
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  return (
    <div id="inventoryStreetTurn" className={cls.content}>
      {state.edit && (
        <Edit data={state.edit} Close={closePopup} Update={Update} />
      )}

      <Filter
        title=""
        filter={filter}
        setFilter={setFilter}
        filterWithApi={filterWithApi}
        DATA={DATA}
        type={'streetTurn'}>
        <div className={cls.rowCenter}>
          <h2 className={cls.title}>Smart Match</h2>
          <IOSSwitch
            checked={smartMatchStatus}
            onChange={(ev) => toggleShow()}
            name="checkedShow"
          />
        </div>
      </Filter>

      {loading ? (
        <Loading />
      ) : (
        <List
          DATA={filter.DATA}
          EmptiesTableTheadTitle={EmptiesTableTheadTitle}
          ExportsTableTheadTitle={ExportsTableTheadTitle}
          Del={Del}
          Edit={openPopUp}
          loadingSmartMatch={loadingSmartMatch}
          smartMatchStatus={smartMatchStatus}
          getInventoryMatchList={getInventoryMatchList}
          currentPage={pagination.currentPage}
        />
      )}
      {/* <Pagination pagination={pagination} setPagination={setPagination} /> */}
    </div>
  );
};
