import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import { TerminalsIcon } from '@/assets';
import {
  Tooltip,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import DateInput from '@/components/DateInput';
import clsx from 'clsx';
import SelectBox from 'react-select';
import { adminChangeGateShiftsStatus, getGateSchedule } from '@/actions';
import { useNotify, Loading } from 'react-admin';

export default ({ TerminalsList, getInventoryList, PortsList }) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const [selectedPort, setSelectedPort] = useState('All');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sizeSelectOptions, setSizeSelectOptions] = useState(null);
  const [selectedSIZE, setSelectedSize] = useState(null);
  const [Terminals, setGatelist] = React.useState([]);

  const todayy = new Date();
  const formattedSelectedDate = formatDate(selectedDate);
  let keys = Object.keys(TerminalsList);

  const [selectedUuid, setSelectedUuid] = useState(null);

  const changeDataMode = (uuid) => {
    setSelectedUuid(uuid);

    // notify('Click Kardd!!!😳😳😳');
  };

  useEffect(() => {
    setLoading(true);
    if (keys.length > 0) {
      setGatelist(TerminalsList);
      setLoading(false);
    }
  }, [TerminalsList]);

  const portSelectOptions = Object.keys(PortsList).map((i, index) => ({
    label: PortsList[index].name,
    slug: PortsList[index].slug,
    value: index + 1,
  }));

  const notify = useNotify();

  const getGateScheduleList = (selectedPort, selectedDate) => {
    setLoading(true);
    const dateWeekDays = getCurrentWeekDates(selectedDate);
    const firstWeekDay = formatDate(dateWeekDays[0]);
    const endWeekDay = formatDate(dateWeekDays[6]);
    const formattedFirstWeekDay = `${firstWeekDay.year}/${firstWeekDay.monthNumber}/${firstWeekDay.day}`;
    const formattedEndWeekDay = `${endWeekDay.year}/${endWeekDay.monthNumber}/${endWeekDay.day}`;
    getGateSchedule(
      formattedFirstWeekDay,
      formattedEndWeekDay,
      selectedPort.slug
    )
      .then((res) => {
        setGatelist(res.data.schedule);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedPort || selectedDate) {
      getGateScheduleList(selectedPort, selectedDate);
    }
  }, [selectedPort, selectedDate]);

  const setForm = (it, val) => setFormData({ ...form, [it]: val });

  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
  });

  const handleChangeStatusShifts = (
    terminal_id,
    date,
    shift_column,
    shift_value,
    idx,
    shift_valueNormal,
    indexTerminal
  ) => {
    adminChangeGateShiftsStatus(terminal_id, date, shift_column, shift_value)
      .then((res) => {
        const dateKey = date;
        const shiftColumn = shift_column;
        // console.log('apiresss', res.data.gateSchedule?.[shiftColumn]);
        if (res) {
          setGatelist((prevTerminals) => {
            setSelectedUuid(null);
            return {
              ...prevTerminals,
              [idx]: {
                ...prevTerminals[idx],
                gate_schedules: {
                  ...prevTerminals[idx].gate_schedules,
                  [dateKey]: {
                    ...prevTerminals[idx].gate_schedules[dateKey],
                    [shift_column]: res.data.gateSchedule?.[shift_column], // Update the specific shift_column
                  },
                },
              },
            };
          });
          console.log(
            'res.data.gateSchedule?.[shiftColumn]',
            res.data.gateSchedule?.[shiftColumn]
          );

          const elementId = Terminals[idx].uuid + date + shift_column;
          const element = document.getElementById(elementId);

          if (!element) {
            console.error(`Element not found: ${elementId}`);
            return;
          }

          // Clear existing classes
          element.classList.remove(
            cls.closeVar,
            cls.openVar,
            cls.nullStatusAdminVar
          );

          // Apply new class based on shift_value
          switch (shift_value) {
            case 0:
              element.classList.add(cls.closeVar);
              break;
            case 1:
              element.classList.add(cls.openVar);
              break;
            case null:
              element.classList.add(cls.nullStatusAdminVar);
              break;
            case 2:
              element.classList.add(
                res.data.gateSchedule?.[shiftColumn] === 0
                  ? cls.close
                  : res.data.gateSchedule?.[shiftColumn] === 1
                  ? cls.open
                  : res.data.gateSchedule?.[shiftColumn] === null
                  ? cls.nullStatusAdmin
                  : ''
              );
              break;

            default:
              console.warn(`Unexpected shift_value: ${shift_value}`);
              break;
          }
        }

        notify('Successfully Updated!', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });

    // notify('Click Kardd!!!😳😳😳');
  };

  function getCurrentWeekDates(daydate) {
    const today = new Date();

    // Adjust for Monday-based weeks: getDay() returns 0 for Sunday, 1 for Monday, etc.
    const currentDay = (daydate.getDay() + 6) % 7; // Adjust so Monday is 0 and Sunday is 6

    const firstDayOfWeek = new Date(daydate);
    firstDayOfWeek.setDate(daydate.getDate() - currentDay);

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  }
  // Format the date to separate day, abbreviated month, and year
  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    if (format === 1) {
      return `${year}-${monthNumber}-${day}`;
    } else {
      return { day, dayNumber, dayName, monthNumber, month, year };
    }
  }
  const Reset = () => {
    setSelectedDate(todayy);
    setFormData({
      ports: null,
      date: new Date(),
    });
  };

  //https://testapi.smart-turn.com/api/terminals/gate-schedule?start_date=2024-04-20&end_date=2024-04-30&terminal=7
  const weekDates = getCurrentWeekDates(selectedDate);
  const formattedDates = weekDates.map((date) => formatDate(date));
  const formattedCurrentWeekDates = weekDates.map((date) =>
    formatDate(date, 1)
  );

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = { month: mm, day: dd, year: yyyy };

  const todayIndex = formattedDates.findIndex(
    (date) =>
      date.day === parseInt(today.day) &&
      date.monthNumber === parseInt(today.month) &&
      date.year === today.year
  );

  // const originalDate = "2024-04-20T04:31:52.000000Z";
  const formatUpdatedAtDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate =
      new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }) +
      ' ' +
      new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    return formattedDate;
  };

  // React.useEffect(() => {
  function hasdata(date, flag) {
    if (flag === 'no') {
      if (formattedDates) {
        document.getElementById('datediv').style.color = '#ff0000';
      }
    }
  }
  // });

  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);

  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

        if (amirbiosTop <= -10) {
          amirbios2Ref.current.classList.add(cls.fix);
          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      updateWidth();
      updateHeadWidth();
      syncScroll('amirbios');
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        if (source === 'amirbios') {
          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    // Initial width setting
    updateWidth();
    updateHeadWidth();

    window.updateWidth = updateWidth;
    window.updateHeadWidth = updateHeadWidth;
    window.syncScroll = syncScroll;

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', updateWidth);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('scroll', updateHeadWidth);
    window.addEventListener('resize', updateHeadWidth);
    if (amirbiosRef.current) {
      amirbiosRef.current.addEventListener('scroll', () =>
        syncScroll('amirbios')
      );
      amirbios2Ref.current.addEventListener('scroll', () =>
        syncScroll('amirbios2')
      );
    }

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', updateWidth);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('scroll', updateHeadWidth);
      window.removeEventListener('resize', updateHeadWidth);
      if (amirbiosRef.current) {
        amirbiosRef.current.removeEventListener('scroll', () =>
          syncScroll('amirbios')
        );
        amirbios2Ref.current.removeEventListener('scroll', () =>
          syncScroll('amirbios2')
        );
      }
    };
  }, []);
  if (amirbios2Ref.current) {
    amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
  }

  if (amirbios1Ref.current) {
    amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
  }

  return (
    <>
      <div id="emptyCard" className={cls.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Ports `}</span>
              <SelectBox
                placeholder="Ports"
                className={clsx(cls.select)}
                value={portSelectOptions[0]}
                onChange={(item) => {
                  setForm('ports', item);
                  setSelectedPort(item); // Set selected Port state
                }}
                options={portSelectOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Date `}</span>
              <DateInput
                placeholder="Available date"
                className={clsx(cls.date)}
                format="MM/dd/yyyy"
                value={form.date}
                onChange={(item) => {
                  setForm('date', item);
                  setSelectedDate(item);
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div className={cls.actions}>
          {' '}
          <Grid item xs={12} sm={4} lg={3}>
            <Button className={clsx(cls.but, cls.red)} onClick={Reset}>
              Reset
            </Button>
          </Grid>
        </div>
      </div>
      <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
        {loading ? (
          <Loading />
        ) : (
          <table ref={tableRef} id="table" className={cls.list}>
            <thead className={cls.thead}>
              {/* <td style={{ flex: 0.35 }}>{idx + 1 + (currentPage - 1) * 10}</td> */}
              <span style={{ flex: 0.2 }} className={cls.thdate}>
                <th style={{ flex: 0.3 }}></th>
              </span>
              <span style={{ flex: 0.2 }} className={cls.thdate}>
                <th style={{ flex: 0.4 }}></th>
              </span>
              {Object.keys(formattedDates).map((idx) => (
                <span style={{ flex: 0.3 }} className={cls.thdate}>
                  <th>
                    <div
                      id="datediv"
                      className={
                        formattedSelectedDate.dayNumber ==
                          formattedDates[idx].dayNumber &&
                        formattedSelectedDate.monthNumber ==
                          formattedDates[idx].monthNumber
                          ? cls.tableDateShowToday
                          : cls.tableDateShow
                      }>
                      <span
                        className={
                          formattedSelectedDate.dayNumber ==
                            formattedDates[idx].dayNumber &&
                          formattedSelectedDate.monthNumber ==
                            formattedDates[idx].monthNumber
                            ? cls.dayNameToday
                            : cls.dayName
                        }>
                        {formattedDates[idx].dayName}
                      </span>
                      <span
                        className={
                          formattedSelectedDate.dayNumber ==
                            formattedDates[idx].dayNumber &&
                          formattedSelectedDate.monthNumber ==
                            formattedDates[idx].monthNumber
                            ? cls.dayNumberToday
                            : cls.dayNumberNormal
                        }>
                        {formattedDates[idx].dayNumber}
                      </span>
                    </div>
                  </th>
                </span>
              ))}
            </thead>
            <span ref={amirbios2Ref} id="amirbios1">
              <thead ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                <span style={{ flex: 0.2 }} className={cls.shifttd}>
                  <th style={{ flex: 0.3 }}>Terminal</th>
                </span>
                <span style={{ flex: 0.2 }} className={cls.shifttd}>
                  <th style={{ flex: 0.4 }}>Last Update</th>
                </span>
                <span style={{ flex: 0.3 }} className={cls.shifttd}>
                  <th className={cls.tableTh}>1st Shift</th>
                  <th className={cls.tableTh}>2nd Shift</th>
                  <th className={cls.tableTh}>3rd Shift</th>
                </span>
                <span style={{ flex: 0.3 }} className={cls.shifttd}>
                  <th className={cls.tableTh}>1st Shift</th>
                  <th className={cls.tableTh}>2nd Shift</th>
                  <th className={cls.tableTh}>3rd Shift</th>
                </span>
                <span style={{ flex: 0.3 }} className={cls.shifttd}>
                  <th className={cls.tableTh}>1st Shift</th>
                  <th className={cls.tableTh}>2nd Shift</th>
                  <th className={cls.tableTh}>3rd Shift</th>
                </span>
                <span style={{ flex: 0.3 }} className={cls.shifttd}>
                  <th className={cls.tableTh}>1st Shift</th>
                  <th className={cls.tableTh}>2nd Shift</th>
                  <th className={cls.tableTh}>3rd Shift</th>
                </span>
                <span style={{ flex: 0.3 }} className={cls.shifttd}>
                  <th className={cls.tableTh}>1st Shift</th>
                  <th className={cls.tableTh}>2nd Shift</th>
                  <th className={cls.tableTh}>3rd Shift</th>
                </span>
                <span style={{ flex: 0.3 }} className={cls.shifttd}>
                  <th className={cls.tableTh}>1st Shift</th>
                  <th className={cls.tableTh}>2nd Shift</th>
                  <th className={cls.tableTh}>3rd Shift</th>
                </span>
                <span style={{ flex: 0.3 }} className={cls.shifttd}>
                  <th className={cls.tableTh}>1st Shift</th>
                  <th className={cls.tableTh}>2nd Shift</th>
                  <th className={cls.tableTh}>3rd Shift</th>
                </span>
              </thead>
            </span>
            <tbody className={cls.tbody}>
              {Object.keys(Terminals).map((idx, indexTerminal) => (
                <tr
                  className={
                    Terminals[idx].blur_status === 1
                      ? cls.tableRowNull
                      : cls.tableRow
                  }
                  key={idx}>
                  {/* <td style={{ flex: 0.35 }}>{idx + 1 + (currentPage - 1) * 10}</td> */}
                  <span
                    style={{ flex: 0.2 }}
                    className={
                      Terminals[idx].blur_status === 1
                        ? cls.shifttdTerminal
                        : cls.shifttd
                    }>
                    <td className={cls.terminalsNameLogo}>
                      <a
                        className={cls.terminalLink}
                        target="_blank"
                        href={Terminals[idx].links?.gate_schedule}>
                        <img
                          width={80}
                          src={
                            idx === 'APM'
                              ? TerminalsIcon.APM
                              : idx === 'FMS'
                              ? TerminalsIcon.FMS
                              : idx === 'EVERPORT'
                              ? TerminalsIcon.EVERPORT
                              : idx === 'LACT'
                              ? TerminalsIcon.LACT
                              : idx === 'PCT'
                              ? TerminalsIcon.PCT
                              : idx === 'PST'
                              ? TerminalsIcon.PST
                              : idx === 'Trapac'
                              ? TerminalsIcon.Trapac
                              : idx === 'WBCT'
                              ? TerminalsIcon.WBCT
                              : idx === 'WCC'
                              ? TerminalsIcon.WCC
                              : idx === 'YTI'
                              ? TerminalsIcon.YTI
                              : idx === 'TTI'
                              ? TerminalsIcon.TTI
                              : idx === 'ITS'
                              ? TerminalsIcon.ITS
                              : idx === 'LBCT'
                              ? TerminalsIcon.LBCT
                              : idx === 'MATSON'
                              ? TerminalsIcon.MATSON
                              : idx === 'SSA MARINE'
                              ? TerminalsIcon.SSA
                              : idx === 'SSA STL'
                              ? TerminalsIcon.STL
                              : idx === 'PTS'
                              ? TerminalsIcon.PTS
                              : ''
                          }
                        />
                        {Terminals[idx].slug}
                      </a>
                    </td>
                  </span>
                  <span
                    style={{ flex: 0.2 }}
                    className={
                      Terminals[idx].blur_status === 1
                        ? cls.shifttdTerminal
                        : cls.shifttd
                    }>
                    <td>
                      {Terminals[idx].blur_status === 1
                        ? 'No Data'
                        : Terminals[idx].gate_schedule_last_update?.updated_at
                        ? formatUpdatedAtDate(
                            `${Terminals[idx].gate_schedule_last_update?.updated_at}`
                          )
                        : 'No Data'}
                    </td>
                  </span>
                  {Object.keys(formattedDates).map((i) =>
                    Terminals[idx].blur_status === 1 ? (
                      <span
                        style={{ flex: 0.3 }}
                        className={
                          formattedSelectedDate.dayNumber ==
                            formattedDates[i].dayNumber &&
                          formattedSelectedDate.monthNumber ==
                            formattedDates[i].monthNumber &&
                          Terminals[idx].blur_status === 0
                            ? cls.shifttdToday
                            : cls.shifttd
                        }></span>
                    ) : (
                      <span
                        style={{ flex: 0.3 }}
                        className={
                          formattedSelectedDate.dayNumber ==
                            formattedDates[i].dayNumber &&
                          formattedSelectedDate.monthNumber ==
                            formattedDates[i].monthNumber &&
                          Terminals[idx].blur_status === 0
                            ? cls.shifttdToday
                            : cls.shifttd
                        }>
                        {Terminals[idx].gate_schedules[
                          `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                        ]
                          ? ''
                          : ''}
                        <th
                          id={
                            Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift1'
                          }
                          onClick={() =>
                            changeDataMode(
                              Terminals[idx].uuid +
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                                'shift1'
                            )
                          }
                          className={
                            Terminals[idx].gate_schedules[
                              `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                            ]?.admin_moderations?.shift1 &&
                            Terminals[idx].gate_schedules[
                              `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                            ]?.shift1 === 1
                              ? cls.openVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.admin_moderations?.shift1 &&
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1 === 0
                              ? cls.closeVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.admin_moderations?.shift1 &&
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1 === null
                              ? cls.nullStatusAdminVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1 === 1
                              ? cls.open
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1 === 0
                              ? cls.close
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1 === null
                              ? cls.nullStatusAdmin
                              : cls.nullStatusAdmin
                          }>
                          {selectedUuid ===
                          Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift1' ? (
                            <Select
                              displayEmpty
                              disableUnderline={true}
                              defaultValue={
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift1 === 0
                                  ? 0
                                  : Terminals[idx].gate_schedules[
                                      `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                    ]?.shift1 === 1
                                  ? 1
                                  : Terminals[idx].gate_schedules[
                                      `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                    ]?.shift1 === 2
                                  ? null
                                  : ''
                              }
                              className={cls.selectBox}
                              onChange={(e) =>
                                handleChangeStatusShifts(
                                  Terminals[idx].id,
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`,
                                  'shift1',
                                  e.target.value === 3 ? null : e.target.value,
                                  idx,
                                  Terminals[idx].gate_schedules[
                                    `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                  ]?.shift1,
                                  indexTerminal
                                )
                              }
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem key={0} value={0}>
                                Closed
                              </MenuItem>
                              <MenuItem key={1} value={1}>
                                Open
                              </MenuItem>
                              <MenuItem key={2} value={2}>
                                Scrape
                              </MenuItem>
                              <MenuItem key={3} value={3}>
                                Null
                              </MenuItem>
                            </Select>
                          ) : (
                            ''
                          )}

                          {selectedUuid ===
                          Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift1'
                            ? ''
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift1 === 1
                            ? 'Open'
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift1 === 0
                            ? 'Closed'
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift1 === 2
                            ? null
                            : ''}
                        </th>
                        <th
                          id={
                            Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift2'
                          }
                          onClick={() =>
                            changeDataMode(
                              Terminals[idx].uuid +
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                                'shift2'
                            )
                          }
                          className={
                            Terminals[idx].gate_schedules[
                              `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                            ]?.admin_moderations?.shift2 &&
                            Terminals[idx].gate_schedules[
                              `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                            ]?.shift2 === 1
                              ? cls.openVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.admin_moderations?.shift2 &&
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2 === 0
                              ? cls.closeVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.admin_moderations?.shift2 &&
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2 === null
                              ? cls.nullStatusAdminVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2 === 1
                              ? cls.open
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2 === 0
                              ? cls.close
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2 === 2
                              ? cls.dual
                              : cls.nullStatusAdmin
                          }>
                          {selectedUuid ===
                          Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift2' ? (
                            <Select
                              displayEmpty
                              disableUnderline={true}
                              defaultValue={
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift2 === 0
                                  ? 0
                                  : Terminals[idx].gate_schedules[
                                      `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                    ]?.shift2 === 1
                                  ? 1
                                  : Terminals[idx].gate_schedules[
                                      `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                    ]?.shift2 === 2
                                  ? null
                                  : ''
                              }
                              className={cls.selectBox}
                              onChange={(e) =>
                                handleChangeStatusShifts(
                                  Terminals[idx].id,
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`,
                                  'shift2',
                                  e.target.value === 3 ? null : e.target.value,
                                  idx
                                )
                              }
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem key={0} value={0}>
                                Closed
                              </MenuItem>
                              <MenuItem key={1} value={1}>
                                Open
                              </MenuItem>
                              <MenuItem key={2} value={2}>
                                Scrape
                              </MenuItem>
                              <MenuItem key={3} value={3}>
                                Null
                              </MenuItem>
                            </Select>
                          ) : (
                            ''
                          )}

                          {selectedUuid ===
                          Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift2'
                            ? ''
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift2 === 1
                            ? 'Open'
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift2 === 0
                            ? 'Closed'
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift2 === 2
                            ? null
                            : ''}
                        </th>
                        <th
                          id={
                            Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift3'
                          }
                          onClick={() =>
                            changeDataMode(
                              Terminals[idx].uuid +
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                                'shift3'
                            )
                          }
                          className={
                            Terminals[idx].gate_schedules[
                              `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                            ]?.admin_moderations?.shift3 &&
                            Terminals[idx].gate_schedules[
                              `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                            ]?.shift3 === 1
                              ? cls.openVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.admin_moderations?.shift3 &&
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift3 === 0
                              ? cls.closeVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.admin_moderations?.shift3 &&
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift3 === null
                              ? cls.nullStatusAdminVar
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift3 === 1
                              ? cls.open
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift3 === 0
                              ? cls.close
                              : Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift3 === 2
                              ? cls.dual
                              : cls.nullStatusAdmin
                          }>
                          {selectedUuid ===
                          Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift3' ? (
                            <Select
                              displayEmpty
                              disableUnderline={true}
                              defaultValue={
                                Terminals[idx].gate_schedules[
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                ]?.shift3 === 0
                                  ? 0
                                  : Terminals[idx].gate_schedules[
                                      `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                    ]?.shift3 === 1
                                  ? 1
                                  : Terminals[idx].gate_schedules[
                                      `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                                    ]?.shift3 === 2
                                  ? null
                                  : ''
                              }
                              className={cls.selectBox}
                              onChange={(e) =>
                                handleChangeStatusShifts(
                                  Terminals[idx].id,
                                  `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`,
                                  'shift3',
                                  e.target.value === 3 ? null : e.target.value,
                                  idx
                                )
                              }
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem key={0} value={0}>
                                Closed
                              </MenuItem>
                              <MenuItem key={1} value={1}>
                                Open
                              </MenuItem>
                              <MenuItem key={2} value={2}>
                                Scrape
                              </MenuItem>
                              <MenuItem key={3} value={3}>
                                Null
                              </MenuItem>
                            </Select>
                          ) : (
                            ''
                          )}

                          {selectedUuid ===
                          Terminals[idx].uuid +
                            `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}` +
                            'shift3'
                            ? ''
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift3 === 1
                            ? 'Open'
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift3 === 0
                            ? 'Closed'
                            : Terminals[idx].gate_schedules[
                                `${formattedDates[i].year}-${formattedDates[i].monthNumber}-${formattedDates[i].day}`
                              ]?.shift3 === 2
                            ? null
                            : ''}
                        </th>
                      </span>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
