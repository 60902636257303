// in src/MyLayout.js
import React, { useState } from 'react';
import { Layout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import AppBar from './AppBar';
import SideBar from './SideBar';
import Menu from './Menu';
import ChatBar from '../Chat/ChatBar';
import Footer from './Footer';
import ContactInfoPopup from '../ContactInfoPopup';
import { usePermissions } from 'react-admin';
import { useEffect } from 'react';

const styles = makeStyles({
  container: {
    margin: '1px 0px 5px 100px',
    padding: '0',
    minHeight: 'calc(90vh - 70px)',
    '@media (max-width:1000px)': {
      margin: '0px 0px 10px 100px',
    },
    '@media (max-width:800px)': {
      margin: '0px 0px 50px 0px',
    },
  },
  loginLoader: {
    width: '25px !important',
    height: '25px !important',
    color: '#ffffff',
  },
  adminContainer: {
    margin: '32px 0px 90px 100px',
    '@media (max-width:800px)': {
      margin: '32px 0px 90px 0px !important',
    },
  },
});

export default (props) => {
  const cls = styles();
  const popUp = useSelector((state) => state.reducer.ContactInfoPopup || null);
  const { permissions } = usePermissions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.children]);

  return (
    <Layout {...props} appBar={AppBar} sidebar={SideBar} menu={Menu}>
      {/* {permissions !== 'admin' && <ChatBar />} */}
      {/* {popUp && <ContactInfoPopup data={popUp} />} */}
      <div
        id="amirsalam"
        className={`${cls.container} ${
          permissions === 'admin' && cls.adminContainer
        }`}>
        {/* {permissions === 'admin' && window.location.href.includes('members') && <AdminTopMenu />} */}
        {props.children}
      </div>
      {/* <Footer /> */}
    </Layout>
  );
};
