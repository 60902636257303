import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 240;

export default makeStyles((theme) => ({
  headerCont: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 10,
  },
  logo: {
    width: 150,
    ['@media (max-width:350px)']: {
      width: '100px',
    },
  },

  but: {
    height: 40,
    borderRadius: 10,
    backgroundColor: '#303030',
    fontFamily: 'Averta Bold',
    textDecoration: 'none',
    fontSize: '1rem',
    padding: '0 10px',
    fontWeight: 'Bold',
    lineHeight: 1.2,
    color: '#f8b100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxSizing: 'border-box',
    marginLeft: 25,

    '&:hover': {
      backgroundColor: '#f8b100',
      color: '#303030',
      border: 'solid 2px #303030',
    },
  },

  header: {
    display: 'flex',
    height: 56,
  },
  appBar: {
    backgroundColor: '#f8b100',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#000',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#3A3A3A',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    '& svg': {
      color: '#fff',
      width: '2rem',
      height: '2rem',
    },
  },
  list: {
    '& svg': {
      color: '#fff',
    },
    '& a': {
      fontFamily: 'Averta Bold',
      fontWeight: 'Bold',
      textDecoration: 'none',
      fontSize: '1rem',
      lineHeight: 1.2,
      color: '#fff',
    },
  },
}));
