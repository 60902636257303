import React, { useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { _get } from '@/actions/Helper';
import styles from './appointmentStyle';
import { TerminalsIcon } from '@/assets';
import { availableApointments } from '@/actions';

export default ({
  CloseAppointment,
  openAppointmentSet,
  data,
  setContainerAppointments,
  containerId,
  gatedIn,
  gatedOut,
  container,
}) => {
  const cls = styles();
  const [loading, setLoading] = useState(false);
  const [appointmentSet, setAppointmentSet] = useState([]);
  const Sizes = useSelector((state) => state.reducer.size);
  const SSLs = useSelector((state) => state.reducer.ssl);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [Terminals, setTerminals] = useState([]);
  const [availability, setAvailability] = useState([]);
  const parseData = ({ id, size, ssl, date, count_all, address }) => ({
    id: containerId,
    date,
    count: count_all,
    size: Sizes.find(({ label }) => label === size),
    ssl: SSLs.find(({ label }) => label === ssl),
    loc: {
      '& svg': {
        fill: '#f8b100',
      },
      label: _get(address, 'address', ''),
      value: {
        lng: _get(address, 'long', ''),
        lat: _get(address, 'lat', ''),
        address: _get(address, 'address', ''),
        city: _get(address, 'city', ''),
      },
    },
    gated_in: gatedIn,
    gated_out: gatedOut,
    container_name: container.name,
    chasses_name: container.chases,
  });
  const todayy = new Date();
  const formattedSelectedDate = formatDate(selectedDate);
  // console.log('', );

  function getCurrentWeekDates(daydate) {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(daydate);
      date.setDate(daydate.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    if (format == 1) {
      return `${year}-${monthNumber}-${day}`;
    } else {
      return { day, dayNumber, dayName, monthNumber, month, year };
    }
  }
  const weekDates = getCurrentWeekDates(selectedDate);
  const formattedDates = weekDates.map((date) => formatDate(date));

  const [containers, setContainers] = React.useState(data.containers);

  const [form, setFormData] = React.useState({
    id: null,
    gated_in: container.gated_in,
    gated_out: container.gated_out,
    container_name: container.name,
    chasses_name: container.chases,
    size: null,
    ssl: null,
    date: null,
    count: 0,
    loc: null,
  });

  React.useEffect(() => {
    setFormData(parseData(data));
  }, [data, Sizes, SSLs]);

  const setForm = (it, value) => setFormData({ ...form, [it]: value });

  const handleUpdateContainers = (name, value, index) => {
    setContainers((prev) => {
      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  useEffect(() => {
    setLoading(true);
    form?.ssl && form?.size && getApointment();
  }, [form]);

  const getApointment = () => {
    availableApointments({
      ssl: form?.ssl?.value,
      size: form?.size?.value,
      containerId,
    }).then((res) => {
      const { availability, terminals } = res;
      setAppointmentSet({
        status:
          res?.containerAppointments[0]?.terminal_appointment_availability
            ?.status,
        terminal: `${
          res?.containerAppointments[0]?.terminal_appointment_availability
            .terminal.slug
        }
        ${res?.containerAppointments[0]?.terminal_appointment_availability?.raw_data?.date.replace(
          /(\d{2}:\d{2})-(\d{2}:\d{2})/,
          '$1  -  $2'
        )}`,
      });
      setContainerAppointments(res?.containerAppointments);
      // console.log(res);
      const terminalSlugs = Object.fromEntries(
        Object.entries(terminals).map(([id, terminal]) => [id, terminal.slug])
      );

      Object.keys(terminals).forEach((terminalId) => {
        const terminalSlug = terminalSlugs[terminalId];
        terminals[terminalSlug] = {
          ...terminals[terminalId],
          gate_schedules: {},
        };
        delete terminals[terminalId];
        setAvailability(availability);
        Object.keys(availability).forEach((date) => {
          terminals[terminalSlug].gate_schedules[date] = {};

          if (availability[date][terminalId]) {
            const shifts = availability[date][terminalId];

            Object.keys(shifts).forEach((shiftKey) => {
              const status = shifts[shiftKey].status;

              terminals[terminalSlug].gate_schedules[date][shiftKey] = {
                // single: status === 1 ? 1 : 0,
                single: status === 1 ? 1 : 0,
                // dual: status === 1 || status === 2 ? 1 : 0,
                dual: status === 1 || status === 2 ? 1 : status,
                available_count: shifts[shiftKey].available_count,
              };
            });
          }
        });
      });

      setTerminals(terminals);
      setLoading(false);
    });
  };

  // console.log('Terminal', Terminals);
  // console.log('availability', availability);

  const submit = () => {
    // setLoading(true);
    CloseAppointment();
    // Update(form, containerId, containers).then(() => setLoading(false));
  };
  const showAppts = (i, idx, sh, d) => {
    const terminalId = Number(Terminals[idx]?.id);
    const terminal = idx;
    const shift = sh;
    const dat = d;
    const cabinId = data?.cabinId;
    openAppointmentSet(
      containerId,
      container.id,
      container,
      shift,
      cabinId,
      terminal,
      terminalId,
      dat
    );
    // setLoading(true);
    // CloseAppointment();
    // Update(form, containerId, containers).then(() => setLoading(false));
  };

  const formatDateTimeToUTC = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate =
      `${String(date.getUTCMonth() + 1).padStart(2, '0')}-` + // Months are zero-based
      `${String(date.getUTCDate()).padStart(2, '0')}-` +
      `${String(date.getUTCFullYear()).slice(-2)}`; // Slice the last two digits for the year

    // Check if both hours and minutes are 00
    if (hours === 0 && minutes === 0) {
      return formattedDate; // Return only the date
    } else {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )} ${formattedDate}`; // Return time and date
    }
  };

  const getInventoryContainers = () => {
    let inventoryContainers = [];
    data.containers.forEach((container) => {
      // console.log('con', container);
      // console.log('ff', form);

      // if (container.status) {
      inventoryContainers.push({
        size: data.size,
        ssl: data.ssl,
        location: data.loc,
        lfd: formatDateTimeToUTC(container.last_free_day),
        aptType: 'None',
        chassesType:
          container.own_chases === 0
            ? 'Pool'
            : container.own_chases === 1
            ? 'Own'
            : '',
        terminalApt: container.return_appointment || 'No Apt.',
        name: container.name ? container.name : '',
        id: container.id ? container.id : null,
        chases: container.chases ? container.chases : '',
        status: container.status ? container.status : null,
        gated_in: container.gated_in
          ? formatDateTimeToUTC(container.gated_in)
          : null,
        gated_out: container.gated_out
          ? formatDateTimeToUTC(container.gated_out)
          : null,
      });
      // }
    });
    setContainers(inventoryContainers);
  };

  useEffect(() => {
    getInventoryContainers();
  }, []);

  const dataObjectDateKeyWithIndex = (index) => {
    return `${formattedDates[index].year}-${formattedDates[index].monthNumber}-${formattedDates[index].day}`;
  };

  const shif1SingleStatus = (index, idx) => {
    if (
      availability[dataObjectDateKeyWithIndex(index)] &&
      availability[dataObjectDateKeyWithIndex(index)][Number(Terminals[idx].id)]
    ) {
      return (
        availability[dataObjectDateKeyWithIndex(index)] &&
        availability[dataObjectDateKeyWithIndex(index)][
          Number(Terminals[idx].id)
        ] &&
        availability[dataObjectDateKeyWithIndex(index)][
          Number(Terminals[idx].id)
        ].shift1 &&
        availability[dataObjectDateKeyWithIndex(index)][
          Number(Terminals[idx].id)
        ].shift1.status
      );
    } else {
      return 'noData';
    }
  };
  const shif1SingleAvailability = (index, idx) => {
    return (
      availability[dataObjectDateKeyWithIndex(index)] &&
      availability[dataObjectDateKeyWithIndex(index)][
        Number(Terminals[idx].id)
      ] &&
      availability[dataObjectDateKeyWithIndex(index)][Number(Terminals[idx].id)]
        .shift1 &&
      availability[dataObjectDateKeyWithIndex(index)][Number(Terminals[idx].id)]
        .shift1.available_count
    );
  };
  const shif2SingleStatus = (index, idx) => {
    return (
      availability[dataObjectDateKeyWithIndex(index)] &&
      availability[dataObjectDateKeyWithIndex(index)][
        Number(Terminals[idx].id)
      ] &&
      availability[dataObjectDateKeyWithIndex(index)][Number(Terminals[idx].id)]
        .shift2 &&
      availability[dataObjectDateKeyWithIndex(index)][Number(Terminals[idx].id)]
        .shift2.status
    );
  };
  const shif2SingleAvailability = (index, idx) => {
    return (
      availability[dataObjectDateKeyWithIndex(index)] &&
      availability[dataObjectDateKeyWithIndex(index)][
        Number(Terminals[idx].id)
      ] &&
      availability[dataObjectDateKeyWithIndex(index)][Number(Terminals[idx].id)]
        .shift2 &&
      availability[dataObjectDateKeyWithIndex(index)][Number(Terminals[idx].id)]
        .shift2.available_count
    );
  };

  return (
    <div
      id="app_modal"
      onClick={(e) => e.target.id === 'app_modal' && CloseAppointment()}
      className={cls.popup}>
      <div className={cls.card}>
        {loading ? (
          <Loading className={cls.loading} />
        ) : (
          <>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded className={cls.closeP} onClick={CloseAppointment} />
            </Tooltip>
            <table style={{ marginBottom: '30px' }} className={cls.list}>
              <thead>
                <tr className={cls.thead}>
                  <th style={{ flex: 1 }}>Size</th>
                  <th style={{ flex: 1 }}>SSL</th>
                  <th style={{ flex: 1 }}>Container</th>
                  <th style={{ flex: 1 }}>Chassis</th>
                  <th style={{ flex: 1 }}>Gated Out</th>
                  <th style={{ flex: 1 }}>Empty Location</th>
                  <th style={{ flex: 1 }}>LFD</th>
                  <th style={{ flex: 1 }}>Chassis Type</th>
                  {appointmentSet?.status ? (
                    <th style={{ flex: 1 }}>Apt. Type</th>
                  ) : null}
                  {appointmentSet?.status ? (
                    <th style={{ flex: 1 }}>Terminal Apt.</th>
                  ) : null}
                </tr>
              </thead>
              <tbody className={cls.tbody}>
                {containers.map((container, index) => {
                  return (
                    <>
                      {container.id === containerId ? (
                        <tr
                          style={{ border: '1px solid #e9e9e9' }}
                          className={cls.tableRow}>
                          <td style={{ flex: 1 }}>{container.size}</td>
                          <td style={{ flex: 1 }}>{container.ssl}</td>
                          <td style={{ flex: 1 }}>{container.name}</td>
                          <td style={{ flex: 1 }}>{container.chases}</td>
                          <td style={{ flex: 1 }}>{container.gated_out}</td>
                          <td style={{ flex: 1 }}>{container.location}</td>
                          <td style={{ flex: 1 }}>{container.lfd}</td>
                          <td style={{ flex: 1 }}>{container.chassesType}</td>
                          {appointmentSet?.status ? (
                            <td style={{ flex: 1 }}>
                              {appointmentSet?.status === 1 ? 'Single' : 'Dual'}
                            </td>
                          ) : null}
                          {appointmentSet?.status ? (
                            <td style={{ flex: 1 }}>
                              {appointmentSet?.terminal}
                            </td>
                          ) : null}
                        </tr>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>

            {loading ? (
              <Loading />
            ) : (
              <table className={cls.list}>
                <span>
                  <thead className={cls.thead}>
                    {/* <td style={{ flex: 0.35 }}>{idx + 1 + (currentPage - 1) * 10}</td> */}
                    <span style={{ flex: 0.133 }} className={cls.shifttd}>
                      <th style={{ flex: 0.3 }}>Date</th>
                    </span>
                    {Object.keys(formattedDates).map((idx) => (
                      <span style={{ flex: 0.2 }} className={cls.shifttd}>
                        <th style={{ flex: 0.3 }}>
                          <div
                            id="datediv"
                            className={
                              formattedSelectedDate.dayNumber ==
                                formattedDates[idx].dayNumber &&
                              formattedSelectedDate.monthNumber ==
                                formattedDates[idx].monthNumber
                                ? cls.tableDateShowToday
                                : cls.tableDateShow
                            }>
                            <span
                              className={
                                formattedSelectedDate.dayNumber ==
                                  formattedDates[idx].dayNumber &&
                                formattedSelectedDate.monthNumber ==
                                  formattedDates[idx].monthNumber
                                  ? cls.dayNameToday
                                  : cls.dayName
                              }>
                              {formattedDates[idx].dayName}
                            </span>
                            <span
                              className={
                                formattedSelectedDate.dayNumber ==
                                  formattedDates[idx].dayNumber &&
                                formattedSelectedDate.monthNumber ==
                                  formattedDates[idx].monthNumber
                                  ? cls.dayNumberToday
                                  : cls.dayNumberNormal
                              }>
                              {formattedDates[idx].dayNumber}
                            </span>
                            <span
                              className={
                                formattedSelectedDate.dayNumber ==
                                  formattedDates[idx].dayNumber &&
                                formattedSelectedDate.monthNumber ==
                                  formattedDates[idx].monthNumber
                                  ? cls.dayNameToday
                                  : cls.dayName
                              }>
                              {formattedDates[idx].month}
                            </span>
                          </div>
                        </th>
                      </span>
                    ))}
                  </thead>
                </span>
                <span>
                  <thead className={cls.thead}>
                    <span style={{ flex: 0.2 }} className={cls.shifttd}>
                      <th style={{ flex: 0.3 }}>Terminal</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>1st Shift</th>
                      <th className={cls.tableTh}>2nd Shift</th>
                    </span>
                  </thead>
                </span>
                <span>
                  <thead className={cls.thead}>
                    <span style={{ flex: 0.2 }} className={cls.shifttd}>
                      <th style={{ flex: 0.3 }}>Mode</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                    <span style={{ flex: 0.3 }} className={cls.shifttd}>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                      <th className={cls.tableTh}>Single</th>
                      <th className={cls.tableTh}>Dual</th>
                    </span>
                  </thead>
                </span>
                <tbody className={cls.tbody}>
                  {Object.keys(Terminals).map((idx) => (
                    <tr
                      style={
                        idx === 'ITS' ? { border: '1px solid #e9e9e9' } : {}
                      }
                      className={
                        Terminals[idx].blur_status === 1
                          ? cls.tableRowNull
                          : cls.tableRow
                      }
                      key={idx}>
                      <span
                        style={{ flex: 0.2 }}
                        className={
                          Terminals[idx].blur_status === 1
                            ? cls.shifttdTerminal
                            : cls.shifttd
                        }>
                        <td className={cls.terminalsNameLogo}>
                          <a
                            className={cls.terminalLink}
                            target="_blank"
                            href={Terminals[idx].links?.gate_schedule}>
                            <img
                              width={80}
                              src={
                                idx == 'APM'
                                  ? TerminalsIcon.APM
                                  : idx == 'FMS'
                                  ? TerminalsIcon.FMS
                                  : idx == 'EVERPORT'
                                  ? TerminalsIcon.EVERPORT
                                  : idx == 'LACT'
                                  ? TerminalsIcon.LACT
                                  : idx == 'PCT'
                                  ? TerminalsIcon.PCT
                                  : idx == 'PST'
                                  ? TerminalsIcon.PST
                                  : idx == 'Trapac'
                                  ? TerminalsIcon.Trapac
                                  : idx == 'WBCT'
                                  ? TerminalsIcon.WBCT
                                  : idx == 'WCC'
                                  ? TerminalsIcon.WCC
                                  : idx == 'YTI'
                                  ? TerminalsIcon.YTI
                                  : idx == 'TTI'
                                  ? TerminalsIcon.TTI
                                  : idx == 'ITS'
                                  ? TerminalsIcon.ITS
                                  : idx == 'LBCT'
                                  ? TerminalsIcon.LBCT
                                  : idx == 'MATSON'
                                  ? TerminalsIcon.MATSON
                                  : idx == 'SSA MARINE'
                                  ? TerminalsIcon.SSA
                                  : idx == 'SSA STL'
                                  ? TerminalsIcon.STL
                                  : idx == 'PTS'
                                  ? TerminalsIcon.PTS
                                  : ''
                              }
                            />
                            {Terminals[idx].slug}
                          </a>
                        </td>
                      </span>
                      {Object.keys(formattedDates).map((i) => (
                        <span
                          style={{ flex: 0.3 }}
                          className={
                            formattedSelectedDate.dayNumber ==
                              formattedDates[i].dayNumber &&
                            formattedSelectedDate.monthNumber ==
                              formattedDates[i].monthNumber
                              ? cls.shifttdToday
                              : cls.shifttd
                          }>
                          <th
                            className={
                              shif1SingleStatus(i, idx) === 1 &&
                              shif1SingleAvailability(i, idx) <= 0
                                ? cls.zero
                                : shif1SingleStatus(i, idx) === 1 &&
                                  shif1SingleAvailability(i, idx) > 0
                                ? cls.open
                                : shif1SingleStatus(i, idx) === null
                                ? cls.nullAvalable
                                : shif1SingleStatus(i, idx) === 0
                                ? cls.close
                                : cls.nullAvalable
                            }
                            onClick={() =>
                              shif1SingleStatus(i, idx) === null &&
                              shif1SingleAvailability(i, idx) === null
                                ? ''
                                : (shif1SingleStatus(i, idx) === 1 ||
                                    shif1SingleStatus(i, idx) === null) &&
                                  shif1SingleAvailability(i, idx) > 0
                                ? showAppts(
                                    i,
                                    idx,
                                    1,
                                    dataObjectDateKeyWithIndex(i)
                                  )
                                : ''
                            }>
                            {shif1SingleStatus(i, idx) === 1 &&
                            shif1SingleAvailability(i, idx) <= 0 ? (
                              'Open Avail 0'
                            ) : shif1SingleStatus(i, idx) >= 1 &&
                              shif1SingleAvailability(i, idx) > 0 ? (
                              `Open Avail ${shif1SingleAvailability(i, idx)}`
                            ) : shif1SingleStatus(i, idx) === null &&
                              shif1SingleAvailability(i, idx) > 0 ? (
                              <span
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}>
                                <span>Avail</span>
                                {shif1SingleAvailability(i, idx)}
                              </span>
                            ) : shif1SingleStatus(i, idx) === 0 ? (
                              'Closed'
                            ) : (
                              '-'
                            )}
                          </th>
                          <th
                            className={
                              shif1SingleStatus(i, idx) >= 1 &&
                              shif1SingleAvailability(i, idx) <= 0
                                ? cls.zero
                                : shif1SingleStatus(i, idx) >= 1 &&
                                  shif1SingleAvailability(i, idx) > 0
                                ? cls.open
                                : shif1SingleStatus(i, idx) === null
                                ? cls.nullAvalable
                                : shif1SingleStatus(i, idx) === 0
                                ? cls.close
                                : cls.nullAvalable
                            }
                            onClick={() =>
                              shif1SingleStatus(i, idx) !== 0 &&
                              shif1SingleAvailability(i, idx) > 0
                                ? showAppts(
                                    i,
                                    idx,
                                    1,
                                    dataObjectDateKeyWithIndex(i)
                                  )
                                : ''
                            }>
                            {shif1SingleStatus(i, idx) >= 1 &&
                            shif1SingleAvailability(i, idx) <= 0
                              ? 'Open'
                              : shif1SingleStatus(i, idx) >= 1 &&
                                shif1SingleAvailability(i, idx) > 0
                              ? `Open`
                              : shif1SingleStatus(i, idx) === null
                              ? `-`
                              : shif1SingleStatus(i, idx) === 0
                              ? `Closed`
                              : '-'}
                          </th>
                          <th
                            className={
                              shif2SingleStatus(i, idx) === 1 &&
                              shif2SingleAvailability(i, idx) <= 0
                                ? cls.zero
                                : shif2SingleStatus(i, idx) === 1 &&
                                  shif2SingleAvailability(i, idx) > 0
                                ? cls.open
                                : shif2SingleStatus(i, idx) === null
                                ? cls.nullAvalable
                                : shif2SingleStatus(i, idx) === 0
                                ? cls.close
                                : cls.nullAvalable
                            }
                            onClick={() =>
                              (shif2SingleStatus(i, idx) === 1 ||
                                shif2SingleStatus(i, idx) === null) &&
                              shif2SingleAvailability(i, idx) > 0
                                ? showAppts(
                                    i,
                                    idx,
                                    1,
                                    dataObjectDateKeyWithIndex(i)
                                  )
                                : ''
                            }>
                            {shif2SingleStatus(i, idx) === 1 &&
                            shif2SingleAvailability(i, idx) <= 0 ? (
                              'Open Avail 0'
                            ) : shif2SingleStatus(i, idx) >= 1 &&
                              shif2SingleAvailability(i, idx) > 0 ? (
                              `Open Avail ${shif2SingleAvailability(i, idx)}`
                            ) : shif2SingleStatus(i, idx) === null &&
                              shif2SingleAvailability(i, idx) > 0 ? (
                              <span
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}>
                                <span>Avail</span>
                                {shif2SingleAvailability(i, idx)}
                              </span>
                            ) : shif2SingleStatus(i, idx) === 0 ? (
                              'Closed'
                            ) : (
                              '-'
                            )}
                          </th>
                          <th
                            className={
                              shif2SingleStatus(i, idx) >= 1 &&
                              shif2SingleAvailability(i, idx) <= 0
                                ? cls.zero
                                : shif2SingleStatus(i, idx) >= 1 &&
                                  shif2SingleAvailability(i, idx) > 0
                                ? cls.open
                                : shif2SingleStatus(i, idx) === null
                                ? cls.nullAvalable
                                : shif2SingleStatus(i, idx) === 0
                                ? cls.close
                                : cls.nullAvalable
                            }
                            onClick={() =>
                              shif2SingleStatus(i, idx) !== 0 &&
                              shif2SingleAvailability(i, idx) > 0
                                ? showAppts(
                                    i,
                                    idx,
                                    1,
                                    dataObjectDateKeyWithIndex(i)
                                  )
                                : ''
                            }>
                            {shif2SingleStatus(i, idx) >= 1 &&
                            shif2SingleAvailability(i, idx) <= 0
                              ? 'Open'
                              : shif2SingleStatus(i, idx) >= 1 &&
                                shif2SingleAvailability(i, idx) > 0
                              ? `Open`
                              : shif2SingleStatus(i, idx) === null
                              ? `-`
                              : shif2SingleStatus(i, idx) === 0
                              ? `Closed`
                              : '-'}
                          </th>
                        </span>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Button className={cls.but} onClick={() => CloseAppointment()}>
              Cancel Booking
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
