import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import { Icons, TerminalsIcon } from '@/assets';
import {
  Tooltip,
  Grid,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ReactSVG } from 'react-svg';

import clsx from 'clsx';
import SelectBox from 'react-select';
import {
  adminChangeScrapeSetting,
  adminChangeScrapeSettingPr,
  getScrapeGateList,
  getScrapeRecivablesList,
  getScrapeVesselsList,
  getScrapeVesselsListPr,
} from '@/actions';
import { useNotify, Loading } from 'react-admin';
import PerDiamSetting from './PerDiamSetting';
import { usePusher } from '../../App';
import { toPlainObject } from 'lodash';
import AppointmentsSetting from './AppointmentsSetting';

export default ({
  TerminalsList,
  PortsList,
  DATATableRecivable,
  DATATableVessels,
  dataTableVesselsTerminal,
  dataTableVesselsSsl,
}) => {
  const cls = styles();
  const [loading, setLoading] = useState(false);
  const [selectedPort, setSelectedPort] = useState('All');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState(1);
  const [dataTypeChange, setdataTypeChange] = useState('terminal');
  const [Terminals, setTerminals] = useState([]);
  const [TerminalsReciv, setTerminalsReciv] = useState([]);
  const [TerminalsVessels, setTerminalsVessels] = useState([]);
  const [TerminalsVesselsTerminal, setTerminalsVesselsTerminal] = useState([]);
  const [TerminalsVesselsSsl, setTerminalsVesselsSsl] = useState([]);
  const { channels } = usePusher();

  function replaceVesselScheduleTerminalById(
    TerminalsVesselsTerminal,
    updatedVesselScheduleSetting
  ) {
    const index = TerminalsVesselsTerminal.findIndex(
      (vesselItem) => vesselItem.id === updatedVesselScheduleSetting.id
    );
    if (index !== -1) {
      return TerminalsVesselsTerminal.map((vesselItem, i) =>
        i === index
          ? {
              ...updatedVesselScheduleSetting, // Update fields with new data
              scrapeable: vesselItem.scrapeable, // Preserve existing scrapeable
            }
          : vesselItem
      );
    }
    return TerminalsVesselsTerminal;
  }
  function replaceVesselScheduleSslById(
    TerminalsVesselsSsl,
    updatedVesselScheduleSetting
  ) {
    const index = TerminalsVesselsSsl.findIndex(
      (vesselItem) => vesselItem.id === updatedVesselScheduleSetting.id
    );
    if (index !== -1) {
      return TerminalsVesselsSsl.map((vesselItem, i) =>
        i === index
          ? {
              ...updatedVesselScheduleSetting, // Update fields with new data
              scrapeable: vesselItem.scrapeable, // Preserve existing scrapeable
            }
          : vesselItem
      );
    }
    return TerminalsVesselsSsl;
  }
  function replaceGateScheduleById(Terminals, updatedgateScheduleSetting) {
    const index = Terminals.findIndex(
      (gateItem) => gateItem.id === updatedgateScheduleSetting.id
    );
    if (index !== -1) {
      return Terminals.map((gateItem, i) =>
        i === index
          ? {
              ...updatedgateScheduleSetting, // Update fields with new data
              scrapeable: gateItem.scrapeable, // Preserve existing scrapeable
            }
          : gateItem
      );
    }
    return Terminals;
  }
  function replaceRecivScheduleById(
    TerminalsReciv,
    updatedrecivScheduleSetting
  ) {
    const index = TerminalsReciv.findIndex(
      (recivItem) => recivItem.id === updatedrecivScheduleSetting.id
    );
    if (index !== -1) {
      return TerminalsReciv.map((recivItem, i) =>
        i === index
          ? {
              ...updatedrecivScheduleSetting, // Update fields with new data
              scrapeable: recivItem.scrapeable, // Preserve existing scrapeable
            }
          : recivItem
      );
    }
    return TerminalsReciv;
  }
  useEffect(() => {
    if (channels?.publicChannel) {
      channels.publicChannel.bind('VesselScheduleSetting', (data) => {
        const updatedVesselScheduleSetting = data.message;
        if (updatedVesselScheduleSetting.scrapeable_type === 'Terminal') {
          const updatedTerminalsVesselsTerminal =
            replaceVesselScheduleTerminalById(
              TerminalsVesselsTerminal,
              updatedVesselScheduleSetting
            );

          setTerminalsVesselsTerminal(updatedTerminalsVesselsTerminal);
        } else if (updatedVesselScheduleSetting.scrapeable_type === 'SSL') {
          const updatedTerminalsVesselsSsl = replaceVesselScheduleSslById(
            TerminalsVesselsSsl,
            updatedVesselScheduleSetting
          );
          setTerminalsVesselsSsl(updatedTerminalsVesselsSsl);
        }
      });

      channels.publicChannel.bind('GateScheduleSetting', (data) => {
        // console.log('GateScheduleSetting event:', data);
        const updatedGateScheduleSetting = data.message;
        const updatedTerminalsGates = replaceGateScheduleById(
          Terminals,
          updatedGateScheduleSetting
        );
        // console.log('updatedTerminalsGates:', updatedTerminalsGates);
        setTerminals(updatedTerminalsGates);
      });

      channels.publicChannel.bind('ReceivableScheduleSetting', (data) => {
        // console.log('ReceivableScheduleSetting event:', data);
        const updatedRecivScheduleSetting = data.message;
        const updatedTerminalsReciv = replaceRecivScheduleById(
          TerminalsReciv,
          updatedRecivScheduleSetting
        );
        // console.log('updatedTerminalsReciv:', updatedTerminalsReciv);
        setTerminalsReciv(updatedTerminalsReciv);
      });
      // console.log('Reciv:', TerminalsReciv);
      // console.log('Gate:', Terminals);
      // console.log('vvst:', TerminalsVesselsTerminal);

      return () => {
        channels.publicChannel.unbind('VesselScheduleSetting');
        channels.publicChannel.unbind('GateScheduleSetting');
        channels.publicChannel.unbind('ReceivableScheduleSetting');
      };
    }
  }, [
    channels,
    TerminalsVesselsTerminal,
    TerminalsVesselsSsl,
    TerminalsVessels,
    TerminalsReciv,
  ]);
  useEffect(() => {
    // console.log('TerminalsVessels', TerminalsVessels);
  }, [TerminalsVessels, DATATableVessels]);

  const scheduleType = [
    {
      label: 'Gate',
      slug: 'gate',
      value: 1,
    },
    {
      label: 'Recivables',
      slug: 'recivables',
      value: 2,
    },
    {
      label: 'Vessel',
      slug: 'vessel',
      value: 3,
    },
    {
      label: 'PerDiam',
      slug: 'perDiam',
      value: 4,
    },
    {
      label: 'Appointments',
      slug: 'appointments',
      value: 5,
    },
  ];
  const typeSelectOptions = [
    {
      label: 'Terminal',
      slug: 'terminal',
      value: 'terminal',
    },
    {
      label: 'SSL',
      slug: 'ssl',
      value: 'ssl',
    },
    {
      label: 'Priority',
      slug: 'all',
      value: 'all',
    },
  ];
  const [form, setFormData] = React.useState({
    scheduleType: scheduleType[0],
    type: typeSelectOptions[0],
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
  });

  const [formReciv, setFormRecivData] = React.useState({
    scheduleType: scheduleType[0],
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
  });

  const getGateScheduleList = () => {
    setLoading(true);
    getScrapeGateList('LA/LB')
      .then((res) => {
        if (res) {
          setTerminals(res.data.scrapeSettings);
        }
        let keys = Object.keys(Terminals);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getRecivablesScheduleList = () => {
    setLoading(true);
    getScrapeRecivablesList('LA/LB')
      .then((res) => {
        if (res) {
          setTerminalsReciv(res.data.scrapeSettings);
        }
        let keys = Object.keys(TerminalsReciv);

        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getVesselsScheduleListPr = () => {
    setLoading(true);
    getScrapeVesselsListPr()
      .then((res) => {
        if (res) {
          setTerminalsVessels(res.data.scrapeSettings);
        }
        let keys = Object.keys(TerminalsVessels);
        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getVesselsScheduleListTerminal = () => {
    const source = `&source=Terminal`;
    setLoading(true);
    getScrapeVesselsList('LA/LB', source)
      .then((res) => {
        if (res) {
          setTerminalsVesselsTerminal(res.data.scrapeSettings);
        }
        let keys = Object.keys(TerminalsVesselsTerminal);

        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };
  const getVesselsScheduleListSsl = () => {
    const source = `&source=SSL`;
    setLoading(true);
    getScrapeVesselsList('LA/LB', source)
      .then((res) => {
        if (res) {
          setTerminalsVesselsSsl(res.data.scrapeSettings);
        }
        let keys = Object.keys(TerminalsVesselsSsl);

        if (keys.length > 0) {
          setLoading(false);
        }
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const todayy = new Date();
  const handleTableData = (item) => {
    setLoading(true);
    switch (item) {
      case 1:
        getGateScheduleList();
        break;

      case 2:
        getRecivablesScheduleList();
        setTimeout(() => {
          setLoading(false);
        }, 500);
        break;

      case 3:
        getVesselsScheduleListTerminal();
        setTimeout(() => {
          setLoading(false);
        }, 500);
        break;

      case 'all':
        getVesselsScheduleListPr();
        setTimeout(() => {
          setLoading(false);
        }, 500);

        break;

      case 'terminal':
        getVesselsScheduleListTerminal();
        setTimeout(() => {
          setLoading(false);
        }, 500);

        break;

      case 'ssl':
        getVesselsScheduleListSsl();
        setTimeout(() => {
          setLoading(false);
        }, 500);

        break;

      case 4:
        getVesselsScheduleListTerminal();
        setTimeout(() => {
          setLoading(false);
        }, 500);

        break;

      default:
        console.error('Unknown item:', item);
        setLoading(false);
        break;
    }
  };

  useEffect(() => {
    getGateScheduleList();
  }, []);
  const portSelectOptions = Object.keys(PortsList).map((i, index) => ({
    label: PortsList[index].name,
    slug: PortsList[index].slug,
    value: index + 1,
  }));

  const notify = useNotify();

  const handleChangeScrapeSetting = (terminalId, key, value, idx) => {
    adminChangeScrapeSetting(terminalId, key, value)
      .then((res) => {
        if (res) {
          setTerminals((prevTerminals) =>
            prevTerminals?.map((item, index) =>
              index === idx ? { ...item, ...res.data.scrapeSetting } : item
            )
          );
        }

        notify('Successfully Updated!', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleChangeScrapeSettingReciv = (terminalId, key, value, idx) => {
    adminChangeScrapeSetting(terminalId, key, value)
      .then((res) => {
        if (res) {
          setTerminalsReciv((prevTerminals) =>
            prevTerminals?.map((item, index) =>
              index === idx ? { ...item, ...res.data.scrapeSetting } : item
            )
          );
        }

        notify('Successfully Updated!', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleChangeScrapeSettingVesselsPr = (ID, value, idx) => {
    adminChangeScrapeSettingPr(ID, value, idx)
      .then((res) => {
        if (res) {
          setTerminalsVessels((prevTerminals) =>
            prevTerminals?.map((item, index) =>
              index === idx ? { ...item, ...res.data.scrapeSetting } : item
            )
          );
        }

        notify('Successfully Updated!', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleChangeScrapeSettingVesselsTerminal = (
    terminalId,
    key,
    value,
    idx
  ) => {
    adminChangeScrapeSetting(terminalId, key, value)
      .then((res) => {
        if (res) {
          setTerminalsVesselsTerminal((prevTerminals) =>
            prevTerminals?.map((item, index) =>
              index === idx ? { ...item, ...res.data.scrapeSetting } : item
            )
          );
        }

        notify('Successfully Updated!', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleChangeScrapeSettingVesselsSsl = (terminalId, key, value, idx) => {
    adminChangeScrapeSetting(terminalId, key, value)
      .then((res) => {
        if (res) {
          setTerminalsVesselsSsl((prevTerminals) =>
            prevTerminals?.map((item, index) =>
              index === idx ? { ...item, ...res.data.scrapeSetting } : item
            )
          );
        }

        notify('Successfully Updated!', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const setForm = (it, val) => setFormData({ ...form, [it]: val });
  const setFormReciv = (it, val) =>
    setFormRecivData({ ...formReciv, [it]: val });

  function getCurrentWeekDates(daydate) {
    const today = new Date();

    // Adjust for Monday-based weeks: getDay() returns 0 for Sunday, 1 for Monday, etc.
    const currentDay = (daydate.getDay() + 6) % 7; // Adjust so Monday is 0 and Sunday is 6

    const firstDayOfWeek = new Date(daydate);
    firstDayOfWeek.setDate(daydate.getDate() - currentDay);

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  }
  // Format the date to separate day, abbreviated month, and year
  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    if (format == 1) {
      return `${year}-${monthNumber}-${day}`;
    } else {
      return { day, dayNumber, dayName, monthNumber, month, year };
    }
  }
  const Reset = () => {
    setSelectedDate(todayy);
    setFormData({
      ports: null,
      date: new Date(),
    });
  };

  //https://testapi.smart-turn.com/api/terminals/gate-schedule?start_date=2024-04-20&end_date=2024-04-30&terminal=7
  const weekDates = getCurrentWeekDates(selectedDate);
  const formattedDates = weekDates.map((date) => formatDate(date));
  const formattedCurrentWeekDates = weekDates.map((date) =>
    formatDate(date, 1)
  );

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = { month: mm, day: dd, year: yyyy };

  const todayIndex = formattedDates.findIndex(
    (date) =>
      date.day === parseInt(today.day) &&
      date.monthNumber === parseInt(today.month) &&
      date.year === today.year
  );

  // const originalDate = "2024-04-20T04:31:52.000000Z";
  const formatUpdatedAtDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate =
      new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }) +
      ' ' +
      new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    return formattedDate;
  };

  // React.useEffect(() => {
  function hasdata(date, flag) {
    if (flag == 'no') {
      if (formattedDates) {
        document.getElementById('datediv').style.color = '#ff0000';
      }
    }
  }
  // });

  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);

  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;

        if (amirbiosTop <= -10) {
          amirbios2Ref.current.classList.add(cls.fix);
          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      syncScroll('amirbios');
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        if (source === 'amirbios') {
          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    // Initial width setting
    updateWidth();
    updateHeadWidth();

    window.updateWidth = updateWidth;
    window.updateHeadWidth = updateHeadWidth;
    window.syncScroll = syncScroll;

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', updateWidth);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('scroll', updateHeadWidth);
    window.addEventListener('resize', updateHeadWidth);
    if (amirbiosRef.current) {
      amirbiosRef.current.addEventListener('scroll', () =>
        syncScroll('amirbios')
      );
    }
    if (amirbios2Ref.current) {
      amirbios2Ref.current.addEventListener('scroll', () =>
        syncScroll('amirbios2')
      );
    }

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', updateWidth);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('scroll', updateHeadWidth);
      window.removeEventListener('resize', updateHeadWidth);
      if (amirbiosRef.current) {
        amirbiosRef.current.removeEventListener('scroll', () =>
          syncScroll('amirbios')
        );
      }
      if (amirbios2Ref.current) {
        amirbios2Ref.current.removeEventListener('scroll', () =>
          syncScroll('amirbios2')
        );
      }
    };
  }, [
    tableWidth,
    spanWidth,
    Terminals,
    selectedType,
    scheduleType,
    form,
    formatDate,
  ]);

  useEffect(() => {
    if (amirbios2Ref.current) {
      amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
    }
  }, [spanWidth]);

  useEffect(() => {
    if (amirbios1Ref.current) {
      amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
    }
  }, [tableWidth]);

  if (amirbios2Ref.current) {
    amirbios2Ref.current.style.width = `${spanWidth - 4}px`;
  }

  if (amirbios1Ref.current) {
    amirbios1Ref.current.style.width = `${tableWidth - 4}px`;
  }

  // useEffect(() => {
  //     localStorage.setItem("scrape_setting_form", JSON.stringify(form));
  // },[form])

  return (
    <>
      <div id="emptyCard" className={cls.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Type `}</span>
              <SelectBox
                placeholder="Type"
                className={clsx(cls.select)}
                value={form.scheduleType}
                onChange={(item) => {
                  setForm('scheduleType', item);
                  handleTableData(item.value); // Set selected Port state
                }}
                options={scheduleType}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Ports `}</span>
              <SelectBox
                placeholder="Ports"
                className={clsx(cls.select)}
                // value={
                //   JSON.parse(localStorage?.getItem('scrape_setting_form'))
                //     ? JSON.parse(localStorage?.getItem('scrape_setting_form'))
                //         ?.ports
                //     : portSelectOptions[0]
                // }
                value={portSelectOptions[0]}
                onChange={(item) => {
                  setForm('ports', item);
                  setSelectedPort(item);
                }}
                options={portSelectOptions}
              />
            </div>
          </Grid>
          {form.scheduleType.value === 3 ? (
            <Grid item xs={12} sm={4} lg={3}>
              <div className={`${cls.input} ${cls.containerInput}`}>
                <span className={cls.containerBadge}>{`Vessel `}</span>
                <SelectBox
                  placeholder="vessel"
                  className={clsx(cls.select)}
                  value={form.type}
                  onChange={(item) => {
                    setForm('type', item);
                    setSelectedType(item.value);
                    setdataTypeChange(item.value);
                    handleTableData(item.value);
                  }}
                  options={typeSelectOptions}
                />
              </div>
            </Grid>
          ) : null}
        </Grid>
      </div>
      <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
        {loading ? (
          <Loading />
        ) : form.scheduleType.value === 1 ? (
          <table ref={tableRef} id="table" className={cls.list}>
            <span ref={amirbios2Ref} id="amirbios1">
              <thead ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Terminal</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Integration</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Scrapping Frequency</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Last Scrape</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Next Scrape</th>
                </span>
                <span style={{ flex: 1.3 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Action</th>
                </span>
              </thead>
            </span>
            <tbody className={cls.tbody}>
              {Array.isArray(Terminals)
                ? Terminals.map((item, idx) => (
                    <tr className={cls.tableRow} key={idx}>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          <a
                            className={cls.terminalLink}
                            target="_blank"
                            href={item.scrapeable?.links.gate_schedule}>
                            <img
                              width={80}
                              src={
                                item.scrapeable.slug == 'APM'
                                  ? TerminalsIcon.APM
                                  : item.scrapeable.slug == 'FMS'
                                  ? TerminalsIcon.FMS
                                  : item.scrapeable.slug == 'EVERPORT'
                                  ? TerminalsIcon.EVERPORT
                                  : item.scrapeable.slug == 'LACT'
                                  ? TerminalsIcon.LACT
                                  : item.scrapeable.slug == 'PCT'
                                  ? TerminalsIcon.PCT
                                  : item.scrapeable.slug == 'PST'
                                  ? TerminalsIcon.PST
                                  : item.scrapeable.slug == 'Trapac'
                                  ? TerminalsIcon.Trapac
                                  : item.scrapeable.slug == 'WBCT'
                                  ? TerminalsIcon.WBCT
                                  : item.scrapeable.slug == 'WCC'
                                  ? TerminalsIcon.WCC
                                  : item.scrapeable.slug == 'YTI'
                                  ? TerminalsIcon.YTI
                                  : item.scrapeable.slug == 'TTI'
                                  ? TerminalsIcon.TTI
                                  : item.scrapeable.slug == 'ITS'
                                  ? TerminalsIcon.ITS
                                  : item.scrapeable.slug == 'LBCT'
                                  ? TerminalsIcon.LBCT
                                  : item.scrapeable.slug == 'MATSON'
                                  ? TerminalsIcon.MATSON
                                  : item.scrapeable.slug == 'SSA MARINE'
                                  ? TerminalsIcon.SSA
                                  : item.scrapeable.slug == 'SSA STL'
                                  ? TerminalsIcon.STL
                                  : item.scrapeable.slug == 'PTS'
                                  ? TerminalsIcon.PTS
                                  : ''
                              }
                            />
                            {item.scrapeable.slug}
                          </a>
                        </span>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <FormControl>
                          <Select
                            displayEmpty
                            disableUnderline={true}
                            defaultValue={item.scrape_type}
                            className={cls.selectBox}
                            onChange={(e) =>
                              handleChangeScrapeSetting(
                                item.id,
                                'scrape_type',
                                item.scrape_type === 'SCRAPE'
                                  ? 'API'
                                  : item.scrape_type === 'API'
                                  ? 'SCRAPE'
                                  : '',
                                idx
                              )
                            }
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem key={0} value={'SCRAPE'}>
                              SCRAPE
                            </MenuItem>
                            <MenuItem key={1} value={'API'}>
                              API
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <FormControl>
                          <Select
                            displayEmpty
                            disableUnderline={true}
                            defaultValue={item.scrape_frequency}
                            className={cls.selectBox}
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  15,
                                  idx
                                )
                              }
                              key={0}
                              value={15}>
                              15 min
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  30,
                                  idx
                                )
                              }
                              key={1}
                              value={30}>
                              30 min
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  60,
                                  idx
                                )
                              }
                              key={2}
                              value={60}>
                              1 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  360,
                                  idx
                                )
                              }
                              key={3}
                              value={360}>
                              6 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  720,
                                  idx
                                )
                              }
                              key={4}
                              value={720}>
                              12 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSetting(
                                  item.id,
                                  'scrape_frequency',
                                  1440,
                                  idx
                                )
                              }
                              key={5}
                              value={1440}>
                              1 day
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          {item.last_scrape
                            ? formatUpdatedAtDate(item.last_scrape)
                            : ''}
                        </span>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          {item.next_scrape
                            ? formatUpdatedAtDate(item.next_scrape)
                            : ''}
                        </span>
                      </td>
                      <td style={{ flex: 1.3 }} className={cls.shifttd}>
                        {item.status === 'DISABLED' ? (
                          <Tooltip arrow title="Disabled" placement="top">
                            <IconButton
                              aria-label="Disable"
                              onClick={() => {
                                handleChangeScrapeSetting(
                                  item.id,
                                  'status',
                                  'ENABLED',
                                  idx
                                );
                              }}>
                              <ReactSVG
                                src={Icons.close}
                                className={cls.icon}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip arrow title="Enabled" placement="top">
                            <IconButton
                              aria-label="enable"
                              onClick={() => {
                                handleChangeScrapeSetting(
                                  item.id,
                                  'status',
                                  'DISABLED',
                                  idx
                                );
                              }}>
                              <ReactSVG
                                src={Icons.tickICon}
                                className={cls.icon}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip arrow title="Refresh" placement="top">
                          <IconButton
                            aria-label="refresh"
                            onClick={() =>
                              handleChangeScrapeSetting(
                                item.id,
                                'refresh_scrape',
                                1,
                                idx
                              )
                            }>
                            <ReactSVG
                              src={Icons.refreshIcon}
                              className={cls.icon}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Stop" placement="top">
                          <IconButton
                            aria-label="stop"
                            onClick={() =>
                              handleChangeScrapeSetting(
                                item.id,
                                'blur_status',
                                item.blur_status === 0 ? 1 : 0,
                                idx
                              )
                            }>
                            <ReactSVG
                              src={
                                item.blur_status === 0
                                  ? Icons.stop
                                  : Icons.stopRed
                              }
                              className={cls.icon}
                            />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                : ''}
            </tbody>
          </table>
        ) : form.scheduleType.value === 2 ? (
          <table ref={tableRef} id="table" className={cls.list}>
            <span ref={amirbios2Ref} id="amirbios1">
              <thead ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Terminal</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Integration</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Scrapping Frequency</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Last Scrape</th>
                </span>
                <span style={{ flex: 1 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Next Scrape</th>
                </span>
                <span style={{ flex: 1.3 }} className={cls.shifttd}>
                  <th style={{ flex: 1 }}>Action</th>
                </span>
              </thead>
            </span>
            <></>
            <tbody className={cls.tbody}>
              {Array.isArray(TerminalsReciv)
                ? TerminalsReciv.map((item, idx) => (
                    <tr className={cls.tableRow} key={idx}>
                      <></>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          <a
                            className={cls.terminalLink}
                            target="_blank"
                            href={item.scrapeable?.links.gate_schedule}>
                            <img
                              width={80}
                              src={
                                item.scrapeable.slug == 'APM'
                                  ? TerminalsIcon.APM
                                  : item.scrapeable.slug == 'FMS'
                                  ? TerminalsIcon.FMS
                                  : item.scrapeable.slug == 'EVERPORT'
                                  ? TerminalsIcon.EVERPORT
                                  : item.scrapeable.slug == 'LACT'
                                  ? TerminalsIcon.LACT
                                  : item.scrapeable.slug == 'PCT'
                                  ? TerminalsIcon.PCT
                                  : item.scrapeable.slug == 'PST'
                                  ? TerminalsIcon.PST
                                  : item.scrapeable.slug == 'Trapac'
                                  ? TerminalsIcon.Trapac
                                  : item.scrapeable.slug == 'WBCT'
                                  ? TerminalsIcon.WBCT
                                  : item.scrapeable.slug == 'WCC'
                                  ? TerminalsIcon.WCC
                                  : item.scrapeable.slug == 'YTI'
                                  ? TerminalsIcon.YTI
                                  : item.scrapeable.slug == 'TTI'
                                  ? TerminalsIcon.TTI
                                  : item.scrapeable.slug == 'ITS'
                                  ? TerminalsIcon.ITS
                                  : item.scrapeable.slug == 'LBCT'
                                  ? TerminalsIcon.LBCT
                                  : item.scrapeable.slug == 'MATSON'
                                  ? TerminalsIcon.MATSON
                                  : item.scrapeable.slug == 'SSA MARINE'
                                  ? TerminalsIcon.SSA
                                  : item.scrapeable.slug == 'SSA STL'
                                  ? TerminalsIcon.STL
                                  : item.scrapeable.slug == 'PTS'
                                  ? TerminalsIcon.PTS
                                  : ''
                              }
                            />
                            {item.scrapeable.slug}
                          </a>
                        </span>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <FormControl>
                          <Select
                            displayEmpty
                            disableUnderline={true}
                            defaultValue={item.scrape_type}
                            className={cls.selectBox}
                            onChange={(e) =>
                              handleChangeScrapeSettingReciv(
                                item.id,
                                'scrape_type',
                                item.scrape_type === 'SCRAPE'
                                  ? 'API'
                                  : item.scrape_type === 'API'
                                  ? 'SCRAPE'
                                  : '',
                                idx
                              )
                            }
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem key={0} value={'SCRAPE'}>
                              SCRAPE
                            </MenuItem>
                            <MenuItem key={1} value={'API'}>
                              API
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <FormControl>
                          <Select
                            displayEmpty
                            disableUnderline={true}
                            defaultValue={item.scrape_frequency}
                            className={cls.selectBox}
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSettingReciv(
                                  item.id,
                                  'scrape_frequency',
                                  15,
                                  idx
                                )
                              }
                              key={0}
                              value={15}>
                              15 min
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSettingReciv(
                                  item.id,
                                  'scrape_frequency',
                                  30,
                                  idx
                                )
                              }
                              key={1}
                              value={30}>
                              30 min
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSettingReciv(
                                  item.id,
                                  'scrape_frequency',
                                  60,
                                  idx
                                )
                              }
                              key={2}
                              value={60}>
                              1 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSettingReciv(
                                  item.id,
                                  'scrape_frequency',
                                  360,
                                  idx
                                )
                              }
                              key={3}
                              value={360}>
                              6 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSettingReciv(
                                  item.id,
                                  'scrape_frequency',
                                  720,
                                  idx
                                )
                              }
                              key={4}
                              value={720}>
                              12 hour
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                handleChangeScrapeSettingReciv(
                                  item.id,
                                  'scrape_frequency',
                                  1440,
                                  idx
                                )
                              }
                              key={5}
                              value={1440}>
                              1 day
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </td>
                      <></>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          {item.last_scrape
                            ? formatUpdatedAtDate(item.last_scrape)
                            : ''}
                        </span>
                      </td>
                      <td style={{ flex: 1 }} className={cls.shifttd}>
                        <span>
                          {item.next_scrape
                            ? formatUpdatedAtDate(item.next_scrape)
                            : ''}
                        </span>
                      </td>
                      <td style={{ flex: 1.3 }} className={cls.shifttd}>
                        {item.status === 'DISABLED' ? (
                          <Tooltip arrow title="Disabled" placement="top">
                            <IconButton
                              aria-label="Disable"
                              onClick={() => {
                                handleChangeScrapeSettingReciv(
                                  item.id,
                                  'status',
                                  'ENABLED',
                                  idx
                                );
                              }}>
                              <ReactSVG
                                src={Icons.close}
                                className={cls.icon}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip arrow title="Enabled" placement="top">
                            <IconButton
                              aria-label="enable"
                              onClick={() => {
                                handleChangeScrapeSettingReciv(
                                  item.id,
                                  'status',
                                  'DISABLED',
                                  idx
                                );
                              }}>
                              <ReactSVG
                                src={Icons.tickICon}
                                className={cls.icon}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip arrow title="Refresh" placement="top">
                          <IconButton
                            aria-label="Refresh"
                            onClick={() =>
                              handleChangeScrapeSettingReciv(
                                item.id,
                                'refresh_scrape',
                                1,
                                idx
                              )
                            }>
                            <ReactSVG
                              src={Icons.refreshIcon}
                              className={cls.icon}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Stop" placement="top">
                          <IconButton
                            aria-label="stop"
                            onClick={() =>
                              handleChangeScrapeSettingReciv(
                                item.id,
                                'blur_status',
                                item.blur_status === 0 ? 1 : 0,
                                idx
                              )
                            }>
                            <ReactSVG
                              src={
                                item.blur_status === 0
                                  ? Icons.stop
                                  : Icons.stopRed
                              }
                              className={cls.icon}
                            />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                : ''}
            </tbody>
          </table>
        ) : form.scheduleType.value === 3 ? (
          dataTypeChange === 'all' ? (
            <table
              key={'all'}
              ref={tableRef}
              id="table"
              className={cls.listPriority}>
              <span ref={amirbios2Ref} id="amirbios1">
                <thead ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Column </th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Terminal</th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Shipping Line</th>
                  </span>
                </thead>
                <></>
              </span>
              <></> <></>
              <tbody className={cls.tbody}>
                {Array.isArray(TerminalsVessels)
                  ? TerminalsVessels.map((item, idx) => (
                      <tr className={cls.tableRow} key={idx}>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <span>{item.show_name}</span>
                        </td>
                        {item.terminal_as_priority === 1 ? (
                          <td
                            style={{ flex: 1 }}
                            className={
                              item.terminal_as_priority === 0
                                ? cls.shifttdClick
                                : cls.shifttd
                            }>
                            <span>
                              <ReactSVG
                                src={Icons.close}
                                className={cls.icon}
                              />
                            </span>
                          </td>
                        ) : (
                          <td
                            style={{ flex: 1 }}
                            className={
                              item.terminal_as_priority === 0
                                ? cls.shifttdClick
                                : cls.shifttd
                            }
                            onClick={(e) =>
                              handleChangeScrapeSettingVesselsPr(
                                item.id,
                                1,
                                idx
                              )
                            }></td>
                        )}
                        {item.ssl_as_priority === 1 ? (
                          <td
                            style={{ flex: 1 }}
                            className={
                              item.ssl_as_priority === 0
                                ? cls.shifttdClick
                                : cls.shifttd
                            }>
                            <span>
                              <ReactSVG
                                src={Icons.close}
                                className={cls.icon}
                              />
                            </span>
                          </td>
                        ) : (
                          <td
                            style={{ flex: 1 }}
                            className={
                              item.ssl_as_priority === 0
                                ? cls.shifttdClick
                                : cls.shifttd
                            }
                            onClick={(e) =>
                              handleChangeScrapeSettingVesselsPr(
                                item.id,
                                2,
                                idx
                              )
                            }></td>
                        )}
                      </tr>
                    ))
                  : ''}
              </tbody>
            </table>
          ) : dataTypeChange === 'terminal' ? (
            <table
              key={'Terminal'}
              ref={tableRef}
              id="table"
              className={cls.list}>
              <span ref={amirbios2Ref} id="amirbios1">
                <thead ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Terminal </th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Integration</th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Scrapping Frequency</th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Last Scrape</th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Next Scrape</th>
                  </span>
                  <span style={{ flex: 1.3 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Action</th>
                  </span>
                  <></>
                </thead>
              </span>
              <></>
              <tbody className={cls.tbody}>
                <></>
                {Array.isArray(TerminalsVesselsTerminal)
                  ? TerminalsVesselsTerminal.map((item, idx) => (
                      <tr className={cls.tableRow} key={idx}>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <span>
                            <a
                              className={cls.terminalLink}
                              target="_blank"
                              href={item.scrapeable?.links.gate_schedule}>
                              <img
                                width={80}
                                src={
                                  item.scrapeable.slug == 'APM'
                                    ? TerminalsIcon.APM
                                    : item.scrapeable.slug == 'FMS'
                                    ? TerminalsIcon.FMS
                                    : item.scrapeable.slug == 'EVERPORT'
                                    ? TerminalsIcon.EVERPORT
                                    : item.scrapeable.slug == 'LACT'
                                    ? TerminalsIcon.LACT
                                    : item.scrapeable.slug == 'PCT'
                                    ? TerminalsIcon.PCT
                                    : item.scrapeable.slug == 'PST'
                                    ? TerminalsIcon.PST
                                    : item.scrapeable.slug == 'Trapac'
                                    ? TerminalsIcon.Trapac
                                    : item.scrapeable.slug == 'WBCT'
                                    ? TerminalsIcon.WBCT
                                    : item.scrapeable.slug == 'WCC'
                                    ? TerminalsIcon.WCC
                                    : item.scrapeable.slug == 'YTI'
                                    ? TerminalsIcon.YTI
                                    : item.scrapeable.slug == 'TTI'
                                    ? TerminalsIcon.TTI
                                    : item.scrapeable.slug == 'ITS'
                                    ? TerminalsIcon.ITS
                                    : item.scrapeable.slug == 'LBCT'
                                    ? TerminalsIcon.LBCT
                                    : item.scrapeable.slug == 'MATSON'
                                    ? TerminalsIcon.MATSON
                                    : item.scrapeable.slug == 'SSA MARINE'
                                    ? TerminalsIcon.SSA
                                    : item.scrapeable.slug == 'SSA STL'
                                    ? TerminalsIcon.STL
                                    : item.scrapeable.slug == 'PTS'
                                    ? TerminalsIcon.PTS
                                    : ''
                                }
                              />
                              {item.scrapeable.slug}
                            </a>
                          </span>
                          <></>
                        </td>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <FormControl>
                            <Select
                              displayEmpty
                              disableUnderline={true}
                              defaultValue={item.scrape_type}
                              className={cls.selectBox}
                              onChange={(e) =>
                                handleChangeScrapeSettingVesselsTerminal(
                                  item.id,
                                  'scrape_type',
                                  item.scrape_type === 'SCRAPE'
                                    ? 'API'
                                    : item.scrape_type === 'API'
                                    ? 'SCRAPE'
                                    : '',
                                  idx
                                )
                              }
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem key={0} value={'SCRAPE'}>
                                SCRAPE
                              </MenuItem>
                              <MenuItem key={1} value={'API'}>
                                API
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </td>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <FormControl>
                            <Select
                              displayEmpty
                              disableUnderline={true}
                              defaultValue={item.scrape_frequency}
                              className={cls.selectBox}
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsTerminal(
                                    item.id,
                                    'scrape_frequency',
                                    15,
                                    idx
                                  )
                                }
                                key={0}
                                value={15}>
                                15 min
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsTerminal(
                                    item.id,
                                    'scrape_frequency',
                                    30,
                                    idx
                                  )
                                }
                                key={1}
                                value={30}>
                                30 min
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsTerminal(
                                    item.id,
                                    'scrape_frequency',
                                    60,
                                    idx
                                  )
                                }
                                key={2}
                                value={60}>
                                1 hour
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsTerminal(
                                    item.id,
                                    'scrape_frequency',
                                    360,
                                    idx
                                  )
                                }
                                key={3}
                                value={360}>
                                6 hour
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsTerminal(
                                    item.id,
                                    'scrape_frequency',
                                    720,
                                    idx
                                  )
                                }
                                key={4}
                                value={720}>
                                12 hour
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsTerminal(
                                    item.id,
                                    'scrape_frequency',
                                    1440,
                                    idx
                                  )
                                }
                                key={5}
                                value={1440}>
                                1 day
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </td>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <span>
                            {item.last_scrape
                              ? formatUpdatedAtDate(item.last_scrape)
                              : ''}
                          </span>
                        </td>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <span>
                            {item.next_scrape
                              ? formatUpdatedAtDate(item.next_scrape)
                              : ''}
                          </span>
                        </td>
                        <td style={{ flex: 1.3 }} className={cls.shifttd}>
                          {item.status === 'DISABLED' ? (
                            <Tooltip arrow title="Disabled" placement="top">
                              <IconButton
                                aria-label="Disable"
                                onClick={() => {
                                  handleChangeScrapeSettingVesselsTerminal(
                                    item.id,
                                    'status',
                                    'ENABLED',
                                    idx
                                  );
                                }}>
                                <ReactSVG
                                  src={Icons.close}
                                  className={cls.icon}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip arrow title="Enabled" placement="top">
                              <IconButton
                                aria-label="enable"
                                onClick={() => {
                                  handleChangeScrapeSettingVesselsTerminal(
                                    item.id,
                                    'status',
                                    'DISABLED',
                                    idx
                                  );
                                }}>
                                <ReactSVG
                                  src={Icons.tickICon}
                                  className={cls.icon}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip arrow title="Refresh" placement="top">
                            <IconButton
                              aria-label="Refresh"
                              onClick={() =>
                                handleChangeScrapeSettingVesselsTerminal(
                                  item.id,
                                  'refresh_scrape',
                                  1,
                                  idx
                                )
                              }>
                              <ReactSVG
                                src={Icons.refreshIcon}
                                className={cls.icon}
                              />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                  : ''}
              </tbody>
            </table>
          ) : dataTypeChange === 'ssl' ? (
            <table key={'Ssl'} ref={tableRef} id="table" className={cls.list}>
              <span ref={amirbios2Ref} id="amirbios1">
                <thead ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>SSL </th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Integration</th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Scrapping Frequency</th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Last Scrape</th>
                  </span>
                  <span style={{ flex: 1 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Next Scrape</th>
                  </span>
                  <span style={{ flex: 1.3 }} className={cls.shifttd}>
                    <th style={{ flex: 1 }}>Action</th>
                  </span>
                </thead>
                <></>
              </span>
              <></>
              <tbody className={cls.tbody}>
                <></>
                {Array.isArray(TerminalsVesselsSsl)
                  ? TerminalsVesselsSsl.map((item, idx) => (
                      <tr className={cls.tableRow} key={idx}>
                        <></>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <span>
                            <a
                              className={cls.terminalLink}
                              target="_blank"
                              href={item.scrapeable?.links.vessel}>
                              {item.scrapeable.slug}
                            </a>
                          </span>
                        </td>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <FormControl>
                            <Select
                              displayEmpty
                              disableUnderline={true}
                              defaultValue={item.scrape_type}
                              className={cls.selectBox}
                              onChange={(e) =>
                                handleChangeScrapeSettingVesselsSsl(
                                  item.id,
                                  'scrape_type',
                                  item.scrape_type === 'SCRAPE'
                                    ? 'API'
                                    : item.scrape_type === 'API'
                                    ? 'SCRAPE'
                                    : '',
                                  idx
                                )
                              }
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem key={0} value={'SCRAPE'}>
                                SCRAPE
                              </MenuItem>
                              <MenuItem key={1} value={'API'}>
                                API
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </td>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <FormControl>
                            <Select
                              displayEmpty
                              disableUnderline={true}
                              defaultValue={item.scrape_frequency}
                              className={cls.selectBox}
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsSsl(
                                    item.id,
                                    'scrape_frequency',
                                    15,
                                    idx
                                  )
                                }
                                key={0}
                                value={15}>
                                15 min
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsSsl(
                                    item.id,
                                    'scrape_frequency',
                                    30,
                                    idx
                                  )
                                }
                                key={1}
                                value={30}>
                                30 min
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsSsl(
                                    item.id,
                                    'scrape_frequency',
                                    60,
                                    idx
                                  )
                                }
                                key={2}
                                value={60}>
                                1 hour
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsSsl(
                                    item.id,
                                    'scrape_frequency',
                                    360,
                                    idx
                                  )
                                }
                                key={3}
                                value={360}>
                                6 hour
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsSsl(
                                    item.id,
                                    'scrape_frequency',
                                    720,
                                    idx
                                  )
                                }
                                key={4}
                                value={720}>
                                12 hour
                              </MenuItem>
                              <MenuItem
                                onClick={(e) =>
                                  handleChangeScrapeSettingVesselsSsl(
                                    item.id,
                                    'scrape_frequency',
                                    1440,
                                    idx
                                  )
                                }
                                key={5}
                                value={1440}>
                                1 day
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </td>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <span>
                            {item.last_scrape
                              ? formatUpdatedAtDate(item.last_scrape)
                              : ''}
                          </span>
                        </td>
                        <td style={{ flex: 1 }} className={cls.shifttd}>
                          <span>
                            {item.next_scrape
                              ? formatUpdatedAtDate(item.next_scrape)
                              : ''}
                          </span>
                        </td>
                        <td style={{ flex: 1.3 }} className={cls.shifttd}>
                          {item.status === 'DISABLED' ? (
                            <Tooltip arrow title="Disabled" placement="top">
                              <IconButton
                                aria-label="Disable"
                                onClick={() => {
                                  handleChangeScrapeSettingVesselsSsl(
                                    item.id,
                                    'status',
                                    'ENABLED',
                                    idx
                                  );
                                }}>
                                <ReactSVG
                                  src={Icons.close}
                                  className={cls.icon}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip arrow title="Enabled" placement="top">
                              <IconButton
                                aria-label="enable"
                                onClick={() => {
                                  handleChangeScrapeSettingVesselsSsl(
                                    item.id,
                                    'status',
                                    'DISABLED',
                                    idx
                                  );
                                }}>
                                <ReactSVG
                                  src={Icons.tickICon}
                                  className={cls.icon}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip arrow title="Refresh" placement="top">
                            <IconButton
                              aria-label="Refresh"
                              onClick={() =>
                                handleChangeScrapeSettingVesselsSsl(
                                  item.id,
                                  'refresh_scrape',
                                  1,
                                  idx
                                )
                              }>
                              <ReactSVG
                                src={Icons.refreshIcon}
                                className={cls.icon}
                              />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                  : ''}
              </tbody>
            </table>
          ) : (
            ''
          )
        ) : form.scheduleType.value === 4 ? (
          <PerDiamSetting />
        ) : form.scheduleType.value === 5 ? (
          <AppointmentsSetting Terminals={Terminals} />
        ) : null}
      </div>
    </>
  );
};
