import React, { useEffect, useState, useRef } from 'react';
import styles from './styles';
import { getEmptyReceivingSchedule } from '@/actions';
import { useNotify, Loading } from 'react-admin';
import { Icons, TerminalsIcon } from '@/assets';
import { Icon } from '@mui/material';
import { Tooltip, Grid, Button } from '@material-ui/core';
import Select from 'react-select';
import { ReactSVG } from 'react-svg';
import DateInput from '@/components/DateInput';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

export default ({ TerminalList, DATADefault }) => {
  const [open, setOpen] = React.useState(null);
  const [openIndex, setOpenIndex] = useState(null); // Track the index of the open dropdown

  const [loading, setLoading] = React.useState(false);
  const [filteredData, setFilteredData] = useState(DATADefault);
  const [Terminals, setTerminals] = React.useState(TerminalList);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sizeSelectOptions, setSizeSelectOptions] = useState(null);
  const [sslSelectOptions, setSslSelectOptions] = useState(null);
  const [selectedSIZE, setSelectedSize] = useState(null);
  const [selectedSSL, setSelectedSSL] = useState();
  const selectOp = useSelector((state) => state.reducer);
  // console.log('selectOp', selectOp.ssl);
  const setForm = (it, val) => setFormData({ ...form, [it]: val });
  const todayDate = new Date();
  const dropdownRef = useRef(null);

  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
  });
  const handleTooltipClick = (index) => {
    setOpen(index);
  };

  const handleTooltipOutside = (event) => {
    setOpen(false);
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const cls = styles();
  const [selectedTab, setSelectedTab] = useState();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = { month: mm, day: dd, year: yyyy };

  const amirbiosRef = useRef(null);
  const tableRef = useRef(null);
  const amirbios1Ref = useRef(null);
  const amirbios2Ref = useRef(null);
  const nextColRef = useRef(null);
  const terminalColRef = useRef(null);
  const shiftColRef = useRef(null);
  const shiftRef1 = useRef(null);
  const shiftRef2 = useRef(null);

  const [tableWidth, setTableWidth] = useState(0);
  const [spanWidth, setSpanWidth] = useState(0);
  const [terminalWidth, setTerminalWidth] = useState(0);
  const [testtt, setTesttt] = useState(``);

  useEffect(() => {
    const sslSelectOptions = Object.keys(DATADefault).map((i, index) => ({
      label: i,
      value: index + 1,
    }));
    setSslSelectOptions(sslSelectOptions);
    sslSelectOptions.unshift({ label: 'All', value: 0 });
    setSelectedSSL(sslSelectOptions[0]);
    const sizeSelectOptions = Object.keys(filteredData['ANL']?.containers).map(
      (i, index) => ({ label: i, value: index + 1 })
    );
    setSizeSelectOptions(sizeSelectOptions);
    sizeSelectOptions.unshift({ label: 'All', value: 0 });
    setSelectedSize(sizeSelectOptions[0]);
  }, [DATADefault]);

  useEffect(() => {
    setSelectedTab(`${today.year}-${today.month}-${today.day}`);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const selectedDateFormatted = formatDate(selectedDate);
      changeTableDate(
        `${selectedDateFormatted.year}-${selectedDateFormatted.monthNumber}-${selectedDateFormatted.day}`
      );
      setSelectedTab(
        `${selectedDateFormatted.year}-${selectedDateFormatted.monthNumber}-${selectedDateFormatted.day}`
      );
    }
  }, [selectedDate]);

  const changeTableDate = (date) => {
    handleChangeTable(date);
    setLoading(true);
    getEmptyReceivingSchedule(date)
      .then((res) => {
        setTerminals(res.data.restrictions);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const notify = useNotify();

  const handleChangeTable = (index) => {
    setSelectedTab(index);
  };

  function getCurrentWeekDates(daydate) {
    const today = new Date();

    // Adjust for Monday-based weeks: getDay() returns 0 for Sunday, 1 for Monday, etc.
    const currentDay = (daydate.getDay() + 6) % 7; // Adjust so Monday is 0 and Sunday is 6

    const firstDayOfWeek = new Date(daydate);
    firstDayOfWeek.setDate(daydate.getDate() - currentDay);

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  // Example usage:
  const someDate = new Date(); // You can replace this with any date you want
  getCurrentWeekDates(someDate);

  const Reset = () => {
    setSelectedDate(todayDate);
    setSelectedSize(sizeSelectOptions[0]);
    setSelectedSSL(sslSelectOptions[0]);
    setFormData({
      size: sizeSelectOptions[0],
      ssl: sslSelectOptions[0],
      date: new Date(),
    });
  };

  // Format the date to separate day, abbreviated month, and year
  function formatDate(date, format) {
    const day = date.getDate().toString().padStart(2, '0');
    const dayNumber = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const dayName = dayNames[date.getDay()];
    const monthNumber = String(monthIndex + 1).padStart(2, '0'); // Add leading zero if necessary
    const month = monthNames[monthIndex];
    if (format == 1) {
      return `${year}-${monthNumber}-${day}`;
    } else {
      return { day, dayNumber, dayName, monthNumber, month, year };
    }
  }
  // Print the dates of the current week
  const weekDates = getCurrentWeekDates(selectedDate);
  const formattedDates = weekDates.map((date) => formatDate(date));
  // const originalDate = "2024-04-20T04:31:52.000000Z";

  const formatUpdatedAtDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate =
      new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      }) +
      ' ' +
      new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    return formattedDate;
  };

  function getTimeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / 60000); // Convert to minutes

    if (diffInMinutes <= 5) {
      return '5 Minutes ago';
    } else if (diffInMinutes <= 10) {
      return '10 Minutes ago';
    } else if (diffInMinutes <= 15) {
      return '15 Minutes ago';
    } else {
      // More than 15 minutes have passed
      const intervals = Math.floor(35 / 15);
      const remainder = diffInMinutes % 15;
      console.log('diffInMinutes', diffInMinutes);
      console.log('remainder', remainder);
      console.log('intervals', intervals);
      console.log('tttttttttttttt');

      if (remainder <= 5) {
        return `${5} Minutes ago`;
      } else if (remainder <= 10) {
        return `${10} Minutes ago`;
      } else {
        return `${15} Minutes ago`;
      }
    }
  }
  // Example usage:
  // const dateString = '2024-09-28T17:44:00.000000Z';
  // console.log(getTimeAgo(dateString)); // Output will vary based on the current time

  useEffect(() => {
    const handleScroll = () => {
      if (amirbiosRef.current && amirbios1Ref.current) {
        const amirbiosTop = amirbiosRef.current.getBoundingClientRect().top;
        if (amirbiosTop <= 20) {
          amirbios2Ref.current.classList.add(cls.fix);
          shiftRef1.current.classList.add(cls.hideShift);
          shiftRef2.current.classList.add(cls.hideShift);
          amirbios2Ref.current.classList.add(cls.showShift);

          tableRef.current.classList.add(cls.tableTopMargin);
        } else {
          amirbios2Ref.current.classList.remove(cls.fix);
          shiftRef1.current.classList.remove(cls.hideShift);
          shiftRef2.current.classList.remove(cls.hideShift);
          amirbios2Ref.current.classList.remove(cls.showShift);
          tableRef.current.classList.remove(cls.tableTopMargin);
        }
      }
      syncScroll('amirbios');
      setOpenIndex(false);
      setOpen(false);
    };
    const updateWidth = () => {
      if (tableRef.current && amirbios1Ref.current) {
        const tableCurrentWidth =
          tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableCurrentWidth);
      }
    };

    const updateHeadWidth = () => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        const tableeCurrentWidth =
          amirbiosRef.current.getBoundingClientRect().width;
        setSpanWidth(tableeCurrentWidth);
      }
    };

    const updateTerminalWidth = () => {
      if (nextColRef.current) {
        const terminalCurrentWidth =
          nextColRef.current.getBoundingClientRect().width;
        setTerminalWidth(terminalCurrentWidth);
      }
    };

    const syncScroll = (source) => {
      if (amirbiosRef.current && amirbios2Ref.current) {
        if (source === 'amirbios') {
          amirbios2Ref.current.scrollLeft = amirbiosRef.current.scrollLeft;
        } else if (source === 'amirbios2') {
          amirbiosRef.current.scrollLeft = amirbios2Ref.current.scrollLeft;
        }
      }
    };

    // Initial width setting
    updateWidth();
    updateHeadWidth();
    updateTerminalWidth();

    window.updateWidth = updateWidth;
    window.updateHeadWidth = updateHeadWidth;
    window.syncScroll = syncScroll;

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWidth);
    window.addEventListener('resize', updateHeadWidth);
    window.addEventListener('resize', updateTerminalWidth);
    window.addEventListener('scroll', updateTerminalWidth);
    if (amirbiosRef.current) {
      amirbiosRef.current.addEventListener('scroll', () => {
        syncScroll('amirbios');
      });
      amirbios2Ref.current.addEventListener('scroll', () => {
        syncScroll('amirbios2');
      });
    }

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('resize', updateHeadWidth);
      window.removeEventListener('resize', updateTerminalWidth);
      window.removeEventListener('scroll', updateTerminalWidth);
      if (amirbiosRef.current) {
        amirbiosRef.current.removeEventListener('scroll', () =>
          syncScroll('amirbios')
        );
        amirbios2Ref.current.removeEventListener('scroll', () =>
          syncScroll('amirbios2')
        );
      }
    };
  }, []);

  if (amirbios2Ref.current) {
    amirbios2Ref.current.style.width = `${spanWidth - 1}px`;
  }

  if (amirbios1Ref.current) {
    amirbios1Ref.current.style.width = `${tableWidth}px`;
  }

  if (terminalColRef.current) {
    terminalColRef.current.style.minWidth = `${
      terminalWidth - 0.01 * terminalWidth - 2
    }px`;
    shiftColRef.current.style.minWidth = `${
      terminalWidth - 0.01 * terminalWidth - 2
    }px`;
  }

  // Function to filter data based on selected SSL
  const filterDataBySSL = (data, ssl, size) => {
    var delayInMilliseconds = 400; //0.5 second

    setTimeout(function () {
      if (ssl) {
        if (ssl.value == 0) {
          setLoading(false);
          return data;
        }
      }
      setLoading(false);
    }, delayInMilliseconds);

    const filteredBySSL =
      !ssl || ssl.label === 'All'
        ? data
        : Object.keys(data).reduce((acc, key) => {
            if (key === ssl.label) {
              acc[key] = data[key];
            }
            return acc;
          }, {});

    // Then filter by Size within the filtered SSL data
    const filteredBySize =
      !size || size.label === 'All'
        ? filteredBySSL
        : Object.keys(filteredBySSL).reduce((acc, key) => {
            acc[key] = {
              ...filteredBySSL[key],
              containers: Object.keys(filteredBySSL[key].containers)
                .filter((containerKey) => containerKey === size.label)
                .reduce((obj, containerKey) => {
                  obj[containerKey] =
                    filteredBySSL[key].containers[containerKey];
                  return obj;
                }, {}),
            };
            return acc;
          }, {});

    return filteredBySize;
  };

  useEffect(() => {
    setLoading(true);
    const filteredDataa = filterDataBySSL(
      DATADefault,
      selectedSSL,
      selectedSIZE
    );
    setFilteredData(filteredDataa);
  }, [DATADefault, selectedSSL, selectedSIZE]);
  // console.log('filteredDataaaaaaaaaa', filteredData);

  // <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
  // <table ref={tableRef} id="table" className={cls.list}>
  //   <thead ref={amirbios2Ref} id="amirbios1">
  //     <tr ref={amirbios1Ref} id="amirbios2" className={cls.thead}>

  return (
    <>
      <div id="emptyCard" className={cls.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`SSl `}</span>
              <Select
                placeholder="SSL"
                className={clsx(cls.select)}
                value={selectedSSL}
                onChange={(item) => {
                  setForm('ssl', item);
                  setSelectedSSL(item); // Set selected SSL state
                }}
                options={sslSelectOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Size `}</span>
              <Select
                placeholder="Size"
                className={clsx(cls.select)}
                value={selectedSIZE}
                onChange={(item) => {
                  setForm('size', item);
                  setSelectedSize(item);
                }}
                options={sizeSelectOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={`${cls.input} ${cls.containerInput}`}>
              <span className={cls.containerBadge}>{`Date `}</span>
              <DateInput
                placeholder="Available date"
                className={clsx(cls.date)}
                format="MM/dd/yyyy"
                value={form.date}
                onChange={(item) => {
                  setForm('date', item);
                  setSelectedDate(item);
                }}
              />
            </div>
          </Grid>
        </Grid>

        <div className={cls.actions}>
          <Grid item xs={12} sm={4} lg={3}>
            <Button className={clsx(cls.but, cls.red)} onClick={Reset}>
              Reset
            </Button>
          </Grid>
        </div>
      </div>
      <div id="legendSection" className={cls.tableInfoBox}>
        <span
          onClick={() => {
            document.getElementById('legendSection').style.display = 'none';
          }}
          className={cls.legendItemsX}>
          Legend
          <ReactSVG src={Icons.xiconn} className={cls.legendItemsXIcon} />
        </span>
        <span className={cls.legendItems}>
          <ReactSVG src={Icons.tickICon} className={cls.icon} />
          Open for receiving
        </span>
        <span className={cls.legendItems}>
          <ReactSVG
            style={{ padding: '0px 0px 1px' }}
            src={Icons.close}
            className={cls.icon}
          />
          Closed for receiving
        </span>
        <span style={{ marginTop: '-2px' }} className={cls.legendItems}>
          <ReactSVG
            style={{ padding: '2px 0px 0px' }}
            src={Icons.transaction}
            className={cls.icon}
          />
          Dual transactions only
        </span>
        <span style={{ marginTop: '-4px' }} className={cls.legendItems}>
          <ReactSVG
            style={{ padding: '2px 0px 0px' }}
            src={Icons.message}
            className={cls.icon}
          />
          Hover for messages
        </span>
      </div>
      <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
        <table className={cls.listDateRow}>
          <thead className={cls.thead}>
            {Object.keys(formattedDates).map((idx, i) => (
              <span className={cls.thdate}>
                {i === 7 ? (
                  ''
                ) : (
                  <>
                    <th
                      className={
                        selectedTab ==
                        `${formattedDates[idx].year}-${formattedDates[idx].monthNumber}-${formattedDates[idx].day}`
                          ? cls.tableDateShowToday
                          : cls.tableDateShow
                      }
                      key={`${formattedDates[idx].year}-${formattedDates[idx].monthNumber}-${formattedDates[idx].day}`}
                      onClick={() =>
                        changeTableDate(
                          `${formattedDates[idx].year}-${formattedDates[idx].monthNumber}-${formattedDates[idx].day}`
                        )
                      }>
                      <span>{formattedDates[idx].dayName}</span>{' '}
                      <span>{formattedDates[idx].dayNumber}</span>{' '}
                      <span>{formattedDates[idx].month}</span>
                    </th>
                  </>
                )}
              </span>
            ))}
          </thead>
        </table>
        <table ref={tableRef} id="table">
          <thead
            className={cls.list}
            ref={amirbios2Ref}
            id="amirbios1"
            style={{ display: 'none' }}>
            <tr className={cls.tableRow} ref={amirbios1Ref} id="amirbios2">
              <th ref={terminalColRef} scope="col" colspan="2">
                Terminal
              </th>
              {Object.keys(Terminals).map((idx) => (
                <td scope="col" className={cls.tableTd} colSpan="2">
                  <a
                    className={cls.terminalLink}
                    target="_blank"
                    href={Terminals[idx].links?.restriction}>
                    <img
                      className={cls.terminalLogo}
                      width={'100%'}
                      src={
                        idx == 'APM'
                          ? TerminalsIcon.APM
                          : idx == 'FMS'
                          ? TerminalsIcon.FMS
                          : idx == 'EVERPORT'
                          ? TerminalsIcon.EVERPORT
                          : idx == 'LACT'
                          ? TerminalsIcon.LACT
                          : idx == 'PCT'
                          ? TerminalsIcon.PCT
                          : idx == 'PST'
                          ? TerminalsIcon.PST
                          : idx == 'Trapac'
                          ? TerminalsIcon.Trapac
                          : idx == 'WBCT'
                          ? TerminalsIcon.WBCT
                          : idx == 'WCC'
                          ? TerminalsIcon.WCC
                          : idx == 'YTI'
                          ? TerminalsIcon.YTI
                          : idx == 'TTI'
                          ? TerminalsIcon.TTI
                          : idx == 'ITS'
                          ? TerminalsIcon.ITS
                          : idx == 'LBCT'
                          ? TerminalsIcon.LBCT
                          : idx == 'MATSON'
                          ? TerminalsIcon.MATSON
                          : idx == 'SSA MARINE'
                          ? TerminalsIcon.SSA
                          : idx == 'SSA STL'
                          ? TerminalsIcon.STL
                          : idx == 'PTS'
                          ? TerminalsIcon.PTS
                          : ''
                      }
                    />
                    <span className={cls.pierSpan}>
                      <span>{idx}</span>
                      <span>
                        {Terminals[idx].pier ? Terminals[idx].pier : ' '}
                      </span>
                    </span>
                  </a>
                </td>
              ))}
            </tr>
            <tr className={cls.tableRow}>
              <th ref={shiftColRef} scope="col" colspan="2">
                Shift Status
              </th>
              {Object.keys(Terminals).map((idx) => (
                <td className={cls.tableTd} colSpan="2">
                  <span className={cls.shiftStatusTitle}>
                    <span className={cls.shiftStatusSpan1St} colSpan="1">
                      1st
                    </span>
                    <span className={cls.shiftStatusSpan2Nd} colSpan="1">
                      2nd
                    </span>
                  </span>
                </td>
              ))}
            </tr>
          </thead>
        </table>
        {loading ? (
          <Loading />
        ) : (
          <>
            <table className={cls.list}>
              <thead
                // ref={amirbios2Ref}
                // id="amirbios2"
                className={cls.theadSticky}>
                {testtt ? (
                  <span dangerouslySetInnerHTML={{ __html: testtt }} />
                ) : (
                  <span></span>
                )}
                <tr
                  style={{ flex: 0.2 }}
                  ref={shiftRef1}
                  id=""
                  className={cls.tableRow}>
                  <th
                    // ref={terminalColRef}
                    scope="col"
                    colspan="2">
                    Terminal
                  </th>
                  {Object.keys(Terminals).map((idx) => (
                    <td scope="col" className={cls.tableTd} colSpan="2">
                      <a
                        className={cls.terminalLink}
                        target="_blank"
                        href={Terminals[idx].links?.restriction}>
                        <img
                          className={cls.terminalLogo}
                          width={'100%'}
                          src={
                            idx == 'APM'
                              ? TerminalsIcon.APM
                              : idx == 'FMS'
                              ? TerminalsIcon.FMS
                              : idx == 'EVERPORT'
                              ? TerminalsIcon.EVERPORT
                              : idx == 'LACT'
                              ? TerminalsIcon.LACT
                              : idx == 'PCT'
                              ? TerminalsIcon.PCT
                              : idx == 'PST'
                              ? TerminalsIcon.PST
                              : idx == 'Trapac'
                              ? TerminalsIcon.Trapac
                              : idx == 'WBCT'
                              ? TerminalsIcon.WBCT
                              : idx == 'WCC'
                              ? TerminalsIcon.WCC
                              : idx == 'YTI'
                              ? TerminalsIcon.YTI
                              : idx == 'TTI'
                              ? TerminalsIcon.TTI
                              : idx == 'ITS'
                              ? TerminalsIcon.ITS
                              : idx == 'LBCT'
                              ? TerminalsIcon.LBCT
                              : idx == 'MATSON'
                              ? TerminalsIcon.MATSON
                              : idx == 'SSA MARINE'
                              ? TerminalsIcon.SSA
                              : idx == 'SSA STL'
                              ? TerminalsIcon.STL
                              : idx == 'PTS'
                              ? TerminalsIcon.PTS
                              : ''
                          }
                        />
                        <span className={cls.pierSpan}>
                          <span>{idx}</span>
                          <span>
                            {Terminals[idx].pier ? Terminals[idx].pier : ' '}
                          </span>
                        </span>
                      </a>
                    </td>
                  ))}
                </tr>
                <tr style={{ flex: 0.2 }} className={cls.tableRow}>
                  <th ref={nextColRef} colspan="2">
                    Next Appointment
                  </th>
                  {Object.keys(Terminals).map((idx) => (
                    <td className={cls.tableTd} colSpan="2"></td>
                  ))}
                </tr>
                <tr style={{ flex: 0.2 }} className={cls.tableRow}>
                  <th colspan="2">Gate Status</th>
                  {Object.keys(Terminals).map((idx) => (
                    <td
                      colSpan="2"
                      className={`${cls.tableTd}
                      `}>
                      <span>
                        {Terminals[idx].gate_status == 1
                          ? 'Open'
                          : Terminals[idx].gate_status == 0
                          ? 'Closed'
                          : Terminals[idx].gate_status == 2
                          ? 'Dual'
                          : ''}
                      </span>
                    </td>
                  ))}
                </tr>
                <tr style={{ flex: 0.2 }} className={cls.tableRow}>
                  <th colspan="2">Last Update</th>
                  {Object.keys(Terminals).map((idx) => (
                    <td className={cls.tableTd} colSpan="2">
                      {/* {Terminals[idx].blur_status === 1
                        ? 'No Data'
                        : Terminals[idx].restrictions_latest3?.updated_at
                        ? formatUpdatedAtDate(
                            `${Terminals[idx].restrictions_latest3?.updated_at}`
                          )
                        : ''} */}
                      {Terminals[idx].blur_status === 1
                        ? 'No Data'
                        : Terminals[idx].restrictions_latest3?.updated_at
                        ? getTimeAgo(
                            Terminals[idx].restrictions_latest3?.updated_at
                          )
                        : ''}
                    </td>
                  ))}
                </tr>
                <tr
                  style={{ flex: 0.2 }}
                  ref={shiftRef2}
                  className={cls.tableRow}>
                  <th colspan="2">Shift Status</th>
                  {Object.keys(Terminals).map((idx) => (
                    <td className={cls.tableTd} colSpan="2">
                      <span className={cls.shiftStatusTitle}>
                        <span className={cls.shiftStatusSpan1St} colSpan="1">
                          1st
                        </span>
                        <span className={cls.shiftStatusSpan2Nd} colSpan="1">
                          2nd
                        </span>
                      </span>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody className={cls.tbody}>
                {Object.keys(filteredData)?.map((key) => (
                  <tr className={cls.tableRow}>
                    <>
                      <th colspan="2">
                        <tr className={cls.sslTr}>
                          <th className={cls.tableTdsslname} rowSpan={1}>
                            {key}
                          </th>
                          <span className={cls.sizeSpan}>
                            {Object.keys(filteredData[key]?.containers).map(
                              (kkk) => (
                                <tr className={cls.sslNamesTh}>
                                  {kkk}
                                  {/* <span className={cls.sslNamesTh}></span> */}
                                </tr>
                              )
                            )}
                          </span>
                        </tr>
                      </th>
                      {Object.keys(Terminals).map((idx) => (
                        <td
                          style={{ flex: 0.2 }}
                          className={
                            Terminals[idx].blur_status === 1
                              ? cls.tableTdNull
                              : cls.tableTd
                          }
                          colSpan="2">
                          {selectedSIZE &&
                            Object.values(
                              filteredData[key]?.containers
                            ).forEach((item, ggg) => {
                              if (item.title === selectedSIZE.label) {
                                return <span>hast</span>;

                                // console.log("Matching title found:", item.title);
                              } else {
                                return <span>nist</span>;

                                // console.log("not match:");
                              }
                            })}
                          {selectedSIZE == null || selectedSIZE.label === 'All'
                            ? Object.keys(filteredData[key]?.containers).map(
                                (item, ggg) =>
                                  Terminals[idx].blur_status === 1 ? (
                                    <tr className={cls.shiftStatus}> </tr>
                                  ) : (
                                    <tr className={cls.shiftStatus}>
                                      <span
                                        className={
                                          Terminals[idx].restriction.parsed[key]
                                            .containers[item].shift1 === true ||
                                          Terminals[idx].restriction.parsed[key]
                                            .containers[item].shift1 === 1
                                            ? cls.open
                                            : Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift1 ==
                                                false ||
                                              Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift1 === 0
                                            ? cls.close
                                            : Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift1 ===
                                                'DUAL' ||
                                              Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift1 === 2
                                            ? cls.dual
                                            : cls.nullStatuss
                                        }>
                                        {Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1 === false ||
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1 === 0 ? (
                                          <ReactSVG
                                            src={Icons.close}
                                            className={cls.icon}
                                          />
                                        ) : Terminals[idx].restriction.parsed[
                                            key
                                          ].containers[item].shift1 === true ||
                                          Terminals[idx].restriction.parsed[key]
                                            .containers[item].shift1 === 1 ? (
                                          <ReactSVG
                                            src={Icons.tickICon}
                                            className={cls.icon}
                                          />
                                        ) : Terminals[idx].restriction.parsed[
                                            key
                                          ].containers[item].shift1 ===
                                            'DUAL' ||
                                          Terminals[idx].restriction.parsed[key]
                                            .containers[item].shift1 === 2 ? (
                                          <ReactSVG
                                            src={Icons.transaction}
                                            className={cls.icon}
                                          />
                                        ) : (
                                          ''
                                        )}
                                        {Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1_message ? (
                                          <Tooltip
                                            onClick={(e) =>
                                              handleTooltipOutside(
                                                Terminals[idx].id +
                                                  Terminals[idx].slug +
                                                  Terminals[idx].restriction
                                                    ?.parsed[key].name +
                                                  ggg +
                                                  'shift1'
                                              )
                                            }
                                            onOpen={(e) =>
                                              handleTooltipClick(
                                                Terminals[idx].id +
                                                  Terminals[idx].slug +
                                                  Terminals[idx].restriction
                                                    ?.parsed[key].name +
                                                  ggg +
                                                  'shift1'
                                              )
                                            }
                                            onClose={(e) =>
                                              handleTooltipOutside(
                                                Terminals[idx].id +
                                                  Terminals[idx].slug +
                                                  Terminals[idx].restriction
                                                    ?.parsed[key].name +
                                                  ggg +
                                                  'shift1'
                                              )
                                            }
                                            open={
                                              open ===
                                              Terminals[idx].id +
                                                Terminals[idx].slug +
                                                Terminals[idx].restriction
                                                  ?.parsed[key].name +
                                                ggg +
                                                'shift1'
                                                ? true
                                                : false
                                            }
                                            arrow
                                            title={
                                              Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift1_message
                                            }
                                            placement="top">
                                            <ReactSVG
                                              src={Icons.attention}
                                              className={cls.iconAttention}
                                            />
                                          </Tooltip>
                                        ) : (
                                          ''
                                        )}
                                      </span>
                                      <span
                                        className={
                                          Terminals[idx].restriction.parsed[key]
                                            .containers[item].shift2 === true ||
                                          Terminals[idx].restriction.parsed[key]
                                            .containers[item].shift2 === 1
                                            ? cls.open
                                            : Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift2 ==
                                                false ||
                                              Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift2 === 0
                                            ? cls.close
                                            : Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift2 ===
                                                'DUAL' ||
                                              Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift2 === 2
                                            ? cls.dual
                                            : cls.nullStatuss
                                        }>
                                        {Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2 === false ||
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2 === 0 ? (
                                          <ReactSVG
                                            src={Icons.close}
                                            className={cls.icon}
                                          />
                                        ) : Terminals[idx].restriction.parsed[
                                            key
                                          ].containers[item].shift2 === true ||
                                          Terminals[idx].restriction.parsed[key]
                                            .containers[item].shift2 === 1 ? (
                                          <ReactSVG
                                            src={Icons.tickICon}
                                            className={cls.icon}
                                          />
                                        ) : Terminals[idx].restriction.parsed[
                                            key
                                          ].containers[item].shift2 ===
                                            'DUAL' ||
                                          Terminals[idx].restriction.parsed[key]
                                            .containers[item].shift2 === 2 ? (
                                          <ReactSVG
                                            src={Icons.transaction}
                                            className={cls.icon}
                                          />
                                        ) : (
                                          ''
                                        )}
                                        {Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2_message ? (
                                          <Tooltip
                                            onClick={(e) =>
                                              handleTooltipOutside(
                                                Terminals[idx].id +
                                                  Terminals[idx].slug +
                                                  Terminals[idx].restriction
                                                    ?.parsed[key].name +
                                                  ggg +
                                                  'shift2'
                                              )
                                            }
                                            onOpen={(e) =>
                                              handleTooltipClick(
                                                Terminals[idx].id +
                                                  Terminals[idx].slug +
                                                  Terminals[idx].restriction
                                                    ?.parsed[key].name +
                                                  ggg +
                                                  'shift2'
                                              )
                                            }
                                            onClose={(e) =>
                                              handleTooltipOutside(
                                                Terminals[idx].id +
                                                  Terminals[idx].slug +
                                                  Terminals[idx].restriction
                                                    ?.parsed[key].name +
                                                  ggg +
                                                  'shift2'
                                              )
                                            }
                                            open={
                                              open ===
                                              Terminals[idx].id +
                                                Terminals[idx].slug +
                                                Terminals[idx].restriction
                                                  ?.parsed[key].name +
                                                ggg +
                                                'shift2'
                                                ? true
                                                : false
                                            }
                                            arrow
                                            title={
                                              Terminals[idx].restriction.parsed[
                                                key
                                              ].containers[item].shift2_message
                                            }
                                            placement="top">
                                            <ReactSVG
                                              src={Icons.attention}
                                              className={cls.iconAttention}
                                            />
                                          </Tooltip>
                                        ) : (
                                          ''
                                        )}
                                      </span>
                                    </tr>
                                  )
                              )
                            : Object.keys(filteredData[key]?.containers)
                                .filter((item) => item === selectedSIZE?.label) // Filter items based on condition
                                .map((item, ggg) => (
                                  <tr className={cls.shiftStatus}>
                                    <span
                                      className={
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1 === true ||
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1 === 1
                                          ? cls.open
                                          : Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift1 ===
                                              false ||
                                            Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift1 === 0
                                          ? cls.close
                                          : Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift1 ===
                                              'DUAL' ||
                                            Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift1 === 2
                                          ? cls.dual
                                          : cls.nullStatuss
                                      }>
                                      {Terminals[idx].restriction.parsed[key]
                                        .containers[item].shift1 === false ||
                                      Terminals[idx].restriction.parsed[key]
                                        .containers[item].shift1 === 0 ? (
                                        <ReactSVG
                                          src={Icons.close}
                                          className={cls.icon}
                                        />
                                      ) : Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1 === true ||
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1 === 1 ? (
                                        <ReactSVG
                                          src={Icons.tickICon}
                                          className={cls.icon}
                                        />
                                      ) : Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1 === 'DUAL' ||
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift1 === 2 ? (
                                        <ReactSVG
                                          src={Icons.transaction}
                                          className={cls.icon}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      {Terminals[idx].restriction.parsed[key]
                                        .containers[item].shift1_message ? (
                                        <Tooltip
                                          onClick={(e) =>
                                            handleTooltipOutside(
                                              Terminals[idx].id +
                                                Terminals[idx].slug +
                                                Terminals[idx].restriction
                                                  ?.parsed[key].name +
                                                ggg +
                                                'shift1'
                                            )
                                          }
                                          onOpen={(e) =>
                                            handleTooltipClick(
                                              Terminals[idx].id +
                                                Terminals[idx].slug +
                                                Terminals[idx].restriction
                                                  ?.parsed[key].name +
                                                ggg +
                                                'shift1'
                                            )
                                          }
                                          onClose={(e) =>
                                            handleTooltipOutside(
                                              Terminals[idx].id +
                                                Terminals[idx].slug +
                                                Terminals[idx].restriction
                                                  ?.parsed[key].name +
                                                ggg +
                                                'shift1'
                                            )
                                          }
                                          open={
                                            open ===
                                            Terminals[idx].id +
                                              Terminals[idx].slug +
                                              Terminals[idx].restriction
                                                ?.parsed[key].name +
                                              ggg +
                                              'shift1'
                                              ? true
                                              : false
                                          }
                                          arrow
                                          title={
                                            Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift1_message
                                          }
                                          placement="top">
                                          <ReactSVG
                                            src={Icons.attention}
                                            className={cls.iconAttention}
                                          />
                                        </Tooltip>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                    <span
                                      className={
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2 === true ||
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2 === 1
                                          ? cls.open
                                          : Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift2 ===
                                              false ||
                                            Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift2 === 0
                                          ? cls.close
                                          : Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift1 ===
                                              'DUAL' ||
                                            Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift2 === 2
                                          ? cls.dual
                                          : cls.nullStatuss
                                      }>
                                      {Terminals[idx].restriction.parsed[key]
                                        .containers[item].shift2 === false ||
                                      Terminals[idx].restriction.parsed[key]
                                        .containers[item].shift2 === 0 ? (
                                        <ReactSVG
                                          src={Icons.close}
                                          className={cls.icon}
                                        />
                                      ) : Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2 === true ||
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2 === 1 ? (
                                        <ReactSVG
                                          src={Icons.tickICon}
                                          className={cls.icon}
                                        />
                                      ) : Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2 === 'DUAL' ||
                                        Terminals[idx].restriction.parsed[key]
                                          .containers[item].shift2 === 2 ? (
                                        <ReactSVG
                                          src={Icons.transaction}
                                          className={cls.icon}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      {Terminals[idx].restriction.parsed[key]
                                        .containers[item].shift2_message ? (
                                        <Tooltip
                                          onClick={(e) =>
                                            handleTooltipOutside(
                                              Terminals[idx].id +
                                                Terminals[idx].slug +
                                                Terminals[idx].restriction
                                                  ?.parsed[key].name +
                                                ggg +
                                                'shift2'
                                            )
                                          }
                                          onOpen={(e) =>
                                            handleTooltipClick(
                                              Terminals[idx].id +
                                                Terminals[idx].slug +
                                                Terminals[idx].restriction
                                                  ?.parsed[key].name +
                                                ggg +
                                                'shift2'
                                            )
                                          }
                                          onClose={(e) =>
                                            handleTooltipOutside(
                                              Terminals[idx].id +
                                                Terminals[idx].slug +
                                                Terminals[idx].restriction
                                                  ?.parsed[key].name +
                                                ggg +
                                                'shift2'
                                            )
                                          }
                                          open={
                                            open ===
                                            Terminals[idx].id +
                                              Terminals[idx].slug +
                                              Terminals[idx].restriction
                                                ?.parsed[key].name +
                                              ggg +
                                              'shift2'
                                              ? true
                                              : false
                                          }
                                          arrow
                                          title={
                                            Terminals[idx].restriction.parsed[
                                              key
                                            ].containers[item].shift2_message
                                          }
                                          placement="top">
                                          <ReactSVG
                                            src={Icons.attention}
                                            className={cls.iconAttention}
                                          />
                                        </Tooltip>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  </tr>
                                ))}
                        </td>
                      ))}
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      {/* 
      <div ref={amirbiosRef} id="amirbios" className={cls.listContainer}>
        <table ref={tableRef} id="table" className={cls.list}>
          <thead ref={amirbios2Ref} id="amirbios1">
            <tr ref={amirbios1Ref} id="amirbios2" className={cls.thead}>
              <th style={{ flex: 0.2 }} className={cls.tableTitleSorting}>
                item.name
              </th>
              <th style={{ flex: 0.2 }} className={cls.tableTitleSorting}>
                item.name
              </th>
              <th style={{ flex: 0.2 }} className={cls.tableTitleSorting}>
                item.name
              </th>
              <th style={{ flex: 0.2 }} className={cls.tableTitleSorting}>
                item.name
              </th>
            </tr>
          </thead>
            <tbody>
                <tr className={cls.tableRow} >
                <td style={{ flex: 0.2 }} className={cls.shifttd}>
                    <span style={{ flex: 1 }} className={cls.terminalsNameLogo}>
                      <a
                        className={cls.terminalLink}
                        target="_blank"
                        >
                        idx.terminal_name
                      </a>
                    </span>
                  </td>
                  <td style={{ flex: 0.2 }} className={cls.shifttd}>
                    <span style={{ flex: 1 }} className={cls.terminalsNameLogo}>
                      <a
                        className={cls.terminalLink}
                        target="_blank"
                        >
                        idx.terminal_name
                      </a>
                    </span>
                  </td>
                  <td style={{ flex: 0.2 }} className={cls.shifttd}>
                    <span style={{ flex: 1 }} className={cls.terminalsNameLogo}>
                      <a
                        className={cls.terminalLink}
                        target="_blank"
                        >
                        idx.terminal_name
                      </a>
                    </span>
                  </td>
                </tr>
            </tbody>
        </table>
      </div> */}
    </>
  );
};
