import React from 'react';
import styles from '../styles';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import { PersonAdd, FileCopyOutlined, LinkOutlined } from '@material-ui/icons';
import { useOutsideAlerter } from '@/hocs';

export default ({ setContent, shareLink, notify, setShareModal }) => {
  const cls = styles();

  const handleCopyLink = (text) => {
    navigator.clipboard.writeText(text);
    notify('Copied!', 'success');
  };

  //close popup when click outside
  // const wrapperRef = React.useRef(null);
  // useOutsideAlerter(wrapperRef, () => {
  //   setShareModal(null);
  // });
  // console.log('shareLinkaaaa', shareLink);

  return (
    <div className={cls.shareLinkContainer}>
      <h3>Share your Inventory</h3>
      <Button onClick={() => setContent(1)} className={cls.ShareEmail}>
        <PersonAdd />
        <span style={{ marginLeft: '6px' }}>Share with Email</span>
      </Button>
      <p>Or share this Container link with others you want</p>
      <div className={cls.copySection}>
        <div>{shareLink?.DATA.link_text}</div>
        <div className={cls.copyActions}>
          <Tooltip arrow title="Copy Link" placement="top">
            <IconButton
              aria-label="copy link"
              onClick={() => handleCopyLink(shareLink?.DATA.link)}>
              <LinkOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Copy Text" placement="top">
            <IconButton
              aria-label="copy text"
              onClick={() => handleCopyLink(shareLink?.DATA.link_text)}>
              <FileCopyOutlined />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
