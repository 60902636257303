import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  popup: {
    // position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
  },

  card: {
    width: '1150px',
    position: 'relative',
    padding: '40px 25px 22px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  loading: {
    height: '100%',
    margin: 0,
  },
  tableRow: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 42,
    // borderRadius: 5,
    // boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    border: '1px solid #e9e9e9',
    borderBottom: 'transparent',
    backgroundColor: '#fff',
    // margin: '10px 0',
    fontFamily: 'Averta Light',
    fontSize: 11,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',

    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
      display: 'flex',
    },
  },
  terminalLink: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'auto',
    color: '#43424b',
  },
  dayName: {
    color: '#a1a1a1',
  },
  dayNameToday: {
    color: '#f8b100',
  },
  dayNumberNormal: {
    fontSize: 13,
    padding: '7px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayNumberToday: {
    minWidth: 19,
    fontSize: 13,
    // padding: '2px 2px 4px 0.5px',
    color: '#f8b100',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // color: 'white',
  },
  tableDateShowToday: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    alignItems: 'center',
    fontSize: '12px',
  },
  shifttdToday: {
    background: '#f5f5f5',
    display: 'flex',
    justifyContent: 'space-evenly',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.2)',
    height: 42,
    alignItems: 'center',
    '& th': {
      borderRight: '1px solid #e9e9e9',
      width: '-webkit-fill-available',
      display: 'flex',
      height: '-webkit-fill-available',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 9,
      '&:last-child': {
        border: 'none',
      },
    },
  },

  tableDateShow: {
    display: 'flex',
    color: '#a1a1a1',
    flexDirection: 'row',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    fontSize: 13,
    alignItems: 'center',
    gap: 1,
  },

  shifttd: {
    display: 'flex',
    justifyContent: 'space-evenly',
    // borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    height: 42,
    alignItems: 'center',
    '& th': {
      borderRight: '1px solid #e9e9e9',
      width: '-webkit-fill-available',
      display: 'flex',
      height: '-webkit-fill-available',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 9,
      '&:last-child': {
        border: 'none',
      },
    },
  },
  tableTopMargin: {
    marginTop: 60,
  },
  shifttdTerminal: {
    background: '#ffffff',
    borderBottom: '1px solid #e9e9e9',
    display: 'flex',
    justifyContent: 'space-evenly',
    borderLeft: '1px solid #e9e9e9',
    borderRight: '1px solid #e9e9e9',
    height: 61,
    alignItems: 'center',
  },
  tableTopMargin: {
    marginTop: 60,
  },

  thead: {
    display: 'flex',
    fontFamily: 'Averta Bold',
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    height: 40,
    // marginBottom: '-10px',
    border: '1px solid #e9e9e9',
    borderBottom: 'none',
    [BP.up('lg')]: {
      fontSize: 12,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  row2: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  select: {
    fontFamily: 'Averta Light',
    width: '100%',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      fontFamily: 'Averta Light',
      padding: 0,
      cursor: 'pointer',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  dateWithBox: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    // border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      fontFamily: 'Averta Light',
      padding: 0,
      cursor: 'pointer',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },

  loc: {
    '& svg': {
      fill: '#f8b100',
    },

    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      border: 'solid 1px #707070',
    },
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  containerBadge: {
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    width: '70px',
    minWidth: '70px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'solid 1px #707070',
    '& input': {
      fontFamily: 'Averta Light',
      padding: '5px 10px',
    },
  },
  closeP: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },
  but: {
    width: 150,
    height: 38,
    borderRadius: 5,
    backgroundColor: '#007fff',
    fontFamily: 'Averta Light',
    fontSize: 14,
    fontWeight: 300,
    color: '#fff',
    padding: 0,
    marginLeft: 'auto',
    marginTop: 20,
    '&:hover': {
      backgroundColor: '#007fff',
    },
  },
  open: {
    background: '#00800024',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'center',
    fontSize: 9,
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&:hover': {
      filter: 'drop-shadow(0px 0px 6px #00000059)',
      transition: 'all ease 0.3s',
    },
  },
  nullAvalable: {
    background: '#f4f4f4',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'center',
    fontSize: 9,
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&:hover': {
      filter: 'drop-shadow(0px 0px 6px #00000059)',
      transition: 'all ease 0.3s',
    },
  },
  list: {
    border: '1px solid #ededed',
    borderCollapse: 'collapse',
  },
  close: {
    background: '#ff000038',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'center',
    fontSize: 9,
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&:hover': {
      filter: 'drop-shadow(0px 0px 6px #00000059)',
      transition: 'all ease 0.3s',
    },
  },
  zero: {
    background: '#ffa82770',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'center',
    fontSize: 9,
    cursor: 'pointer',
    transition: 'all ease 0.3s',
    '&:hover': {
      filter: 'drop-shadow(0px 0px 6px #00000059)',
      transition: 'all ease 0.3s',
    },
  },
}));
